import {
  CallToActionViewModel,
  ClickLogosSectionViewModel,
  InfoSectionViewModel,
  QuoteModelList,
  QuotesViewModel,
  RoundedCardCTAViewModel,
} from './types';

import { EVENT_TYPES } from '@spencer/customerMonitoringTelemetry/customEventTypes';
import { recordHomeEvent } from '@spencer/customerMonitoringTelemetry/cwrumconfig';

//Dummy logos for registry links section
import dockerLogo from '@spencer/assets/homepage/docker.png';
import cloudwatchLogo from '@spencer/assets/homepage/cloudwatch.png';
import datadogLogo from '@spencer/assets/homepage/datadog.png';
import lambdaLogo from '@spencer/assets/homepage/lambda.png';
import ubuntuLogo from '@spencer/assets/homepage/ubuntu.png';
import nginxLogo from '@spencer/assets/homepage/nginx.png';
import eksLogo from '@spencer/assets/homepage/eks.png';
import bitnamiLogo from '@spencer/assets/homepage/bitnami.png';
import pythonLogo from '@spencer/assets/homepage/python.png';
import dynatraceLogo from '@spencer/assets/homepage/dynatrace.png';
import karpenterLogo from '@spencer/assets/homepage/karpenter.png';
import appmeshLogo from '@spencer/assets/homepage/appmesh.png';
import amazonlinuxLogo from '@spencer/assets/homepage/amazonlinux.png';
import mackerelLogo from '@spencer/assets/homepage/mackerel.png';

// AWS vectors
import aws_build from '@spencer/assets/homepage/aws_build.png';
import aws_signup from '@spencer/assets/homepage/aws_signup.png';
import aws_tools from '@spencer/assets/homepage/aws_tools.png';
import aws_training from '@spencer/assets/homepage/aws_training.png';
import { ECR_PTC, ECR_PUBLIC, ECR_PUBLIC_GALLERY } from '@spencer/util/constants';

enum DocLinks {
  PTC = 'https://docs.aws.amazon.com/AmazonECR/latest/userguide/pull-through-cache.html',
  PULL_IMAGE = 'https://docs.aws.amazon.com/AmazonECR/latest/public/docker-pull-ecr-image.html',
  GET_STARTED = 'https://docs.aws.amazon.com/AmazonECR/latest/public/public-getting-started.html',
  VISIBILITY = 'https://docs.aws.amazon.com/AmazonECR/latest/public/public-gallery.html',
}

export const REGISTRY_LOGOS_MODEL: ClickLogosSectionViewModel = {
  header: 'Popular registries',
  items: [
    {
      header: 'Docker official',
      logoPath: dockerLogo,
      clickId: '/docker/',
    },
    {
      header: 'Cloudwatch',
      logoPath: cloudwatchLogo,
      clickId: '/cloudwatch-agent/',
    },
    {
      header: 'Datadog',
      logoPath: datadogLogo,
      clickId: '/datadog/',
    },
    {
      header: 'Ubuntu',
      logoPath: ubuntuLogo,
      clickId: '/ubuntu/',
    },
    {
      header: 'Bitnami',
      logoPath: bitnamiLogo,
      clickId: '/bitnami/',
    },
    {
      header: 'Dynatrace',
      logoPath: dynatraceLogo,
      clickId: '/dynatrace/',
    },
    {
      header: 'NGINX',
      logoPath: nginxLogo,
      clickId: '/nginx/',
    },
    {
      header: 'Lambda',
      logoPath: lambdaLogo,
      clickId: '/lambda/',
    },
    {
      header: 'EKS',
      logoPath: eksLogo,
      clickId: '/eks-distro/',
    },
    {
      header: 'Python',
      logoPath: pythonLogo,
      clickId: '/docker/library/python/',
    },
    {
      header: 'Amazon linux',
      logoPath: amazonlinuxLogo,
      clickId: '/amazonlinux/',
    },
    {
      header: 'Karpenter',
      logoPath: karpenterLogo,
      clickId: '/karpenter/',
    },
    {
      header: 'App mesh',
      logoPath: appmeshLogo,
      clickId: '/appmesh/',
    },
    {
      header: 'Mackerel',
      logoPath: mackerelLogo,
      clickId: '/mackerel/',
    },
  ],
};

export const BENEFITS_VM: InfoSectionViewModel = {
  header: 'Features and benefits',
  items: [
    {
      header: 'Highly available',
      copy: `Pull with confidence. ${ECR_PUBLIC} replicates all images across multiple AWS regions, increasing availability for the images that you need.`,
    },
    {
      header: 'Quality content',
      copy: `Find all images published by Amazon, the Docker Official Images, and images from many popular publishers. Browse the ${ECR_PUBLIC_GALLERY} to find the content that you need.`,
    },
    {
      header: 'Easy image distribution',
      copy: `Use familiar tooling to publish images to ${ECR_PUBLIC} and make them available for the broad public. With 1 transaction per second (TPS) for unauthenticated clients off AWS, and 10 TPS for authenticated and all clients on AWS, your customers can easily find your images and pull with confidence.`,
    },
  ],
};

export const USE_CASES_VM: InfoSectionViewModel = {
  header: 'Use cases',
  items: [
    {
      header: 'Discover public content',
      copy: `Browse images available in the ${ECR_PUBLIC_GALLERY}. Find information about image publishers and review guidance for use cases.`,
      linkRoute: '/search',
      linkText: 'Start browsing the gallery.',
    },
    {
      header: 'Take ownership of your images',
      copy: `Set up your private ECR registry to act as a cache for images in ${ECR_PUBLIC} with a few clicks, allowing you to control the images you use and set up scanning and lifecycle management for them.`,
    },
    {
      header: 'Publish with confidence',
      copy: `Publish your images with the confidence that your customers will be able to find and pull them when they need. Use ${ECR_PUBLIC} in your documentation to make it easy for your customers to try your content.`,
    },
  ],
};

export const AWS_CALL_TO_ACTION_VM: CallToActionViewModel = {
  header: `Get started with ${ECR_PUBLIC}`,
  items: [
    {
      imagePath: aws_tools,
      copy: `Detailed guide on pulling images from ${ECR_PUBLIC}`,
      linkText: 'Pulling public images',
      link: DocLinks.PULL_IMAGE,
      event: () => recordHomeEvent(EVENT_TYPES.HOME_CLICKED_DOC_LINK, { url: DocLinks.PULL_IMAGE }),
    },
    {
      imagePath: aws_build,
      copy: `Get started with Amazon ${ECR_PUBLIC} repositories by creating your first public repository`,
      linkText: `Publish with ${ECR_PUBLIC}`,
      link: DocLinks.GET_STARTED,
      event: () =>
        recordHomeEvent(EVENT_TYPES.HOME_CLICKED_DOC_LINK, { url: DocLinks.GET_STARTED }),
    },
    {
      imagePath: aws_signup,
      copy: `Get your public registry verified to improve visibility in the ${ECR_PUBLIC_GALLERY}`,
      linkText: 'Increase gallery visibility',
      link: DocLinks.VISIBILITY,
      event: () => recordHomeEvent(EVENT_TYPES.HOME_CLICKED_DOC_LINK, { url: DocLinks.VISIBILITY }),
    },
  ],
};

export const AWS_FREE_TRAINING_CTA_VM: RoundedCardCTAViewModel = {
  header: ECR_PTC,
  copy: `${ECR_PTC} feature allows pulling public registry images into ECR Private so you can utilize expanded features on public images such as image vulnerability scanning and cross region replication.`,
  imagePath: aws_training,
  link: DocLinks.PTC,
  event: () => recordHomeEvent(EVENT_TYPES.HOME_CLICKED_DOC_LINK, { url: DocLinks.PTC }),
};

// TODO: post initial launch, legal + marketing needs time to gather quotes
export const QUOTES_MODEL: QuoteModelList = [];

export const QUOTES_VM: QuotesViewModel = {
  header: 'What customers are saying',
  quotes: QUOTES_MODEL,
};
