export const enum GalleryBreadcrumbId {
  HOME = 'HOME',
  SEARCH = 'SEARCH',
  SEARCH_WITH_TERM = 'SEARCH_WITH_TERM',
  REGISTRY = 'REGISTRY',
  LISTING_DETAIL = 'LISTING_DETAIL',
}

export interface GalleryBreadcrumbItem {
  id: GalleryBreadcrumbId;
  label: (param?: string) => string;
  route: (param?: string) => string;
  parentId?: GalleryBreadcrumbId;
}

export type GenerateBreadcrumbsProps = { [key in GalleryBreadcrumbId]?: string };
