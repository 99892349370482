import React, { FC } from 'react';
import { Box, Button } from '@amzn/awsui-components-react/polaris';

import './Empty.scss';
export interface EmptyProps {
  heading: string;
  description: string;
  includeButton?: boolean;
  buttonLabel?: string;
  buttonEventHandler?: (detail) => void;
}

const bemComponentPrefix = 'Empty';

const Empty: FC<EmptyProps> = ({
  heading,
  description,
  includeButton,
  buttonLabel,
  buttonEventHandler,
}) => {
  const buttonHandler = event => {
    buttonEventHandler(event.detail);
  };
  return (
    <Box
      margin={{ vertical: 'xs' }}
      textAlign="center"
      color="inherit"
      className={bemComponentPrefix}
    >
      <b>{heading}</b>
      <Box padding={{ bottom: 's' }} variant="p" color="inherit">
        {description}
      </Box>
      {includeButton && buttonLabel !== null && (
        <Button onClick={buttonHandler}>{buttonLabel}</Button>
      )}
    </Box>
  );
};

export default Empty;
