import React, { useEffect, useState, FC } from 'react';
import { useParams } from 'react-router-dom';
import { QueryCache } from 'react-query';

import { Box, Spinner } from '@amzn/awsui-components-react/polaris';

import { withQueryCacheProvider } from '../common/HOC';
import { useListingQuery, useImageDetailListQuery, useRegistryCatalogQuery } from './hooks';
import ListingDetailNotFound from './ListingDetailNotFound';
import ListingDetailPageHelmet from './ListingDetailPageHelmet';
import ListingDetailHeader from './ListingDetailHeader';
import {
  Breadcrumbs,
  BreadcrumbsVM,
  GalleryBreadcrumbId,
  generateBreadcrumbsViewModel,
} from '@spencer/components/common/Breadcrumbs';
import ListingDetailTabs from './ListingDetailTabs';

import './ListingDetailPage.scss';
import { FEATURES, isFeatureEnabled } from '@spencer/util/featureControl';
import { isRepositoryHidden } from '@spencer/util/hideRepo';
import ErrorPage from '../common/ErrorPage';

const bemPrefix = 'ListingDetailPage';

interface IListingDetailRouteParams {
  publisherId: string;
  repoName: string;
}

const listingDetailPageQueryCache = new QueryCache();

const ListingDetailPage: FC = () => {
  const { publisherId, repoName: repoNameProp } = useParams<IListingDetailRouteParams>();
  const [breadcrumbs, updateBreadcrumbs] = useState<BreadcrumbsVM>([]);
  const { isLoading: isLoadingListing, error: listingError } = useListingQuery(
    publisherId,
    repoNameProp
  );
  useImageDetailListQuery(publisherId, repoNameProp);
  const { isLoading: isLoadingRegistryCatalog } = useRegistryCatalogQuery(publisherId);

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
  }, []);

  useEffect(() => {
    const routeId = GalleryBreadcrumbId.LISTING_DETAIL;

    updateBreadcrumbs(
      generateBreadcrumbsViewModel(routeId, {
        [GalleryBreadcrumbId.REGISTRY]: publisherId ?? '',
        [GalleryBreadcrumbId.LISTING_DETAIL]: `${publisherId ?? ''}/${repoNameProp ?? ''}`,
      })
    );
  }, [publisherId, repoNameProp]);

  if (isRepositoryHidden(publisherId, repoNameProp)) {
    return <ListingDetailNotFound />;
  }

  if (listingError !== null) {
    if (isFeatureEnabled(FEATURES.repositoryErrorPage)) {
      return <ErrorPage error={listingError} />;
    } else {
      return <ListingDetailNotFound />;
    }
  }

  const renderBreadcrumbs = () => {
    const breadcrumbLoading = isLoadingRegistryCatalog || isLoadingListing;

    if (breadcrumbLoading) return;

    return <Breadcrumbs vm={breadcrumbs} />;
  };

  return (
    <div className={bemPrefix}>
      <ListingDetailPageHelmet publisherId={publisherId} repoName={repoNameProp} />
      {renderBreadcrumbs()}
      <ListingDetailHeader publisherId={publisherId} repoName={repoNameProp} />
      <Box>
        {isLoadingListing ? (
          <Spinner />
        ) : (
          <ListingDetailTabs publisherId={publisherId} repoName={repoNameProp} />
        )}
      </Box>
    </div>
  );
};

export default withQueryCacheProvider(ListingDetailPage, listingDetailPageQueryCache);
