export const attemptScrollToId = (id: string, offset?: number): void => {
  const yOffset = offset ?? 0;
  try {
    const el = document?.getElementById(id);
    const top = el?.getBoundingClientRect().top + window?.pageYOffset + yOffset;

    window?.scrollTo({ top, behavior: 'smooth' });
  } catch (e) {
    // TODO: log error once we have telemetry / RUM
  }
};
