import React, { FC } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';
import SearchPage from './components/SearchPage';
import ListingDetailPage from './components/ListingDetailPage';
import RegistryPage from './components/RegistryPage';
import HomePage from './components/HomePage';

import { SearchProvider } from './components/common/SearchContext';
import UnifiedAppLayout from './components/UnifiedAppLayout';

export const AppRoutes: FC = () => {
  return (
    <Switch>
      <SearchProvider>
        <UnifiedAppLayout>
          <Switch>
            <Route exact path="(/home|/)" component={HomePage} />
            <Route exact path="(/search|/)" component={SearchPage} />
            <Route exact path="/:publisherId/:repoName+" component={ListingDetailPage} />
            <Route exact path="/:registryAliasName" component={RegistryPage} />
          </Switch>
        </UnifiedAppLayout>
      </SearchProvider>
      <Redirect to="/" />
    </Switch>
  );
};
