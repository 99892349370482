import React, { FunctionComponent } from 'react';

import { Container, Grid } from '@amzn/awsui-components-react/polaris';

import FallbackImage from '@spencer/components/common/FallbackImage';
import fallbackImage from '@spencer/components/common/default-logo-small.png';
import SearchCardTitle from './SearchCardTitle';
import SearchCardAuthor from './SearchCardAuthor';
import SearchCardDescription from './SearchCardDescription';
import SearchCardTags from './SearchCardTags';
import { bemPrefix } from './common';

import './SearchResultCard.scss';

const baseGridDefinition = [
  { colspan: { default: 12, xxs: 2, s: 1 } },
  { colspan: { default: 12, xxs: 10, s: 11 } },
];

interface ISearchResultCardProps {
  repositoryTitle: string;
  repositoryImageSrc: string;
  repositoryAuthor: string;
  repositoryDescription: string;
  repositoryTags: string[];
  publisherId: string;
  verifiedAuthor: boolean;
  repositoryDownloads?: number;
  isPublisherPage?: boolean;
}

const SearchResultCard: FunctionComponent<ISearchResultCardProps> = ({
  repositoryTitle,
  repositoryImageSrc,
  repositoryAuthor,
  repositoryDescription,
  repositoryTags,
  publisherId,
  verifiedAuthor,
  repositoryDownloads,
  isPublisherPage,
}) => (
  <div className={bemPrefix}>
    <Container className={`${bemPrefix}__Container`}>
      <Grid gridDefinition={baseGridDefinition}>
        <FallbackImage
          className="SearchResultCard__Container__image"
          imageSrc={verifiedAuthor ? repositoryImageSrc : fallbackImage}
          fallbackSrc={fallbackImage}
        />
        <div className={`${bemPrefix}__Container__info`}>
          <SearchCardTitle
            title={repositoryTitle}
            publisherId={publisherId}
            downloads={repositoryDownloads}
          />
          <SearchCardAuthor
            author={repositoryAuthor}
            verified={verifiedAuthor}
            publisherId={publisherId}
            isPublisherPage={isPublisherPage ?? false}
          />
          <SearchCardDescription description={repositoryDescription} />
          <SearchCardTags tags={repositoryTags} />
        </div>
      </Grid>
    </Container>
  </div>
);

export default SearchResultCard;
