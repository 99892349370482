import React, { FC } from 'react';
import Alert from '@spencer/components/common/Alert';
import AlertContentHandler from '@spencer/components/common/Alert/AlertContentHandler';
import { AWSError } from 'aws-sdk';

interface IListDetailAlert {
  error: AWSError;
}

const ListDetailAlert: FC<IListDetailAlert> = ({ error }) => {
  const errorType = error.code ?? '';
  const exceptionContent = AlertContentHandler(errorType);
  return <Alert alertContent={exceptionContent} />;
};

export default ListDetailAlert;
