import React, { FC } from 'react';
import { Icon } from '@amzn/awsui-components-react/polaris';

import './Paginator.scss';

const bemPrefix = 'Paginator';

interface IPage {
  isCurrentPage: boolean;
  page: number;
  onClick: () => void;
}

const getPageLink = (pageNumber: number) => {
  const urlSearchParams = new URLSearchParams(location.search);
  urlSearchParams.set('page', `${pageNumber}`);
  return `?${urlSearchParams}`;
};

const disableDefault = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  e.preventDefault();
};

enum CHEVRON_DIRECTION {
  BACK = 'BACK',
  FORWARD = 'FORWARD',
}
interface IWrapWithLink {
  isEnabled: boolean;
  pageNumber: number;
  direction: CHEVRON_DIRECTION;
}

const PageChevron: FC<IWrapWithLink> = ({ isEnabled, pageNumber, direction }) => {
  const baseElement = (
    <Icon
      name={direction === CHEVRON_DIRECTION.BACK ? `angle-left` : `angle-right`}
      size="normal"
      variant={isEnabled ? 'normal' : 'disabled'}
    />
  );

  if (isEnabled) {
    return (
      <a href={getPageLink(pageNumber)} onClick={disableDefault}>
        {baseElement}
      </a>
    );
  } else {
    return baseElement;
  }
};

const Page: FC<IPage> = ({ isCurrentPage, page, onClick }) => {
  const componentBemPrefix = `${bemPrefix}__Pages`;
  const isCurrentPageModifier = isCurrentPage ? `${componentBemPrefix}--current` : '';

  // return an anchor tag to improve SEO
  return (
    <li
      data-testid={`paginator-page-${page}`}
      className={`${componentBemPrefix} ${isCurrentPageModifier}`}
      onClick={onClick}
    >
      <a href={getPageLink(page)} onClick={disableDefault}>
        {page}
      </a>
    </li>
  );
};

interface IPaginatorProps {
  setPage: (page: number) => void;
  currentPage: number;
  totalPages: number;
  hasNextPage?: boolean;
}

const Paginator: FC<IPaginatorProps> = ({ setPage, currentPage, totalPages }) => {
  const hasNextPage = currentPage < totalPages;

  const pages = [];

  let startWindow: number;
  startWindow = Math.min(currentPage - 2, totalPages - 4);
  startWindow = Math.max(1, startWindow);

  const endWindow = Math.min(startWindow + 4, totalPages);

  for (let i = startWindow; i <= endWindow; i++) {
    pages.push(
      <Page
        key={i}
        isCurrentPage={i === currentPage}
        page={i}
        onClick={() => {
          setPage(i);
        }}
      />
    );
  }
  return (
    <ul className={bemPrefix}>
      {pages?.length ? (
        <div>
          <li
            className={`${bemPrefix}__Chevron ${
              currentPage === 1 ? `${bemPrefix}__Chevron--disabled` : ''
            }`}
            onClick={() => {
              currentPage !== 1 && setPage(currentPage - 1);
            }}
            data-testid="prevPage"
          >
            <PageChevron
              isEnabled={currentPage !== 1}
              pageNumber={currentPage - 1}
              direction={CHEVRON_DIRECTION.BACK}
            />
          </li>
          {pages}
          <li
            className={`${bemPrefix}__Chevron ${
              !hasNextPage ? `${bemPrefix}__Chevron--disabled` : ''
            }`}
            onClick={() => {
              hasNextPage && setPage(currentPage + 1);
            }}
            data-testid="nextPage"
          >
            <PageChevron
              isEnabled={hasNextPage}
              pageNumber={currentPage + 1}
              direction={CHEVRON_DIRECTION.FORWARD}
            />
          </li>
        </div>
      ) : null}
    </ul>
  );
};

export default Paginator;
