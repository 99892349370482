import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { ReactQueryDevtools } from 'react-query-devtools';
import RouteSuspenseFallback from './components/RouteSuspenseFallback';

import { AppRoutes } from './AppRoutes';

import { ECR_PUBLIC_GALLERY } from '@spencer/util/constants';

import '@amzn/awsui-global-styles/polaris.css';

export type HistoryState = {
  from?: string;
};

export default function App() {
  return (
    <div id="gallery-app" style={{ fontFamily: '"Amazon Ember", Helvetica, Arial' }}>
      <HelmetProvider>
        <BrowserRouter>
          <Suspense fallback={<RouteSuspenseFallback />}>
            <Helmet>
              <title>{ECR_PUBLIC_GALLERY}</title>
              <meta
                property="og:description"
                content={`${ECR_PUBLIC_GALLERY} is a website that allows anyone to browse and search for public container images, view developer-provided details, and see pull commands"`}
              />
              <meta
                name="description"
                content={`${ECR_PUBLIC_GALLERY} is a website that allows anyone to browse and search for public container images, view developer-provided details, and see pull commands"`}
              />
              <meta property="og:url" content="https://gallery.ecr.aws/" />
            </Helmet>
            <AppRoutes />
          </Suspense>
        </BrowserRouter>
        <ReactQueryDevtools />
      </HelmetProvider>
    </div>
  );
}
