import {
  ButtonProps,
  ButtonDropdownProps,
  NonCancelableCustomEvent,
} from '@amzn/awsui-components-react/polaris';

import {
  generateServiceName,
  getRepoPort,
  COMPUTE_SERVICES,
} from '@spencer/components/common/LaunchWithAppRunnerButton/utils/launchWithAppRunnerHelpers';

const appRunnerLaunchLink =
  'https://us-east-1.console.aws.amazon.com/apprunner/home?region=us-east-1#/create';

const launchAppRunner = (publisherId, repoName, imageTag) => {
  const launchParams = new URLSearchParams();
  launchParams.set(
    'serviceName',
    generateServiceName({ repositoryName: repoName, computeService: COMPUTE_SERVICES.APPRUNNER })
  );
  launchParams.set('imageUri', `public.ecr.aws/${publisherId}/${repoName}:${imageTag}`);
  launchParams.set(
    'port',
    `${getRepoPort({ registryAlias: publisherId, repositoryName: repoName })}`
  );
  launchParams.set('from', 'public-gallery');
  window.open(`${appRunnerLaunchLink}?${launchParams}`, '_blank', 'noopener');
};

export const handleLaunchWithAppRunnerItemClick =
  (e: NonCancelableCustomEvent<ButtonDropdownProps.ItemClickDetails>) =>
  (publisherId: string, repoName: string, imageTag: string) => {
    const clickedItem = e.detail;
    if (clickedItem.id === 'apprunner') {
      launchAppRunner(publisherId, repoName, imageTag);
    } else if (clickedItem.id === 'lambda') {
      window.open(appRunnerLaunchLink, '_blank', 'noopener');
    }
  };

export const handleLaunchWithAppRunnerButtonClick =
  (_e: NonCancelableCustomEvent<ButtonProps.ClickDetail>) =>
  (publisherId: string, repoName: string, imageTag: string) =>
    launchAppRunner(publisherId, repoName, imageTag);
