import { useEffect, useState } from 'react';

export const useScrollY = () => {
  const [scrollY, setScrollY] = useState<number>(window.scrollY);

  useEffect(() => {
    const updateScroll = () => setScrollY(window?.scrollY);
    window.addEventListener('scroll', () => updateScroll());

    return window.removeEventListener('scroll', () => updateScroll());
  }, []);

  return scrollY;
};
