import React, { FC, ReactNode } from 'react';

import { HeaderVariant } from '../Models/types';

import './Header.scss';
const scssRoot = 'HomeHeader';

interface HeaderProps {
  content: ReactNode;
  variant?: HeaderVariant;
  color?: string;
}

const header = (content: ReactNode, variant: HeaderVariant, color?: string) => {
  switch (variant) {
    case 'h1':
      return (
        <h1 className={`${scssRoot}-h1`} style={color ? { color } : undefined}>
          {content}
        </h1>
      );
    case 'h2':
      return (
        <h2 className={`${scssRoot}-h2`} style={color ? { color } : undefined}>
          {content}
        </h2>
      );
  }
};

export const Header: FC<HeaderProps> = props => {
  const variant = props.variant ?? 'h2';
  return header(props.content, variant, props.color);
};
