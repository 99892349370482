import React, { FC } from 'react';
import { CallToActionViewModel } from '../../Models/types';
import { HomeHeader } from '../HomeHeader/HomeHeader';
import { CallToActionItem } from './CallToActionItem';

import './CallToAction.scss';

interface CallToActionProps {
  vm: CallToActionViewModel;
}

const scssRoot = 'CallToAction';

export const CallToAction: FC<CallToActionProps> = props => {
  const { vm } = props;

  return (
    <div data-testid="CallToAction" className={scssRoot}>
      <div className={`${scssRoot}-header`}>
        <HomeHeader content={vm.header} />
      </div>
      <div className={`${scssRoot}-items`}>
        {vm.items.map(item => (
          <CallToActionItem key={item.copy} vm={item} />
        ))}
      </div>
    </div>
  );
};
