import { ECR_PUBLIC_GALLERY } from '@spencer/util/constants';
import { AWSError } from 'aws-sdk';

export enum ERROR_TYPE {
  REPOSITORY_NOT_FOUND_EXCEPTION = 'RepositoryNotFoundException',
  INVALID_PARAMETER_EXCEPTION = 'InvalidParameterException',
  SERVER_EXCEPTION = 'ServerException',
  REGISTRY_NOT_FOUND_EXCEPTION = 'RegistryNotFoundException',
}

export interface IErrorPageContent {
  title: string;
  errorDesc: string;
  hasDestLink: boolean;
  redirectBack: boolean;
  destLink?: string;
  destLinkDesc?: string;
  destLinkLabel?: string;
  optionalTip?: string;
}

const RegistryNotFoundExceptionContent: IErrorPageContent = {
  title: `Registry not found`,
  errorDesc: `The specified registry doesn't exist. Try again.`,
  hasDestLink: true,
  redirectBack: true,
  destLinkDesc: `The requested registry doesn't exist. To discover the available public registries, see the `,
};

const RepositoryNotFoundExceptionContent: IErrorPageContent = {
  title: `Repository not found`,
  errorDesc: `The requested repository does not exist or is no longer available.`,
  hasDestLink: true,
  redirectBack: true,
  destLinkDesc: `The requested repository doesn't exist. To discover the available public repositories, see the `,
};

const ServerExceptionContent: IErrorPageContent = {
  title: `Page not found`,
  errorDesc: `An unexpected error occurred. Retry the request.`,
  hasDestLink: false,
  redirectBack: false,
};

const InvalidParameterExceptionContent: IErrorPageContent = {
  title: `Invalid parameter specified`,
  errorDesc: `You specified an invalid parameter or the formatting is invalid. Verify the information and try again.`,
  hasDestLink: true,
  redirectBack: false,
  destLink: `https://docs.aws.amazon.com/AmazonECR/latest/public/public-repositories.html`,
  destLinkDesc: `For more information on the available parameters to use when creating or updating a public repository, see `,
  destLinkLabel: `${ECR_PUBLIC_GALLERY} User Guide`,
  optionalTip: `Use the repository catalog data to help users find, understand, and use the images in the public repository.`,
};

const ErrorPageContentHandler = (error: AWSError) => {
  const errorType = error.code ?? '';
  switch (errorType) {
    case ERROR_TYPE.REGISTRY_NOT_FOUND_EXCEPTION:
      return RegistryNotFoundExceptionContent;
    case ERROR_TYPE.REPOSITORY_NOT_FOUND_EXCEPTION:
      return RepositoryNotFoundExceptionContent;
    case ERROR_TYPE.SERVER_EXCEPTION:
      return ServerExceptionContent;
    case ERROR_TYPE.INVALID_PARAMETER_EXCEPTION:
      return InvalidParameterExceptionContent;
    default:
      return ServerExceptionContent;
  }
};

export default ErrorPageContentHandler;
