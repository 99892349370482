import React from 'react';

import { ImageTagDetail } from '@spencer/apis/ecrPublicClient';

import ImageMediaTypeText from '@spencer/components/common/ImageMediaTypeText';

const TypeCell = (item: ImageTagDetail) => (
  <ImageMediaTypeText
    imageManifestMediaType={item.imageDetail?.imageManifestMediaType}
    artifactMediaType={item.imageDetail?.artifactMediaType}
  />
);

export default TypeCell;
