import React, { FC } from 'react';

//import { isRegistryHidden } from '@spencer/util/hideRegistry';
import fallbackImage from '../default-logo-large.png';

import {
  useListingQueryState,
  useImageDetailListQueryState,
  useRegistryCatalogQuery,
} from '../hooks';
import { Box, Grid, Spinner } from '@amzn/awsui-components-react/polaris';
import ListingDetailInfo from './ListingDetailInfo';
import ListingDetailPullSelect from './ListingDetailPullSelect';
import FallbackImage from '@spencer/components/common/FallbackImage';
import { getFilteredTags } from '@spencer/components/common/filters';

const baseGridDefinition = [
  { colspan: { default: 12, xs: 2 } },
  { colspan: { default: 12, xs: 9 } },
];

const bemPrefix = 'ListingDetailHeader';

interface IListingDetailHeader {
  publisherId: string;
  repoName: string;
}

const ListingDetailHeader: FC<IListingDetailHeader> = ({ publisherId, repoName }) => {
  const { isLoading: isLoadingListing, data: listingData } = useListingQueryState(
    publisherId,
    repoName
  );
  const { isLoading: isLoadingTags, data: imageTagDetailList } = useImageDetailListQueryState(
    publisherId,
    repoName
  );
  const { data: registryData } = useRegistryCatalogQuery(publisherId);

  //const hiddenAlias = isRegistryHidden(publisherId); // <-- This flag should be moved upstream to manage displaying the page or not
  const filteredTags = getFilteredTags([
    ...(listingData?.catalogData?.operatingSystems
      ? listingData?.catalogData?.operatingSystems
      : []),
    ...(listingData?.catalogData?.architectures ? listingData?.catalogData?.architectures : []),
  ]);
  const logoSrc = listingData?.catalogData?.logoUrl;
  const verifiedAccount = registryData?.verified ?? false;

  return (
    <Box margin={{ bottom: 'l' }}>
      <Grid gridDefinition={baseGridDefinition} disableGutters>
        <FallbackImage
          className={`${bemPrefix}__gridContainer__repoInfo__logo`}
          imageSrc={verifiedAccount ? logoSrc : ''}
          fallbackSrc={fallbackImage}
        />
        <Box margin={{ left: 'xxl' }}>
          {isLoadingListing ? (
            <Spinner />
          ) : (
            <ListingDetailInfo
              publisherId={publisherId}
              repoName={repoName}
              downloads={listingData?.insightData?.downloadCount}
              registryOwner={registryData?.registryCatalogData.displayName}
              verifiedAccount={verifiedAccount}
            />
          )}
          {isLoadingListing ? null : (
            <ListingDetailPullSelect
              publisherId={publisherId}
              repoName={repoName}
              isLoading={isLoadingTags}
              imageTagDetailList={imageTagDetailList}
            />
          )}
          <Box
            display="block"
            variant="p"
            padding={{ vertical: 'xxxs' }}
            data-testid="repositoryDescription"
          >
            {listingData?.catalogData?.description}
          </Box>
          {filteredTags.length > 0 ? (
            <Box display="block" variant="p" padding={{ vertical: 'xxxs' }}>
              OS/Arch:&nbsp;{filteredTags.join(', ')}
            </Box>
          ) : null}
          {listingData?.catalogData?.marketplaceCertified ? (
            <Box display="block" variant="p" padding={{ vertical: 'xxxs' }}>
              AWS Marketplace Certified
            </Box>
          ) : null}
        </Box>
      </Grid>
    </Box>
  );
};

export default ListingDetailHeader;
