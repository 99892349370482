import React, { FC, useState } from 'react';
import {
  Table,
  TextFilter,
  Pagination,
  CollectionPreferences,
  CollectionPreferencesProps,
  TableProps,
} from '@amzn/awsui-components-react/polaris';
import { Header, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import { AWSError } from 'aws-sdk/lib/error';
import { ImageTagDetail } from '@spencer/apis/ecrPublicClient';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { FEATURES, isFeatureEnabled } from '@spencer/util/featureControl';

import LaunchWithAppRunnerButton, {
  enableLaunchWithAppRunner,
} from '@spencer/components/common/LaunchWithAppRunnerButton';

import {
  PAGE_SIZE_OPTIONS,
  DEFAULT_PAGE_SIZE,
  paginationLabels,
} from './ListingDetailTagsTableConfig';
import Empty from '@spencer/components/common/Empty';
import { getColumnDefinitions } from './columnDefinitions';
import ListDetailAlert from '../../ListDetailAlert';

interface IListingDetailTagsTableProps {
  imageTagDetailList: ImageTagDetail[];
  isLoading: boolean;
  publisherId: string;
  repoName: string;
  error: AWSError;
}

const ListingDetailTagsTable: FC<IListingDetailTagsTableProps> = ({
  isLoading,
  imageTagDetailList,
  publisherId,
  repoName,
  error,
}) => {
  const [columnDefinitions] = useState(getColumnDefinitions(publisherId, repoName));
  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>(DEFAULT_PAGE_SIZE);
  const showLaunchWithAppRunner = enableLaunchWithAppRunner({
    registryAlias: publisherId,
    repositoryName: repoName,
  });
  const isListDetailAlertEnabled = isFeatureEnabled(FEATURES.listDetailAlert);
  const { items, actions, paginationProps, filterProps, collectionProps } = useCollection(
    imageTagDetailList ?? [],
    {
      pagination: { pageSize: preferences.pageSize, defaultPage: 0 },
      filtering: {
        empty: (
          <Empty
            heading={
              !isListDetailAlertEnabled && error?.code === 'RepositoryNotFoundException'
                ? error.message
                : 'No resources'
            }
            description={'No tags to display.'}
            includeButton={false}
          />
        ),
        noMatch: (
          <Empty
            includeButton={true}
            heading={'No matches'}
            description={'We can’t find a match.'}
            buttonLabel={'Clear filter'}
            buttonEventHandler={() => actions.setFiltering('')}
          />
        ),
      },
      selection: showLaunchWithAppRunner ? { keepSelection: true } : null,
      sorting: {},
    }
  );

  const currentPage = paginationProps.currentPageIndex;
  const pageSize = preferences.pageSize;
  const startingPageItem = currentPage === 1 ? 1 : pageSize * (currentPage - 1);
  const lastPageItem = pageSize * currentPage;

  const selectedItems = collectionProps.selectedItems;

  const selectionTypeSingle: TableProps.SelectionType = 'single';
  const selectionTypeConfig = showLaunchWithAppRunner ? { selectionType: selectionTypeSingle } : {};

  if (error !== null && error.code !== 'RepositoryNotFoundException' && isListDetailAlertEnabled) {
    return <ListDetailAlert error={error} />;
  }

  return (
    <React.Fragment>
      {error?.code === 'RepositoryNotFoundException' && isListDetailAlertEnabled && (
        <ListDetailAlert error={error} />
      )}
      <Table
        {...collectionProps}
        {...selectionTypeConfig}
        loading={isLoading}
        loadingText="Loading tags"
        columnDefinitions={columnDefinitions}
        items={items ?? []}
        wrapLines
        stickyHeader
        header={
          <Header
            variant="h2"
            actions={
              showLaunchWithAppRunner ? (
                <SpaceBetween direction="horizontal" size="xs">
                  <LaunchWithAppRunnerButton
                    publisherId={publisherId}
                    repoName={repoName}
                    imageTag={selectedItems[0]?.imageTag}
                    isLoading={isLoading}
                    disabled={selectedItems?.length === 0}
                  />
                </SpaceBetween>
              ) : null
            }
            counter={
              imageTagDetailList?.length
                ? `(${startingPageItem} - ${
                    lastPageItem > imageTagDetailList.length
                      ? imageTagDetailList.length
                      : lastPageItem
                  } of ${imageTagDetailList.length})`
                : '0 of 0'
            }
          >
            Image Tags
          </Header>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={<TextFilter {...filterProps} filteringPlaceholder="Find images" />}
        preferences={
          <CollectionPreferences
            title="Tags table preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: 'Page size',
              options: PAGE_SIZE_OPTIONS,
            }}
          />
        }
      />
    </React.Fragment>
  );
};

export default ListingDetailTagsTable;
