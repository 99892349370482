import { useReducer } from 'react';

import { SelectProps } from '@amzn/awsui-components-react/polaris';
import { RepositoryCatalogSearchResult } from '@spencer/apis/ecrPublicClient';

export enum ACTION {
  UPDATE_PAGE_DATA = 'UPDATE_PAGE_DATA',
  SET_SEARCH_SORT = 'SET_SEARCH_SORT',
  SET_FILTER_BAR_VISIBILITY = 'SET_FILTER_BAR_VISIBILITY',
  SET_IS_LOADING_MULTIPLE_PAGES = 'SET_IS_LOADING_MULTIPLE_PAGES',
}

interface BasicAction {
  type: ACTION;
  payload?: Record<string, unknown>;
}

interface ISetPageStartAction extends BasicAction {
  type: ACTION.UPDATE_PAGE_DATA;
  payload: {
    searchResults: RepositoryCatalogSearchResult[];
    pageStartNumber: number;
    pageEndNumber: number;
  };
}

interface ISetSearchSortAction extends BasicAction {
  type: ACTION.SET_SEARCH_SORT;
  payload: {
    sortOption: SelectProps.Option;
  };
}

interface ISetFilterBarVisibilityAction extends BasicAction {
  type: ACTION.SET_FILTER_BAR_VISIBILITY;
  payload: {
    shouldShow: boolean;
  };
}

interface ISetInitialLoad extends BasicAction {
  type: ACTION.SET_IS_LOADING_MULTIPLE_PAGES;
  payload: {
    isLoadingMultiplePages: boolean;
  };
}

type Actions =
  | ISetPageStartAction
  | ISetSearchSortAction
  | ISetFilterBarVisibilityAction
  | ISetInitialLoad;

interface State {
  pageStart: number;
  pageEnd: number;
  showFilterBar: boolean;
  selectedSearchSortOption: SelectProps.Option;
  searchResults: RepositoryCatalogSearchResult[];
  isLoadingMultiplePages: boolean;
}

const baseState = {
  pageStart: 1,
  pageEnd: 1,
  showFilterBar: false,
  searchResults: [],
};

export interface IStateInitializerProps {
  defaultSearchSortOption: SelectProps.Option;
  startingPage?: {
    pageStart: number;
    pageEnd: number;
  };
}

export const stateInitializer = ({
  defaultSearchSortOption,
  startingPage,
}: IStateInitializerProps) => {
  return useReducer(
    (state: State, action: Actions) => {
      switch (action.type) {
        case ACTION.UPDATE_PAGE_DATA: {
          return {
            ...state,
            searchResults: action.payload.searchResults,
            pageStart: action.payload.pageStartNumber,
            pageEnd: action.payload.pageEndNumber,
          };
        }
        case ACTION.SET_SEARCH_SORT: {
          return {
            ...state,
            selectedSearchSortOption: action.payload.sortOption,
          };
        }
        case ACTION.SET_FILTER_BAR_VISIBILITY: {
          return {
            ...state,
            showFilterBar: action.payload.shouldShow,
          };
        }
        case ACTION.SET_IS_LOADING_MULTIPLE_PAGES: {
          return {
            ...state,
            isLoadingMultiplePages: action.payload.isLoadingMultiplePages,
          };
        }
        default: {
          throw new Error('Unsupported state update.');
        }
      }
    },
    {
      ...baseState,
      isLoadingMultiplePages: false,
      selectedSearchSortOption: defaultSearchSortOption,
      ...(startingPage
        ? {
            pageStart: startingPage.pageStart,
            pageEnd: startingPage.pageEnd,
          }
        : null),
    }
  );
};
