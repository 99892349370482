import React from 'react';

import { Spinner } from '@amzn/awsui-components-react/polaris';
import UnifiedAppLayout from './UnifiedAppLayout';

class RouteSuspenseFallback extends React.Component {
  public render() {
    return (
      <UnifiedAppLayout>
        <Spinner size="large" />
      </UnifiedAppLayout>
    );
  }
}

export default RouteSuspenseFallback;
