import { useReducer } from 'react';

export enum SEARCH_SORT {
  'POPULARITY' = 'POPULARITY',
  'RELEVANCE' = 'RELEVANCE',
}

export interface ISearchState {
  searchTerm: string;
  page: number;
  marketplaceFilter: boolean;
  verifiedFilter: boolean;
  linuxFilter: boolean;
  windowsFilter: boolean;
  armFilter: boolean;
  arm64Filter: boolean;
  x86Filter: boolean;
  x8664Filter: boolean;
  ppcFilter: boolean;
  zFilter: boolean;
  amazonFilter: boolean;
  dockerFilter: boolean;
  searchSortOrder: SEARCH_SORT;
  clearNavSearch: number;
}

export enum ACTION {
  SET_SEARCH_TERM = 'SET_SEARCH_TERM',
  SET_SEARCH_TERM_AND_PAGE = 'SET_SEARCH_TERM_AND_PAGE',
  SET_PAGE = 'SET_PAGE',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
  UPDATE_FROM_URL = 'UPDATE_FROM_URL',
  UPDATE_SEARCH_SORT = 'UPDATE_SEARCH_SORT',
  CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS',
  CLEAR_ALL_FILTERS_AND_RESET_PAGE = 'CLEAR_ALL_FILTERS_AND_RESET_PAGE',
  CLEAR_NAV_SEARCH_EVENT = 'CLEAR_NAV_SEARCH_EVENT',
}

export type IUpdateFiltersPayload = {
  verifiedFilter?: boolean;
  linuxFilter?: boolean;
  windowsFilter?: boolean;
  armFilter?: boolean;
  arm64Filter?: boolean;
  x86Filter?: boolean;
  x8664Filter?: boolean;
  ppcFilter?: boolean;
  zFilter?: boolean;
  amazonFilter?: boolean;
  dockerFilter?: boolean;
};

interface IBasicAction {
  type: ACTION;
  payload?: Record<string, unknown>;
}

interface ISetSearchTermAction extends IBasicAction {
  type: ACTION.SET_SEARCH_TERM;
  payload: {
    searchTerm: string;
  };
}

interface ISetPageAction extends IBasicAction {
  type: ACTION.SET_PAGE;
  payload: {
    page: number;
  };
}

interface ISetSearchTermAndPageAndSortAction extends IBasicAction {
  type: ACTION.SET_SEARCH_TERM_AND_PAGE;
  payload: {
    searchTerm: string;
    page: number;
  };
}

interface IUpdateFiltersAction extends IBasicAction {
  type: ACTION.UPDATE_FILTERS;
  payload: IUpdateFiltersPayload;
}

interface IUpdateFromUrlAction extends IBasicAction {
  type: ACTION.UPDATE_FROM_URL;
  payload: IParamsFromUrl;
}

interface IUpdateSearchSortAction extends IBasicAction {
  type: ACTION.UPDATE_SEARCH_SORT;
  payload: {
    sort: SEARCH_SORT;
  };
}

interface IClearAllFiltersAction extends IBasicAction {
  type: ACTION.CLEAR_ALL_FILTERS;
}

interface IClearAllFiltersAndResetPageAction extends IBasicAction {
  type: ACTION.CLEAR_ALL_FILTERS_AND_RESET_PAGE;
}

interface IClearNavSearchEvent extends IBasicAction {
  type: ACTION.CLEAR_NAV_SEARCH_EVENT;
}

type Actions =
  | ISetSearchTermAction
  | ISetPageAction
  | ISetSearchTermAndPageAndSortAction
  | IUpdateFiltersAction
  | IUpdateFromUrlAction
  | IUpdateSearchSortAction
  | IClearAllFiltersAction
  | IClearAllFiltersAndResetPageAction
  | IClearNavSearchEvent;

interface IStateInitializerProps {
  defaultSearchSortOption: SEARCH_SORT;
}

const filterReducer = (state: ISearchState, filters: IUpdateFiltersPayload) => {
  const {
    verifiedFilter,
    linuxFilter,
    windowsFilter,
    armFilter,
    arm64Filter,
    x86Filter,
    x8664Filter,
    ppcFilter,
    zFilter,
    amazonFilter,
    dockerFilter,
  } = filters;
  return {
    ...state,
    ...(verifiedFilter !== undefined ? { verifiedFilter } : {}),
    ...(linuxFilter !== undefined ? { linuxFilter } : {}),
    ...(windowsFilter !== undefined ? { windowsFilter } : {}),
    ...(armFilter !== undefined ? { armFilter } : {}),
    ...(arm64Filter !== undefined ? { arm64Filter } : {}),
    ...(x86Filter !== undefined ? { x86Filter } : {}),
    ...(x8664Filter !== undefined ? { x8664Filter } : {}),
    ...(ppcFilter !== undefined ? { ppcFilter } : {}),
    ...(zFilter !== undefined ? { zFilter } : {}),
    ...(amazonFilter !== undefined ? { amazonFilter } : {}),
    ...(dockerFilter !== undefined ? { dockerFilter } : {}),
  };
};

const clearAllFiltersReducer = (state: ISearchState) =>
  filterReducer(state, {
    verifiedFilter: false,
    linuxFilter: false,
    windowsFilter: false,
    armFilter: false,
    arm64Filter: false,
    x86Filter: false,
    x8664Filter: false,
    ppcFilter: false,
    zFilter: false,
    amazonFilter: false,
    dockerFilter: false,
  });

const initialState: ISearchState = {
  searchTerm: '',
  page: 1,
  searchSortOrder: SEARCH_SORT.POPULARITY,
  marketplaceFilter: false,
  verifiedFilter: false,
  linuxFilter: false,
  windowsFilter: false,
  armFilter: false,
  arm64Filter: false,
  x86Filter: false,
  x8664Filter: false,
  ppcFilter: false,
  zFilter: false,
  amazonFilter: false,
  dockerFilter: false,
  clearNavSearch: 0,
};

export type IParamsFromUrl = {
  searchTerm?: string;
  page?: number;
  searchSortOrder?: SEARCH_SORT;
  verifiedFilter?: boolean;
  linuxFilter?: boolean;
  windowsFilter?: boolean;
  armFilter?: boolean;
  arm64Filter?: boolean;
  x86Filter?: boolean;
  x8664Filter?: boolean;
  amazonFilter?: boolean;
  dockerFilter?: boolean;
};

// TODO: consider a separate bypass action for home page update
const updatePageInURL = (pageNumber: number) => {
  if (['/', '/home'].includes(location.pathname)) return;
  const urlSearchParams = new URLSearchParams(location.search);
  urlSearchParams.set('page', `${pageNumber}`);
  history.replaceState(history.state, '', `${location.pathname}?${urlSearchParams}`);
};

const reducer = (state: ISearchState, action: Actions): ISearchState => {
  switch (action.type) {
    case ACTION.SET_SEARCH_TERM_AND_PAGE: {
      const { searchTerm, page } = action.payload;
      updatePageInURL(page);
      return {
        ...state,
        searchTerm,
        page,
      };
    }
    case ACTION.SET_SEARCH_TERM: {
      const { searchTerm } = action.payload;
      return {
        ...state,
        searchTerm,
      };
    }
    case ACTION.SET_PAGE: {
      const { page } = action.payload;
      updatePageInURL(page);
      return { ...state, page };
    }
    case ACTION.UPDATE_FILTERS: {
      return filterReducer(state, action.payload);
    }
    case ACTION.UPDATE_FROM_URL: {
      const { searchTerm, page } = action.payload;
      return {
        ...state,
        ...filterReducer(state, action.payload),
        searchTerm,
        page,
      };
    }
    case ACTION.UPDATE_SEARCH_SORT: {
      const { sort } = action.payload;
      return {
        ...state,
        searchSortOrder: sort,
      };
    }
    case ACTION.CLEAR_ALL_FILTERS: {
      return {
        ...state,
        ...clearAllFiltersReducer(state),
      };
    }
    case ACTION.CLEAR_ALL_FILTERS_AND_RESET_PAGE: {
      updatePageInURL(1);
      return {
        ...state,
        ...clearAllFiltersReducer(state),
        page: 1,
      };
    }
    case ACTION.CLEAR_NAV_SEARCH_EVENT: {
      const count = state.clearNavSearch + 1;
      return {
        ...state,
        clearNavSearch: count,
      };
    }
    default: {
      throw new Error('Unsupported search context update');
    }
  }
};

export const stateInitializer = (paramsFromUrl: IParamsFromUrl) =>
  useReducer(reducer, { ...initialState, ...paramsFromUrl });
