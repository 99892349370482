import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { HistoryState } from '@spencer/App';
import { Box, BreadcrumbGroup, BreadcrumbGroupProps } from '@amzn/awsui-components-react/polaris';
import { CancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import { SearchContext } from '../SearchContext';
import { BreadcrumbsVM } from './Util/model';
import { ECR_PUBLIC_GALLERY } from '@spencer/util/constants';

interface BreadcrumbsProps {
  vm: BreadcrumbsVM;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = props => {
  const history = useHistory<HistoryState>();
  const { resetState } = useContext(SearchContext);

  const { vm } = props;

  const handleBreadcrumbClick: CancelableEventHandler<
    BreadcrumbGroupProps.ClickDetail<BreadcrumbGroupProps.Item>
  > = event => {
    event.preventDefault();
    const { detail } = event;

    history.push(detail.href);
    resetState();
  };

  return (
    <Box margin={{ bottom: 'xxl' }}>
      <BreadcrumbGroup
        items={vm}
        onClick={handleBreadcrumbClick}
        ariaLabel={`${ECR_PUBLIC_GALLERY} breadscrumbs`}
      />
    </Box>
  );
};
