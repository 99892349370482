export const hiddenRepositoryMap = {
  w6b6a9x1: {
    'dynatrace/oneagent': null,
  },
};

export const isRepositoryHidden = (registryAlias: string, repositoryName: string) => {
  const registryAliasFormatted = registryAlias.toLocaleLowerCase();
  const repositoryNameFormatted = repositoryName.toLocaleLowerCase();

  if (hiddenRepositoryMap[registryAliasFormatted] !== undefined) {
    return hiddenRepositoryMap[registryAliasFormatted][repositoryNameFormatted] !== undefined;
  } else {
    return false;
  }
};
