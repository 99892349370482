import { useReducer } from 'react';

import { SelectProps } from '@amzn/awsui-components-react/polaris';
import {
  DescribeRepositoryCatalogDataResponse,
  RepositoryCatalogDataItem,
} from '@spencer/apis/ecrPublicClient';

import { FILTER_VALUES, getFilterGroups } from '@spencer/components/common/filters';

export enum ACTION {
  UPDATE_PAGE_DATA = 'UPDATE_PAGE_DATA',
  SET_PAGE = 'SET_PAGE',
  SET_SORT = 'SET_SORT',
  SET_FILTER_BAR_VISIBILITY = 'SET_FILTER_BAR_VISIBILITY',
  SET_IS_LOADING_MULTIPLE_PAGES = 'SET_IS_LOADING_MULTIPLE_PAGES',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
  UPDATE_FROM_URL = 'UPDATE_FROM_URL',
  CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS',
  CLEAR_ALL_FILTERS_AND_RESET_PAGE = 'CLEAR_ALL_FILTERS_AND_RESET_PAGE',
}

interface BasicAction {
  type: ACTION;
  payload?: Record<string, unknown>;
}

export type IUpdateFiltersPayload = {
  verifiedFilter?: boolean;
  linuxFilter?: boolean;
  windowsFilter?: boolean;
  armFilter?: boolean;
  arm64Filter?: boolean;
  x86Filter?: boolean;
  x8664Filter?: boolean;
  ppcFilter?: boolean;
  zFilter?: boolean;
};

interface ISetPageStartAction extends BasicAction {
  type: ACTION.UPDATE_PAGE_DATA;
  payload: {
    repositories?: RepositoryCatalogDataItem[];
    data?: DescribeRepositoryCatalogDataResponse[];
  };
}

interface ISetPage extends BasicAction {
  type: ACTION.SET_PAGE;
  payload: {
    page: number;
  };
}

interface ISetSortAction extends BasicAction {
  type: ACTION.SET_SORT;
  payload: {
    sortOption: SelectProps.Option;
  };
}

interface ISetFilterBarVisibilityAction extends BasicAction {
  type: ACTION.SET_FILTER_BAR_VISIBILITY;
  payload: {
    shouldShow: boolean;
  };
}

interface IUpdateFiltersAction extends BasicAction {
  type: ACTION.UPDATE_FILTERS;
  payload: IUpdateFiltersPayload;
}

interface IUpdateFromUrl extends BasicAction {
  type: ACTION.UPDATE_FROM_URL;
}

interface IClearAllFiltersAction extends BasicAction {
  type: ACTION.CLEAR_ALL_FILTERS;
}

interface IClearAllFiltersAndResetPageAction extends BasicAction {
  type: ACTION.CLEAR_ALL_FILTERS_AND_RESET_PAGE;
}

interface ISetInitialLoad extends BasicAction {
  type: ACTION.SET_IS_LOADING_MULTIPLE_PAGES;
  payload: {
    isLoadingMultiplePages: boolean;
  };
}

type Actions =
  | ISetPageStartAction
  | ISetPage
  | ISetSortAction
  | ISetFilterBarVisibilityAction
  | ISetInitialLoad
  | IUpdateFiltersAction
  | IUpdateFromUrl
  | IClearAllFiltersAction
  | IClearAllFiltersAndResetPageAction;

interface State {
  pageStart: number;
  pageEnd: number;
  page: number;
  totalPages: number;
  showFilterBar: boolean;
  selectedSortOption: SelectProps.Option;
  repositories: RepositoryCatalogDataItem[];
  filteredRepositories: RepositoryCatalogDataItem[];
  sortedRepositories: RepositoryCatalogDataItem[];
  chunkedRepositories: RepositoryCatalogDataItem[][];
  isLoadingMultiplePages: boolean;
  linuxFilter: boolean;
  windowsFilter: boolean;
  armFilter: boolean;
  arm64Filter: boolean;
  x86Filter: boolean;
  x8664Filter: boolean;
}

export interface IParamsFromUrl {
  page?: number;
  sortOrder?: SORT_OPTIONS;
  linuxFilter?: boolean;
  windowsFilter?: boolean;
  armFilter?: boolean;
  arm64Filter?: boolean;
  x86Filter?: boolean;
  x8664Filter?: boolean;
}

export interface IStateInitializerProps {
  defaultSortOption: SelectProps.Option;
  resultsPerPage: number;
  startingPage?: {
    pageStart: number;
    pageEnd: number;
  };
}

export enum SORT_OPTIONS {
  'ALPHA' = 'ALPHA',
  'POPULARITY' = 'POPULARITY',
}

const getInitialStateFromUrl = (): IParamsFromUrl => {
  const urlSearchParams = new URLSearchParams(location.search);
  const operatingSystemsFromUrl = urlSearchParams.get('operatingSystems')?.split(',');
  const architecturesFromUrl = urlSearchParams.get('architectures')?.split(',');
  const pageFromUrl = urlSearchParams.get('page');
  const sortOrderFromUrl = urlSearchParams.get('sortOrder');

  let sortOrder = SORT_OPTIONS.POPULARITY;
  // if search term is set, default to sorting by relevance
  // only set sortOrder to popularity if it is explicity set
  if (sortOrderFromUrl === 'alpha') {
    sortOrder = SORT_OPTIONS.ALPHA;
  }

  let page = 1;
  const parsedPageNumber = parseInt(pageFromUrl);
  if (parsedPageNumber && !Number.isNaN(parsedPageNumber)) {
    page = parsedPageNumber;
  }

  return {
    page,
    sortOrder,
    linuxFilter: operatingSystemsFromUrl?.includes(FILTER_VALUES.linux) ?? false,
    windowsFilter: operatingSystemsFromUrl?.includes(FILTER_VALUES.windows) ?? false,
    armFilter: architecturesFromUrl?.includes(FILTER_VALUES.arm) ?? false,
    arm64Filter: architecturesFromUrl?.includes(FILTER_VALUES.arm64) ?? false,
    x86Filter: architecturesFromUrl?.includes(FILTER_VALUES.x86) ?? false,
    x8664Filter: architecturesFromUrl?.includes(FILTER_VALUES.x8664) ?? false,
  };
};

const filterRepositories = (
  repos: RepositoryCatalogDataItem[],
  filters: IUpdateFiltersPayload
): RepositoryCatalogDataItem[] => {
  const operatingSystems = [];
  const architectures = [];
  if (filters?.linuxFilter) {
    operatingSystems.push(FILTER_VALUES.linux.toLocaleLowerCase());
  }
  if (filters?.windowsFilter) {
    operatingSystems.push(FILTER_VALUES.windows.toLocaleLowerCase());
  }
  if (filters?.armFilter) {
    architectures.push(FILTER_VALUES.arm.toLocaleLowerCase());
  }
  if (filters?.arm64Filter) {
    architectures.push(FILTER_VALUES.arm64.toLocaleLowerCase());
  }
  if (filters?.x86Filter) {
    architectures.push(FILTER_VALUES.x86.toLocaleLowerCase());
  }
  if (filters?.x8664Filter) {
    architectures.push(FILTER_VALUES.x8664.toLocaleLowerCase());
  }

  if (architectures.length === 0 && operatingSystems.length === 0) {
    return repos;
  }

  return repos?.filter(r => {
    let condition = true;

    if (architectures.length) {
      if (r?.catalogData?.architectures?.length) {
        condition = architectures.every(arch =>
          r?.catalogData?.architectures
            .map(a => a.toLocaleLowerCase())
            ?.includes(arch.toLocaleLowerCase())
        );
      } else {
        return false;
      }
    }

    if (operatingSystems.length) {
      if (r?.catalogData?.operatingSystems?.length) {
        condition =
          condition &&
          operatingSystems.every(os =>
            r?.catalogData?.operatingSystems
              ?.map(o => o.toLocaleLowerCase())
              .includes(os.toLocaleLowerCase())
          );
      } else {
        return false;
      }
    }
    return condition;
  });
};

const sortRepositories = (
  repositories: RepositoryCatalogDataItem[],
  sortOption: SORT_OPTIONS
): RepositoryCatalogDataItem[] => {
  if (sortOption === SORT_OPTIONS.ALPHA) {
    return repositories?.sort((a, b) => {
      return a.repositoryName.localeCompare(b.repositoryName);
    });
  } else if (sortOption === SORT_OPTIONS.POPULARITY) {
    return repositories?.sort((a, b) => {
      if (a.insightData.downloadCount > b.insightData.downloadCount) {
        return -1;
      } else if (a.insightData.downloadCount < b.insightData.downloadCount) {
        return 1;
      } else if (a.insightData.downloadCount === b.insightData.downloadCount) {
        return a.repositoryName.localeCompare(b.repositoryName);
      }
    });
  }
};

const chunkRepositories = (repositories: RepositoryCatalogDataItem[], resultsPerPage: number) => {
  return repositories?.reduce((all, one, i) => {
    const ch = Math.floor(i / resultsPerPage);
    all[ch] = [].concat(all[ch] || [], one);
    return all;
  }, []);
};

const updatePageStartEnd = (
  repositories: RepositoryCatalogDataItem[],
  page: number,
  resultsPerPage: number,
  isLoadingMultiplePages: boolean
) => {
  const totalPages = Math.ceil(Math.min(repositories?.length / resultsPerPage)) ?? 0;
  const actualPage = page > totalPages ? 1 : page;

  let pageStart = (actualPage - 1) * resultsPerPage + 1;
  let pageEnd = 0;

  if (repositories?.length === 0) {
    pageStart = 0;
    pageEnd = 0;
  } else if (repositories?.length > actualPage * resultsPerPage) {
    pageEnd = actualPage * resultsPerPage;
  } else {
    pageEnd = repositories?.length ?? 0;
  }

  if (!isLoadingMultiplePages && page > totalPages) {
    updatePageInURL(1);
  }

  return {
    pageStart,
    pageEnd,
    totalPages,
    ...(!isLoadingMultiplePages && page > totalPages ? { page: 1 } : {}),
  };
};

const updateComputedRepositories = (
  repositories: RepositoryCatalogDataItem[],
  filters: IUpdateFiltersPayload,
  sortOption: SORT_OPTIONS,
  page: number,
  resultsPerPage: number,
  isLoadingMultiplePages: boolean
) => {
  const filteredRepositories = filterRepositories(repositories, filters);
  const sortedRepositories = sortRepositories(filteredRepositories, sortOption);
  const chunkedRepositories = chunkRepositories(sortedRepositories, resultsPerPage);

  return {
    sortedRepositories,
    chunkedRepositories,
    filteredRepositories,
    ...updatePageStartEnd(sortedRepositories, page, resultsPerPage, isLoadingMultiplePages),
  };
};

const updatePageInURL = (pageNumber: number) => {
  const urlSearchParams = new URLSearchParams(location.search);
  urlSearchParams.set('page', `${pageNumber}`);
  history.replaceState(history.state, '', `${location.pathname}?${urlSearchParams}`);
};

const baseState = {
  pageStart: 1,
  pageEnd: 1,
  page: 1,
  totalPages: 0,
  showFilterBar: false,
  repositories: [],
  sortedRepositories: [],
  filteredRepositories: [],
  chunkedRepositories: [],
  linuxFilter: false,
  windowsFilter: false,
  armFilter: false,
  arm64Filter: false,
  x86Filter: false,
  x8664Filter: false,
  ...getInitialStateFromUrl(),
};

const getFilterState = (state: Partial<State>) => {
  return {
    ...getOSFilterState(state),
    ...getArchitectureFilterState(state),
  };
};

const getOSFilterState = (state: Partial<State>) => {
  return {
    linuxFilter: state.linuxFilter,
    windowsFilter: state.windowsFilter,
  };
};

const getArchitectureFilterState = (state: Partial<State>) => {
  return {
    armFilter: state.armFilter,
    arm64Filter: state.arm64Filter,
    x86Filter: state.x86Filter,
    x8664Filter: state.x8664Filter,
  };
};

const filterReducer = (state: State, filters: IUpdateFiltersPayload) => {
  const { linuxFilter, windowsFilter, armFilter, arm64Filter, x86Filter, x8664Filter } = filters;
  return {
    ...state,
    ...(linuxFilter !== undefined ? { linuxFilter } : {}),
    ...(windowsFilter !== undefined ? { windowsFilter } : {}),
    ...(armFilter !== undefined ? { armFilter } : {}),
    ...(arm64Filter !== undefined ? { arm64Filter } : {}),
    ...(x86Filter !== undefined ? { x86Filter } : {}),
    ...(x8664Filter !== undefined ? { x8664Filter } : {}),
  };
};

const clearAllFiltersReducer = (state: State) =>
  filterReducer(state, {
    linuxFilter: false,
    windowsFilter: false,
    armFilter: false,
    arm64Filter: false,
    x86Filter: false,
    x8664Filter: false,
  });

export const getConnectedFilterGroups = (state: State, dispatch: (value: Actions) => void) => {
  return getFilterGroups({
    linuxFilterOptions: {
      filterState: state.linuxFilter,
      updateFilter: (value: boolean) =>
        dispatch({ type: ACTION.UPDATE_FILTERS, payload: { linuxFilter: value } }),
    },
    windowsFilterOptions: {
      filterState: state.windowsFilter,
      updateFilter: (value: boolean) =>
        dispatch({ type: ACTION.UPDATE_FILTERS, payload: { windowsFilter: value } }),
    },
    armFilterOptions: {
      filterState: state.armFilter,
      updateFilter: (value: boolean) =>
        dispatch({ type: ACTION.UPDATE_FILTERS, payload: { armFilter: value } }),
    },
    arm64FilterOptions: {
      filterState: state.arm64Filter,
      updateFilter: (value: boolean) =>
        dispatch({ type: ACTION.UPDATE_FILTERS, payload: { arm64Filter: value } }),
    },
    x86FilterOptions: {
      filterState: state.x86Filter,
      updateFilter: (value: boolean) =>
        dispatch({ type: ACTION.UPDATE_FILTERS, payload: { x86Filter: value } }),
    },
    x8664FilterOptions: {
      filterState: state.x8664Filter,
      updateFilter: (value: boolean) =>
        dispatch({ type: ACTION.UPDATE_FILTERS, payload: { x8664Filter: value } }),
    },
  });
};

export const stateInitializer = ({
  defaultSortOption,
  startingPage,
  resultsPerPage,
}: IStateInitializerProps) => {
  return useReducer(
    (state: State, action: Actions) => {
      switch (action.type) {
        case ACTION.UPDATE_PAGE_DATA: {
          let repositories = [];
          if (action.payload?.repositories) {
            repositories = action.payload?.repositories;
          } else if (action.payload?.data) {
            repositories = action.payload?.data?.map(d => d?.repositories)?.flat();
          }

          return {
            ...state,
            repositories,
            ...updateComputedRepositories(
              repositories,
              getFilterState(state),
              state.selectedSortOption.value as SORT_OPTIONS,
              state.page,
              resultsPerPage,
              state.isLoadingMultiplePages
            ),
          };
        }
        case ACTION.SET_PAGE: {
          const { page } = action.payload;
          updatePageInURL(page);

          return {
            ...state,
            page,
            ...updatePageStartEnd(
              state.filteredRepositories,
              page,
              resultsPerPage,
              state.isLoadingMultiplePages
            ),
          };
        }
        case ACTION.SET_SORT: {
          return {
            ...state,
            selectedSortOption: action.payload.sortOption,
            ...updateComputedRepositories(
              state.repositories,
              getFilterState(state),
              action.payload.sortOption.value as SORT_OPTIONS,
              state.page,
              resultsPerPage,
              state.isLoadingMultiplePages
            ),
          };
        }
        case ACTION.SET_FILTER_BAR_VISIBILITY: {
          return {
            ...state,
            showFilterBar: action.payload.shouldShow,
          };
        }
        case ACTION.SET_IS_LOADING_MULTIPLE_PAGES: {
          return {
            ...state,
            isLoadingMultiplePages: action.payload.isLoadingMultiplePages,
          };
        }
        case ACTION.UPDATE_FILTERS: {
          const stateAfterNewFilters = filterReducer(state, action.payload);
          return {
            ...stateAfterNewFilters,
            ...updateComputedRepositories(
              state.repositories,
              getFilterState(stateAfterNewFilters),
              state.selectedSortOption.value as SORT_OPTIONS,
              1,
              resultsPerPage,
              state.isLoadingMultiplePages
            ),
          };
        }
        case ACTION.UPDATE_FROM_URL: {
          const stateFromUrl = getInitialStateFromUrl();
          const filterState = getFilterState(stateFromUrl);
          const stateAfterNewFilters = filterReducer(state, filterState);

          return {
            ...stateFromUrl,
            ...stateAfterNewFilters,
            ...updateComputedRepositories(
              state.repositories,
              getFilterState(stateAfterNewFilters),
              state.selectedSortOption.value as SORT_OPTIONS,
              state.page,
              resultsPerPage,
              state.isLoadingMultiplePages
            ),
          };
        }
        case ACTION.CLEAR_ALL_FILTERS: {
          return {
            ...state,
            ...clearAllFiltersReducer(state),
            ...updateComputedRepositories(
              state.repositories,
              {},
              state.selectedSortOption.value as SORT_OPTIONS,
              state.page,
              resultsPerPage,
              state.isLoadingMultiplePages
            ),
          };
        }
        case ACTION.CLEAR_ALL_FILTERS_AND_RESET_PAGE: {
          updatePageInURL(1);
          return {
            ...state,
            ...clearAllFiltersReducer(state),
            ...updateComputedRepositories(
              state.repositories,
              {},
              state.selectedSortOption.value as SORT_OPTIONS,
              1,
              resultsPerPage,
              state.isLoadingMultiplePages
            ),
            page: 1,
          };
        }
        default: {
          throw new Error('Unsupported state update.');
        }
      }
    },
    {
      ...baseState,
      isLoadingMultiplePages: false,
      selectedSortOption: defaultSortOption,
      ...(startingPage
        ? {
            pageStart: startingPage.pageStart,
            pageEnd: startingPage.pageEnd,
          }
        : null),
    }
  );
};
