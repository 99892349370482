import React, { FC } from 'react';
import { TopNavigationFilter } from '../Models/types';

import { recordHomeEvent } from '@spencer/customerMonitoringTelemetry/cwrumconfig';
import { EVENT_TYPES } from '@spencer/customerMonitoringTelemetry/customEventTypes';

import './FilterRadioGroup.scss';
const scssRoot = 'FilterRadioGroup';

interface FilterRadioGroupProps {
  filters: TopNavigationFilter[];
  selectedFilter: TopNavigationFilter;
  updateFilter: (filter: TopNavigationFilter) => void;
}

export const FilterRadioGroup: FC<FilterRadioGroupProps> = props => {
  const { filters, selectedFilter, updateFilter } = props;

  const handleFilterClick = (filter: TopNavigationFilter) => {
    updateFilter(filter);
    recordHomeEvent(EVENT_TYPES.HOME_CLICKED_FILTER_RADIO, { filter });
  };

  return (
    <div className={`${scssRoot}-container`}>
      {filters.map(filter => (
        <div
          className={`${scssRoot}-filter`}
          key={filter.label}
          onClick={() => handleFilterClick(filter)}
        >
          <input
            id={`FilterRadioGroup-${filter.label.split(' ').join('-')}`}
            type="radio"
            checked={filter.value === selectedFilter.value}
            readOnly
          />
          <label htmlFor={`FilterRadioGroup-${filter.label.split(' ').join('-')}`}>
            {filter.label}
          </label>
        </div>
      ))}
    </div>
  );
};
