import React, { createContext, FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { usePrevious } from '@spencer/util/hooks';

import { getFilterGroups, FILTER_VALUES, FilterGroupIds } from './../filters';

import {
  stateInitializer,
  IParamsFromUrl,
  IUpdateFiltersPayload,
  ISearchState,
  ACTION as SEARCH_CONTEXT_ACTION,
  SEARCH_SORT,
} from './searchState';
import { awsRum } from '@spencer/customerMonitoringTelemetry/cwrumconfig';
import {
  EVENT_TYPES,
  FILTER_BY_ATTRIBUTE,
  SEARCH_WITH_TERM_ATTRIBUTES,
} from '@spencer/customerMonitoringTelemetry/customEventTypes';

export { SEARCH_SORT };

// TODO: Search context seems overkill since it's tied to a single page but wraps all routes, refactor?

export interface ISearchFilter {
  filterState: boolean;
  updateFilter: React.Dispatch<React.SetStateAction<boolean>>;
  label: React.ReactNode;
  description?: string;
  testid?: string;
  value: string;
}

export interface ISearchFilterGroup {
  title: React.ReactNode;
  type: string;
  searchFilters: ISearchFilter[];
}

export interface SearchFilterGroupInfo {
  state: boolean;
  value: string;
}

export interface ISearchInfo {
  searchTerm: string;
  verified?: SearchFilterGroupInfo[];
  popularRegistries?: SearchFilterGroupInfo[];
  operatingSystems?: SearchFilterGroupInfo[];
  architectures?: SearchFilterGroupInfo[];
  sortOrder: SEARCH_SORT;
}

interface ISearchBarContextProps {
  state: ISearchState;
  // helper functions
  setPage: (page: number) => void;
  setSearchSort: (sort: SEARCH_SORT) => void;
  getSearchInfo: () => ISearchInfo;
  updateSearchTerm: (searchTerm: string, filterSearchParam?: string) => void;
  clearAllFilters: () => void;
  clearAllFiltersAndResetPage: () => void;
  resetState: () => void;
  connectedFilterGroups: ISearchFilterGroup[];
  clearNavSearch: () => void;
}

// TODO: note this can be udpated to /search before homepage launch, but we need to update tests and canary prior
const defaultSearchRoute = '/search';

export const SearchContext = createContext<Partial<ISearchBarContextProps>>({
  state: {
    searchTerm: '',
    page: 1,
    marketplaceFilter: false,
    linuxFilter: false,
    windowsFilter: false,
    armFilter: false,
    arm64Filter: false,
    x86Filter: false,
    x8664Filter: false,
    ppcFilter: false,
    zFilter: false,
    verifiedFilter: false,
    amazonFilter: false,
    dockerFilter: false,
    searchSortOrder: SEARCH_SORT.POPULARITY,
    clearNavSearch: 0,
  },
});

const getInitialStateFromUrl = (): IParamsFromUrl => {
  const urlSearchParams = new URLSearchParams(location.search);
  const verifiedFromUrl = urlSearchParams.get(FilterGroupIds.VERIFIED);
  const operatingSystemsFromUrl = urlSearchParams.get(FilterGroupIds.OS)?.split(',');
  const architecturesFromUrl = urlSearchParams.get(FilterGroupIds.ARCHITECTURE)?.split(',');
  const searchTermFromUrl = urlSearchParams.get('searchTerm');
  const pageFromUrl = urlSearchParams.get('page');
  const sortOrderFromUrl = urlSearchParams.get('sortOrder');
  const popularRegistriesFromUrl = urlSearchParams
    .get(FilterGroupIds.POPULAR_REGISTRIES)
    ?.split(',');

  let searchSortOrder = SEARCH_SORT.POPULARITY;

  // always default to popularity
  if (searchTermFromUrl?.length && sortOrderFromUrl === 'relevance') {
    searchSortOrder = SEARCH_SORT.RELEVANCE;
  }

  let page = 1;
  const parsedPageNumber = parseInt(pageFromUrl);
  if (parsedPageNumber && !Number.isNaN(parsedPageNumber)) {
    page = parsedPageNumber;
  }

  return {
    page,
    searchTerm: searchTermFromUrl?.length ? searchTermFromUrl : '',
    searchSortOrder,
    verifiedFilter: verifiedFromUrl === FILTER_VALUES.verified,
    amazonFilter: popularRegistriesFromUrl?.includes(FILTER_VALUES.amazon) ?? false,
    dockerFilter: popularRegistriesFromUrl?.includes(FILTER_VALUES.docker) ?? false,
    linuxFilter: operatingSystemsFromUrl?.includes(FILTER_VALUES.linux) ?? false,
    windowsFilter: operatingSystemsFromUrl?.includes(FILTER_VALUES.windows) ?? false,
    armFilter: architecturesFromUrl?.includes(FILTER_VALUES.arm) ?? false,
    arm64Filter: architecturesFromUrl?.includes(FILTER_VALUES.arm64) ?? false,
    x86Filter: architecturesFromUrl?.includes(FILTER_VALUES.x86) ?? false,
    x8664Filter: architecturesFromUrl?.includes(FILTER_VALUES.x8664) ?? false,
  };
};

export const SearchProvider: FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const previousPathname = usePrevious(location.pathname);

  const [state, dispatch] = stateInitializer(getInitialStateFromUrl());

  // sync from URL every time the page is navigated to
  useEffect(() => {
    if (
      location.pathname !== previousPathname &&
      (location.pathname === '/' || location.pathname === '/search') &&
      (previousPathname !== '/' || previousPathname !== '/search')
    ) {
      if (location.pathname === '/') {
        return;
      }
      dispatch({
        type: SEARCH_CONTEXT_ACTION.UPDATE_FROM_URL,
        payload: getInitialStateFromUrl(),
      });
    }
  }, [location]);

  const setPage = (page: number) =>
    dispatch({ type: SEARCH_CONTEXT_ACTION.SET_PAGE, payload: { page } });

  const updateFilter =
    (filterName: keyof IUpdateFiltersPayload, eventName?: EVENT_TYPES) => (value: boolean) => {
      dispatch({ type: SEARCH_CONTEXT_ACTION.UPDATE_FILTERS, payload: { [filterName]: value } });
      setPage(1);
      // Emitting custom CW RUM event
      if (eventName) {
        const eventData: FILTER_BY_ATTRIBUTE = {
          filteredBy: filterName,
        };
        awsRum.recordEvent(eventName, eventData);
      }
    };

  const setSearchSort = (sort: SEARCH_SORT) => {
    dispatch({ type: SEARCH_CONTEXT_ACTION.UPDATE_SEARCH_SORT, payload: { sort } });
    setPage(1);
  };

  const clearAllFilters = () => {
    dispatch({ type: SEARCH_CONTEXT_ACTION.CLEAR_ALL_FILTERS });
  };

  const clearAllFiltersAndResetPage = () => {
    dispatch({ type: SEARCH_CONTEXT_ACTION.CLEAR_ALL_FILTERS_AND_RESET_PAGE });
  };

  const resetState = () => {
    dispatch({ type: SEARCH_CONTEXT_ACTION.CLEAR_ALL_FILTERS_AND_RESET_PAGE });
    setSearchSort(SEARCH_SORT.POPULARITY);
    dispatch({ type: SEARCH_CONTEXT_ACTION.SET_SEARCH_TERM, payload: { searchTerm: '' } });
  };

  const clearNavSearch = () => {
    dispatch({ type: SEARCH_CONTEXT_ACTION.CLEAR_NAV_SEARCH_EVENT });
  };

  const updateSearchTerm = (searchTerm: string, filterSearchParam?: string) => {
    dispatch({
      type: SEARCH_CONTEXT_ACTION.SET_SEARCH_TERM_AND_PAGE,
      payload: {
        searchTerm,
        page: 1,
      },
    });

    const urlSearchParams = new URLSearchParams(location.search);
    const searchTermParam = urlSearchParams.get('searchTerm');
    if (searchTerm.length) {
      urlSearchParams.set('searchTerm', searchTerm);
    } else if (searchTermParam && !searchTerm.length) {
      urlSearchParams.delete('searchTerm');
    }
    if (filterSearchParam) {
      const splitFilter = filterSearchParam.split('=');
      if (!!splitFilter[1]) urlSearchParams.append(splitFilter[0], splitFilter[1]);
    }
    const routeConfig = {
      pathname: defaultSearchRoute,
      search: urlSearchParams.toString(),
    };

    const movingToNewRoute = !['/search'].includes(location.pathname);

    if (movingToNewRoute) {
      history.push(routeConfig);
    } else {
      history.replace(routeConfig);
    }

    // Emitting custom CW RUM event
    const eventData: SEARCH_WITH_TERM_ATTRIBUTES = {
      searchTerm: searchTerm,
    };
    awsRum.recordEvent(EVENT_TYPES.SEARCH_WITH_TERM, eventData);
  };

  const connectedFilterGroups = getFilterGroups({
    verifiedFilterOptions: {
      filterState: state.verifiedFilter,
      updateFilter: updateFilter('verifiedFilter', EVENT_TYPES.FILTERED_BY_VERIFIED_REGISTRY),
    },
    amazonFilterOptions: {
      filterState: state.amazonFilter,
      updateFilter: updateFilter('amazonFilter', EVENT_TYPES.FILTERED_BY_AMAZON_REGISTRY),
    },
    dockerFilterOptions: {
      filterState: state.dockerFilter,
      updateFilter: updateFilter('dockerFilter', EVENT_TYPES.FILTERED_BY_DOCKER_REGISTRY),
    },
    linuxFilterOptions: {
      filterState: state.linuxFilter,
      updateFilter: updateFilter('linuxFilter', EVENT_TYPES.FILTERED_BY_REPO_CONFIGURATION),
    },
    windowsFilterOptions: {
      filterState: state.windowsFilter,
      updateFilter: updateFilter('windowsFilter', EVENT_TYPES.FILTERED_BY_REPO_CONFIGURATION),
    },
    armFilterOptions: {
      filterState: state.armFilter,
      updateFilter: updateFilter('armFilter', EVENT_TYPES.FILTERED_BY_REPO_CONFIGURATION),
    },
    arm64FilterOptions: {
      filterState: state.arm64Filter,
      updateFilter: updateFilter('arm64Filter', EVENT_TYPES.FILTERED_BY_REPO_CONFIGURATION),
    },
    x86FilterOptions: {
      filterState: state.x86Filter,
      updateFilter: updateFilter('x86Filter', EVENT_TYPES.FILTERED_BY_REPO_CONFIGURATION),
    },
    x8664FilterOptions: {
      filterState: state.x8664Filter,
      updateFilter: updateFilter('x8664Filter', EVENT_TYPES.FILTERED_BY_REPO_CONFIGURATION),
    },
  });

  const getSearchInfoForGroup = (
    filters: ISearchFilterGroup[],
    id: FilterGroupIds
  ): SearchFilterGroupInfo[] => {
    const group = filters.find(f => f.type === id);

    return group && group.searchFilters
      ? group.searchFilters.map(f => ({
          state: f.filterState,
          value: f.value,
        }))
      : undefined;
  };

  const getSearchInfo = (): ISearchInfo => {
    const filters = connectedFilterGroups;
    return {
      searchTerm: state.searchTerm,
      verified: getSearchInfoForGroup(filters, FilterGroupIds.VERIFIED),
      popularRegistries: getSearchInfoForGroup(filters, FilterGroupIds.POPULAR_REGISTRIES),
      operatingSystems: getSearchInfoForGroup(filters, FilterGroupIds.OS),
      architectures: getSearchInfoForGroup(filters, FilterGroupIds.ARCHITECTURE),
      sortOrder: state.searchSortOrder,
    };
  };

  return (
    <SearchContext.Provider
      value={{
        state,
        getSearchInfo,
        setPage,
        setSearchSort,
        updateSearchTerm,
        clearAllFilters,
        clearAllFiltersAndResetPage,
        resetState,
        connectedFilterGroups,
        clearNavSearch,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContext;
