import React, { FC } from 'react';
import { RoundedCardCTAViewModel } from '../../Models/types';

import './RoundedCardCTA.scss';

interface RoundedCardCTAProps {
  vm: RoundedCardCTAViewModel;
}

const scssRoot = 'RoundedCardCTA';

export const RoundedCardCTA: FC<RoundedCardCTAProps> = props => {
  const { vm } = props;

  return (
    <div className={scssRoot}>
      <a
        data-testid={`RoundedCardCTA-${vm.header.split(' ').join('-')}`}
        className={scssRoot}
        rel="noreferrer"
        target="_blank"
        href={vm.link}
        onClick={() => vm.event()}
      >
        <div className={`${scssRoot}-container`}>
          <div className={`${scssRoot}-image`}>
            <img src={vm.imagePath} alt={`icon illustration for ${vm.header}`} />
          </div>
          <div className={`${scssRoot}-text-block`}>
            <div className={`${scssRoot}-text-block-header`}>{vm.header}</div>
            <div className={`${scssRoot}-text-block-copy`}>{vm.copy}</div>
          </div>
        </div>
      </a>
    </div>
  );
};
