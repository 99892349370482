import { BreadcrumbGroupProps } from '@amzn/awsui-components-react/polaris';
import { GalleryBreadcrumbId, GalleryBreadcrumbItem, GenerateBreadcrumbsProps } from './type';
import { ECR_PUBLIC_GALLERY } from '@spencer/util/constants';

export type BreadcrumbsVM = BreadcrumbGroupProps.Item[];

const extractRepoNameFromParam = (param: string) => {
  return `${param?.split('/')?.slice(1)?.join('/') ?? 'repository detail'}`;
};

const GALLERY_BREADCRUMBS: GalleryBreadcrumbItem[] = [
  {
    id: GalleryBreadcrumbId.HOME,
    label: () => ECR_PUBLIC_GALLERY,
    route: () => '/',
  },
  {
    id: GalleryBreadcrumbId.SEARCH,
    label: () => 'search',
    route: () => '/search',
    parentId: GalleryBreadcrumbId.HOME,
  },
  {
    id: GalleryBreadcrumbId.SEARCH_WITH_TERM,
    label: param => `search: ${param}`,
    route: param => `/${param}`,
    parentId: GalleryBreadcrumbId.SEARCH,
  },
  {
    id: GalleryBreadcrumbId.REGISTRY,
    label: param => `${param}`,
    route: param => `/${param}`,
    parentId: GalleryBreadcrumbId.SEARCH,
  },
  {
    id: GalleryBreadcrumbId.LISTING_DETAIL,
    label: param => extractRepoNameFromParam(param),
    route: param => `/${param}`,
    parentId: GalleryBreadcrumbId.REGISTRY,
  },
];

const getBreadcrumbConfigList = (list: GalleryBreadcrumbItem[], routeId: GalleryBreadcrumbId) => {
  const routeItem = GALLERY_BREADCRUMBS.find(crumb => crumb.id === routeId);

  list.unshift(routeItem);

  if (routeItem.parentId) {
    return getBreadcrumbConfigList(list, routeItem.parentId);
  }
  return list;
};

export const generateBreadcrumbsViewModel = (
  routeId: GalleryBreadcrumbId,
  props?: GenerateBreadcrumbsProps
): BreadcrumbsVM => {
  const breadcrumbConfigList = getBreadcrumbConfigList([], routeId);
  const breadcrumbs: BreadcrumbGroupProps.Item[] = breadcrumbConfigList.map(configItem => {
    return {
      text: configItem.label(props[configItem.id] ?? undefined),
      href: configItem.route(props[configItem.id] ?? undefined),
    };
  });
  return breadcrumbs;
};
