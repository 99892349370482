import React, { FC } from 'react';
import { TopNavigation } from '@spencer/components/common/TopNavigation/TopNavigation';
import AppFooter from '@spencer/components/common/AppFooter/AppFooter';

import './UnifiedAppLayout.scss';

const UnifiedAppLayout: FC = props => {
  return (
    <>
      <TopNavigation />
      <div className="AppLayout">{props.children}</div>
      <AppFooter />
    </>
  );
};

export default UnifiedAppLayout;
