import ecrPublicClient, { GetRegistryCatalogDataResponse } from '@spencer/apis/ecrPublicClient';
import { AWSError } from 'aws-sdk/lib/error';
import { useQuery } from 'react-query';
import { querySettings } from './common';

export const useRegistryCatalogQuery = (publisherId: string) =>
  useQuery<GetRegistryCatalogDataResponse, AWSError>(
    ['registryCatalogData', { publisherId }],
    ecrPublicClient.getRegistryCatalogData.bind(ecrPublicClient),
    {
      ...querySettings,
    }
  );

export const useRegistryCatalogQueryState = (publisherId: string) =>
  useQuery<GetRegistryCatalogDataResponse, AWSError>(['registryCatalogData', { publisherId }]);
