import React from 'react';
import { Link } from '@amzn/awsui-components-react/polaris';
import type { ISearchFilterGroup } from './SearchContext';

export enum FILTER_VALUES {
  'marketplace' = 'marketplace',
  'verified' = 'verified',
  'linux' = 'Linux',
  'windows' = 'Windows',
  'arm' = 'ARM',
  'arm64' = 'ARM 64',
  'x86' = 'x86',
  'x8664' = 'x86-64',
  'amazon' = 'amazon',
  'docker' = 'docker',
}

export enum FilterGroupIds {
  VERIFIED = 'verified',
  POPULAR_REGISTRIES = 'popularRegistries',
  OS = 'operatingSystems',
  ARCHITECTURE = 'architecture',
}

export interface IFilterOption {
  filterState: boolean;
  updateFilter: (value: boolean) => void;
}

export interface IGetFilterGroupsParams {
  verifiedFilterOptions?: IFilterOption;
  amazonFilterOptions?: IFilterOption;
  dockerFilterOptions?: IFilterOption;
  linuxFilterOptions: IFilterOption;
  windowsFilterOptions: IFilterOption;
  armFilterOptions: IFilterOption;
  arm64FilterOptions: IFilterOption;
  x86FilterOptions: IFilterOption;
  x8664FilterOptions: IFilterOption;
}

export const displayableTags = Object.values(FILTER_VALUES);

export const getFilteredTags = (tags: string[]): string[] =>
  tags
    .filter(t => displayableTags.includes(t as FILTER_VALUES))
    .map(tag => {
      return tag;
    });

export const getFilterGroups = ({
  verifiedFilterOptions,
  amazonFilterOptions,
  dockerFilterOptions,
  linuxFilterOptions,
  windowsFilterOptions,
  armFilterOptions,
  arm64FilterOptions,
  x86FilterOptions,
  x8664FilterOptions,
}: IGetFilterGroupsParams): ISearchFilterGroup[] => {
  const baseGroups: ISearchFilterGroup[] = [
    ...(amazonFilterOptions && dockerFilterOptions
      ? [
          {
            title: (
              <span data-testid="popular-registries-label" className="pr-s">
                {'Popular Registries'}
              </span>
            ),
            type: FilterGroupIds.POPULAR_REGISTRIES,
            searchFilters: [
              {
                filterState: amazonFilterOptions?.filterState,
                updateFilter: amazonFilterOptions?.updateFilter,
                label: 'Amazon',
                value: FILTER_VALUES.amazon,
                testid: 'amazon-filter-label',
              },
              {
                filterState: dockerFilterOptions?.filterState,
                updateFilter: dockerFilterOptions?.updateFilter,
                label: 'Docker',
                value: FILTER_VALUES.docker,
                testid: 'docker-filter-label',
              },
            ],
          },
        ]
      : []),
    ...(verifiedFilterOptions
      ? [
          {
            title: (
              <span>
                <span className="pr-s">{'Verification'}</span>
                <Link
                  external
                  externalIconAriaLabel="Opens in a new tab"
                  href="https://docs.aws.amazon.com/AmazonECR/latest/public/public-gallery.html"
                >
                  Info
                </Link>
              </span>
            ),
            type: FilterGroupIds.VERIFIED,
            searchFilters: [
              {
                filterState: verifiedFilterOptions?.filterState,
                updateFilter: verifiedFilterOptions?.updateFilter,
                label: 'Verified',
                // description: 'Artifacts and repositories that hae been vetted by AWS Marketplace',
                value: FILTER_VALUES.verified,
              },
            ],
          },
        ]
      : []),
    {
      title: 'Operating Systems',
      type: FilterGroupIds.OS,
      searchFilters: [
        {
          filterState: linuxFilterOptions.filterState,
          updateFilter: linuxFilterOptions.updateFilter,
          label: 'Linux',
          value: FILTER_VALUES.linux,
        },
        {
          filterState: windowsFilterOptions.filterState,
          updateFilter: windowsFilterOptions.updateFilter,
          label: 'Windows',
          value: FILTER_VALUES.windows,
        },
      ],
    },
    {
      title: 'Architectures',
      type: FilterGroupIds.ARCHITECTURE,
      searchFilters: [
        {
          filterState: armFilterOptions.filterState,
          updateFilter: armFilterOptions.updateFilter,
          label: 'ARM',
          value: FILTER_VALUES.arm,
        },
        {
          filterState: arm64FilterOptions.filterState,
          updateFilter: arm64FilterOptions.updateFilter,
          label: 'ARM 64',
          value: FILTER_VALUES.arm64,
        },
        {
          filterState: x86FilterOptions.filterState,
          updateFilter: x86FilterOptions.updateFilter,
          label: 'x86',
          value: FILTER_VALUES.x86,
        },
        {
          filterState: x8664FilterOptions.filterState,
          updateFilter: x8664FilterOptions.updateFilter,
          label: 'x86-64',
          value: FILTER_VALUES.x8664,
        },
      ],
    },
  ];

  return baseGroups;
};
