import { AWSError } from 'aws-sdk';
import { useInfiniteQuery } from 'react-query';
import { DescribeRepositoryCatalogDataResponse } from '@spencer/apis/ecrPublicClient';
import ecrPublicClient from '@spencer/apis/ecrPublicClient';
import { querySettings } from './common';
import { isRepositoryHidden } from '@spencer/util/hideRepo';

// Remove this filter wrapper once react-query version upgraded to support select and move transformation logic their.
const getFilteredReposInRegistry = async (
  key: string,
  props?: {
    registryAliasName: string;
  },
  nextToken?: string
) => {
  const data = await ecrPublicClient.getReposInRegistry(key, props, nextToken);

  data.repositories = data.repositories.filter(
    repository => !isRepositoryHidden(props.registryAliasName, repository.repositoryName)
  );

  return data;
};

export const useGetReposInRegistryQuery = (registryAliasName: string) =>
  useInfiniteQuery<DescribeRepositoryCatalogDataResponse, AWSError>(
    ['reposInRegistry', { registryAliasName }],
    getFilteredReposInRegistry,
    {
      ...querySettings,
      getFetchMore: (lastGroup, _allPages) => lastGroup.repositories.length && lastGroup.nextToken,
    }
  );
