import React, { FC } from 'react';

import { CallToAction, ClickLogosSection, InfoSection, RoundedCardCTA } from './Components';
import { TriangleFullWidthDivider } from '../../components/common/TriangleFullWidthDivider/TriangleFullWidthDivider';
import { Section } from '../../components/common/Section/Section';
import { useInnerWidth } from '@spencer/hooks';

import {
  REGISTRY_LOGOS_MODEL,
  BENEFITS_VM,
  USE_CASES_VM,
  AWS_CALL_TO_ACTION_VM,
  AWS_FREE_TRAINING_CTA_VM,
} from './Models/models';

import { COLORS_HOME_LIGHT_BLUE, COLORS_HOME_MEDIUM_BLUE, COLORS_WHITE } from './Models/constants';
import './HomePage.scss';

const HomePage: FC = () => {
  const windowWidth = useInnerWidth();

  return (
    <div className="HomePage">
      <Section backgroundColor={COLORS_WHITE}>
        <ClickLogosSection vm={REGISTRY_LOGOS_MODEL} defaultShowNum={windowWidth >= 912 ? 7 : 6} />
      </Section>
      <Section backgroundColor={COLORS_WHITE} secondaryColor={COLORS_HOME_LIGHT_BLUE}>
        <InfoSection vm={BENEFITS_VM} id="#home--features-and-benefits" />
        <InfoSection vm={USE_CASES_VM} id="#home--use-cases" />
      </Section>
      <TriangleFullWidthDivider
        baseColor={COLORS_HOME_LIGHT_BLUE}
        secondaryColor={COLORS_HOME_MEDIUM_BLUE}
        direction="DOWN"
      />
      <Section>
        <CallToAction vm={AWS_CALL_TO_ACTION_VM} />
        <RoundedCardCTA vm={AWS_FREE_TRAINING_CTA_VM} />
      </Section>
    </div>
  );
};

export default HomePage;
