import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useInfiniteQuery, QueryCache, ReactQueryCacheProvider } from 'react-query';

import { useMediaQuery } from 'beautiful-react-hooks';

import { AWSError } from 'aws-sdk/lib/error';
import {
  Box,
  Button,
  Grid,
  Icon,
  SelectProps,
  Spinner,
} from '@amzn/awsui-components-react/polaris';

import {
  stateInitializer,
  ACTION as SEARCH_ACTION,
  IStateInitializerProps,
} from './searchPageState';

import FilterBar from '@spencer/components/common/FilterBar';
import Paginator from '@spencer/components/common/Paginator';
import PaginationHeader from '@spencer/components/common/PaginationHeader';
import SearchResultCard from '@spencer/components/common/SearchResultCard';
import { SEARCH_SORT, SearchContext } from '@spencer/components/common/SearchContext';

import { waitFor } from '@spencer/util/asyncHelpers';
import { isRegistryHidden } from '@spencer/util/hideRegistry';

import ecrPublicClient, {
  SearchRepositoryCatalogDataResponse,
} from '@spencer/apis/ecrPublicClient';

import './SearchPage.scss';
import {
  Breadcrumbs,
  BreadcrumbsVM,
  GalleryBreadcrumbId,
  generateBreadcrumbsViewModel,
} from '@spencer/components/common/Breadcrumbs';

import { isRepositoryHidden } from '@spencer/util/hideRepo';
import { awsRum } from '@spencer/customerMonitoringTelemetry/cwrumconfig';
import {
  CHANGED_SORT_BY_SETTING_ATTRIBUTE,
  EVENT_TYPES,
} from '@spencer/customerMonitoringTelemetry/customEventTypes';

interface ISearchPageProps {
  searchText?: '';
}

const queryCache = new QueryCache({
  defaultConfig: { queries: { refetchOnWindowFocus: false } },
});

const bemPrefix = 'SearchPage';

const baseGridDefinition = [
  { colspan: { default: 0, xs: 3 } },
  { colspan: { default: 12, xs: 9 } },
];

const searchSortOptions: SelectProps.Option[] = [
  {
    value: SEARCH_SORT.POPULARITY,
    label: 'Sort by: Popularity',
  },
  {
    value: SEARCH_SORT.RELEVANCE,
    label: 'Sort by: Relevance',
  },
];

const SearchPage: FunctionComponent<ISearchPageProps> = () => {
  const {
    state: searchContextState,
    setPage,
    setSearchSort,
    getSearchInfo,
    connectedFilterGroups,
    clearAllFilters,
    clearAllFiltersAndResetPage,
  } = useContext(SearchContext);

  const { page, searchTerm, searchSortOrder } = searchContextState;

  const resultsPerPage = 20;
  const resultsPerAPIPage = 100;
  const maxPages = 100;
  const apiPages = Math.ceil((page * resultsPerPage) / resultsPerAPIPage);

  const initialState: IStateInitializerProps = {
    defaultSearchSortOption: searchSortOptions[0],
    ...(apiPages > 1
      ? {
          startingPage: {
            pageStart: page * resultsPerPage - 1,
            pageEnd: page * resultsPerPage,
          },
        }
      : {}),
  };

  const [state, dispatch] = stateInitializer(initialState);
  const [breadcrumbs, updateBreadcrumbs] = useState<BreadcrumbsVM>([]);

  const { isLoading, isError, isFetching, data, isFetchingMore, fetchMore, canFetchMore } =
    useInfiniteQuery<SearchRepositoryCatalogDataResponse, AWSError>(
      ['listingsInf', getSearchInfo() ?? {}],
      ecrPublicClient.getListings.bind(ecrPublicClient),
      {
        getFetchMore: (lastGroup, _allPages) =>
          lastGroup.repositoryCatalogSearchResultList.length && lastGroup.nextToken,
        enabled: true,
        retry: 0,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );

  useEffect(() => {
    let currAPIPage = 1;
    (async () => {
      // fetch once to get the first page and check the totalResults
      const response = await fetchMore();
      // only loop if the page * resultsPerPage is less than totalResults, rounded to the nearest whole page
      // otherwise, just reset the page to page 1. Since the max page length is 100, that means anything over
      // 100 will automatically reset to page 1, and only 1 API call will happen. If the page is set to 100, then
      // 20 requests will happen, since there are 100 results per request, and 100 pages * 20 results per page (2000 results)
      if (
        page * resultsPerPage <=
          Math.ceil(response?.[0].totalResults / resultsPerPage) * resultsPerPage &&
        page <= maxPages
      ) {
        dispatch({
          type: SEARCH_ACTION.SET_IS_LOADING_MULTIPLE_PAGES,
          payload: { isLoadingMultiplePages: true },
        });
        while (currAPIPage < apiPages) {
          await fetchMore();
          currAPIPage++;
          await waitFor(100);
        }
      } else {
        setPage(1);
      }
      dispatch({
        type: SEARCH_ACTION.SET_IS_LOADING_MULTIPLE_PAGES,
        payload: { isLoadingMultiplePages: false },
      });
      window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
    })();
  }, []);

  useEffect(() => {
    let pageStartInner = (page - 1) * resultsPerPage + 1;
    let pageEndInner = 0;

    if (data?.[0]?.totalResults === 0) {
      pageStartInner = 0;
      pageEndInner = 0;
    } else if (data?.[0]?.totalResults > page * resultsPerPage) {
      pageEndInner = page * resultsPerPage;
    } else {
      pageEndInner = data?.[0].totalResults;
    }
    dispatch({
      type: SEARCH_ACTION.UPDATE_PAGE_DATA,
      payload: {
        searchResults: data
          ?.reduce((accum, group) => {
            const filteredData = group.repositoryCatalogSearchResultList?.filter(sr => {
              if (isRegistryHidden(sr.primaryRegistryAliasName)) return false;
              if (isRepositoryHidden(sr.primaryRegistryAliasName, sr.repositoryName)) return false;

              return true;
            });

            return accum.concat(filteredData);
          }, [])
          ?.slice(pageStartInner - 1, pageEndInner),
        pageStartNumber: pageStartInner,
        pageEndNumber: pageEndInner,
      },
    });
  }, [data, page]);

  // sync the select state with the actual sort state
  useEffect(() => {
    dispatch({
      type: SEARCH_ACTION.SET_SEARCH_SORT,
      payload: { sortOption: searchSortOptions.find(o => o.value === searchSortOrder) },
    });
  }, [searchSortOrder]);

  useEffect(() => {
    const routeId =
      searchTerm?.length > 0 ? GalleryBreadcrumbId.SEARCH_WITH_TERM : GalleryBreadcrumbId.SEARCH;

    updateBreadcrumbs(
      generateBreadcrumbsViewModel(routeId, {
        [GalleryBreadcrumbId.SEARCH_WITH_TERM]: searchTerm ?? '',
      })
    );
  }, [searchTerm]);

  const updatePage = (page: number) => {
    if (
      page * resultsPerPage >
        data.length * (data[data.length - 1].repositoryCatalogSearchResultList.length / 2) &&
      canFetchMore
    ) {
      fetchMore();
    }
    setPage(page);
  };

  const noResults = state.searchResults?.length === 0;
  const shouldShowPaginator =
    !isLoading &&
    !isError &&
    !noResults &&
    state.pageStart &&
    state.pageEnd &&
    state.searchResults.length;

  // the pixel value should match the xs breakpoint in common.scss
  // IE does not support CSS variables so this was the best way I thought of implementing
  const isMobileState = useMediaQuery('(min-width: 688px)');
  useEffect(() => {
    dispatch({
      type: SEARCH_ACTION.SET_FILTER_BAR_VISIBILITY,
      payload: { shouldShow: state.showFilterBar && !isMobileState },
    });
  }, [isMobileState]);

  const totalPages = Math.ceil(Math.min(data?.[0].totalResults / resultsPerPage, maxPages)) ?? 0;

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <Helmet>
        <link rel="canonical" href={`${location.href}`} />
      </Helmet>
      <Grid disableGutters className={`${bemPrefix}__BaseGrid`} gridDefinition={baseGridDefinition}>
        <FilterBar
          showMobileFilterBar={state.showFilterBar}
          setPage={updatePage}
          page={page}
          clearAllFilters={clearAllFilters}
          clearAllFiltersAndResetPage={clearAllFiltersAndResetPage}
          filterGroups={connectedFilterGroups}
        />
        <div className={`${bemPrefix}__BaseGrid__SearchResults`}>
          <Button
            className={`${bemPrefix}__BaseGrid__SearchResults__FilterButton`}
            onClick={() => {
              dispatch({
                type: SEARCH_ACTION.SET_FILTER_BAR_VISIBILITY,
                payload: { shouldShow: !state.showFilterBar },
              });
            }}
          >
            <Icon
              className={`${bemPrefix}__BaseGrid__SearchResults__FilterButton__icon`}
              name={state.showFilterBar ? 'treeview-collapse' : 'treeview-expand'}
            />
            {state.showFilterBar ? 'Hide filters' : 'Show filters'}
          </Button>
          {isLoading ||
          isFetching ||
          (isFetchingMore && !state.searchResults.length) ||
          state.isLoadingMultiplePages ? (
            <Spinner />
          ) : isError ? (
            <div>An error occurred. Please try refreshing.</div>
          ) : noResults ? (
            <div>No matching results. Modify your filters and try again.</div>
          ) : (
            <>
              <Breadcrumbs vm={breadcrumbs} />
              <PaginationHeader
                title={<Box variant="h4">Repositories</Box>}
                sortSelectedOption={state.selectedSearchSortOption}
                sortOptions={searchSortOptions}
                onSortChange={({ detail }) => {
                  dispatch({
                    type: SEARCH_ACTION.SET_SEARCH_SORT,
                    payload: {
                      sortOption: detail.selectedOption,
                    },
                  });
                  setSearchSort(detail.selectedOption.value as SEARCH_SORT);

                  // Emitting custom CW RUM event
                  const eventData: CHANGED_SORT_BY_SETTING_ATTRIBUTE = {
                    searchTerm: searchTerm,
                    sorting_method: detail.selectedOption.value,
                  };
                  awsRum.recordEvent(EVENT_TYPES.CHANGED_SORT_BY_SETTING, eventData);
                }}
                currentPage={page}
                setPage={updatePage}
                totalPages={totalPages}
                pageStart={state?.pageStart}
                pageEnd={state?.pageEnd}
                totalItems={data?.[0].totalResults}
                isLoading={isLoading}
                // disable the select if there's no search term, since "Relevance" doesn't make much sense
                sortDisabled={isLoading || searchTerm === ''}
              />
              <div className={`${bemPrefix}__BaseGrid__SearchResults__Container`}>
                {state.searchResults?.map((listing, index) => {
                  return (
                    <SearchResultCard
                      key={index}
                      repositoryTitle={listing.repositoryName}
                      repositoryImageSrc={listing.logoUrl}
                      repositoryAuthor={listing.displayName}
                      repositoryDescription={listing.repositoryDescription}
                      repositoryTags={[
                        ...(listing?.operatingSystems ? listing?.operatingSystems : []),
                        ...(listing?.architectures ? listing?.architectures : []),
                      ]}
                      publisherId={listing.primaryRegistryAliasName}
                      verifiedAuthor={listing.registryVerified}
                      repositoryDownloads={listing.downloadCount}
                    />
                  );
                })}
              </div>
              {shouldShowPaginator && (
                <Box padding={{ top: 'm' }}>
                  <Paginator
                    currentPage={page}
                    setPage={updatePage}
                    totalPages={
                      Math.ceil(Math.min(data?.[0].totalResults / resultsPerPage, 100)) ?? 0
                    }
                  />
                </Box>
              )}
            </>
          )}
        </div>
      </Grid>
    </ReactQueryCacheProvider>
  );
};

export default SearchPage;
