// Customer registry alias's that requested for their repositories to be hidden
export const hiddenRegistryMap = {
  'aws-cloud9': null, // customer request
  k1a7z4q8: null, // jetbrains default alias, customer request
  jetbrains: null, // customer request
  s4d8e9h8: null, // aws-mde default alias
  'aws-mde': null, // aws-mde custom alias
  rahulte: null, // rahulte custom alias
  alvajus: null, // alvajus custom alias
  y6w3f9n5: null, // alvajus default aliass
  h7a6a3g2: null, // AWS Neuron team, https://t.corp.amazon.com/P114851089
};

export const isRegistryHidden = (alias: string) => {
  const aliasFormatted = alias.toLocaleLowerCase();

  return hiddenRegistryMap[aliasFormatted] !== undefined;
};
