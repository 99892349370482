import { useEffect, useState } from 'react';

export const useInnerWidth = () => {
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => setInnerWidth(window.innerWidth));

    return window.removeEventListener('resize', () => setInnerWidth(window.innerWidth));
  }, []);

  return innerWidth;
};
