import React, { FC } from 'react';

import './Section.scss';

interface SectionProps {
  backgroundColor?: string;
  secondaryColor?: string;
}

const linearGradientString = (topColor: string, bottomColor: string): string => {
  return `linear-gradient(${topColor},${bottomColor})`;
};

export const Section: FC<SectionProps> = props => {
  const backgroundColor = props.backgroundColor ?? '#ffffff';
  const backgroundString = props.secondaryColor
    ? linearGradientString(backgroundColor, props.secondaryColor)
    : backgroundColor;
  return (
    <section style={{ background: backgroundString, width: '100%' }}>
      <div className="Section__Container">{props.children}</div>
    </section>
  );
};
