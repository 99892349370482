import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import {
  RUM_BETA_CONFIG,
  RUM_GAMMA_CONFIG,
  RUM_PROD_CONFIG,
} from '@spencer/customerMonitoringTelemetry/cwrumutils';
import {
  EVENT_TYPES,
  HOME_EVENTS,
  HOME_CLICKED_ATTRIBUTES,
} from '@spencer/customerMonitoringTelemetry/customEventTypes';

let cwRumClient: AwsRum;
export default async function initializeRum() {
  try {
    let config = RUM_BETA_CONFIG;
    if (window.location.origin === RUM_GAMMA_CONFIG.location) {
      config = RUM_GAMMA_CONFIG;
    } else if (window.location.origin === RUM_PROD_CONFIG.location) {
      config = RUM_PROD_CONFIG;
    }

    const applicationConfig: AwsRumConfig = {
      sessionSampleRate: 1,
      signing: false,
      endpoint: config.apiEndpoint,
      telemetries: [
        'performance',
        'errors',
        [
          'http',
          {
            recordAllRequests: true,
          },
        ],
      ],
      allowCookies: true,
      enableXRay: true,
      sessionEventLimit: 0,
    };

    cwRumClient = new AwsRum(
      config.applicationId,
      config.applicationVersion,
      config.applicationRegion,
      applicationConfig
    );
  } catch (error) {
    console.log(`Unable to initialize CloudWatch RUM with error: `, error);
  }
}

export const awsRum = {
  recordEvent(eventType: EVENT_TYPES, eventData: Record<string, unknown>): void {
    cwRumClient?.recordEvent(eventType, eventData);
  },
};

export const recordHomeEvent = (
  eventType: HOME_EVENTS,
  eventData?: HOME_CLICKED_ATTRIBUTES
): void => {
  cwRumClient?.recordEvent(eventType, eventData ?? {});
};
