import { ECR_PUBLIC_GALLERY } from '@spencer/util/constants';

export const getReportIssueLinkUrl = (): string => {
  const userGuideLink = '<https://docs.aws.amazon.com/AmazonECR/latest/userguide/what-is-ecr.html>';
  const emailSubject = encodeURIComponent(`${ECR_PUBLIC_GALLERY} Site Issue`);
  const emailBodyIntro = encodeURIComponent(
    `Thank you for taking the time to report an issue with the ${ECR_PUBLIC_GALLERY}.`
  );
  const emailBodyText = `Please include as much information as possible to describe the issue (for example: inappropriate content, harmful or misrepresented repository content, or security concerns). If you need help solving a specific problem, please visit the User Guide ${userGuideLink}.`;
  const emailText = `${emailBodyIntro}\n\n${emailBodyText}`;

  return `mailto:ecr-public-report-issues@amazon.com?subject=${emailSubject}&body=${emailText}`;
};

export const getCopywriteMessage = (): string => {
  const year = new Date()?.getFullYear();
  return `© ${year} Amazon Web Services, Inc. or its affiliates. All rights reserved.`;
};
