/**
 * Takes a number of downloads as input and returns a rounded string of either 2 or 3 digits.
 *
 * Examples:
 * Less than 1000 -> exact number (3 digits)
 * 4 digigs (1000, 9999): truncate first 2 digits, 2 significant figures, 9950 -> 9.9K+
 * 5 digigs (10000, 99999): truncate first 2 digits, 2 significant figures, 98500 -> 98K+
 * 6 digigs (100000, 999999): truncate first 3 digits, 3 significant figures, 890990 -> 890K+
 * 7 digigs (1000000, 9999999): truncate first 2 digits, 2 significant figures, 1900999 -> 1.9M+
 * 8 digigs (10000000, 99999999): truncate first 2 digits, 2 significant figures, 12900509 -> 12M+
 * 9 digigs (100000000, 999999999): truncate first 3 digits, 3 significant figures, 250500900 -> 250M+
 *
 * @param downloads number of downloads
 */
export const formatDownloads = (downloads: number) => {
  enum SUFFIX {
    'K' = 1,
    'M',
    'B',
    'T',
  }

  if (downloads < 1000) {
    return `${downloads}`;
  } else if (downloads > 999999999999999) {
    // set max size to 999T
    return `999T+`;
  }

  const zeros = Math.trunc(Math.log10(downloads));
  const digits = zeros + 1;
  let sigFigs = 2;
  if (digits % 3 === 0) {
    sigFigs = 3;
  }

  let outString = `${downloads}`.substring(0, sigFigs);
  const suffixLookup = Math.trunc(zeros / 3);

  if (zeros % 3 === 0) {
    // add a decimal point between the first 2 (only) digits
    const [firstDigit, secondDigit] = [...outString];
    outString = `${firstDigit}.${secondDigit}`;
  }

  return `${outString}${SUFFIX[suffixLookup]}+`;
};
