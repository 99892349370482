import React, { FunctionComponent, useEffect, useState } from 'react';

import { Box, Spinner } from '@amzn/awsui-components-react/polaris';

import {
  Breadcrumbs,
  BreadcrumbsVM,
  GalleryBreadcrumbId,
  generateBreadcrumbsViewModel,
} from '@spencer/components/common/Breadcrumbs';

import VerifiedAccountIndicator from '@spencer/components/common/VerifiedAccountIndicator';

import './RegistryHeader.scss';
import { AWSError } from 'aws-sdk';

const bemPrefix = 'RegistryHeader';

interface IRegistryHeaderProps {
  isLoading: boolean;
  registryOwner?: string;
  publisherId?: string;
  verifiedAccount?: boolean;
  error?: AWSError;
}

const RegistryHeader: FunctionComponent<IRegistryHeaderProps> = ({
  isLoading,
  registryOwner,
  publisherId,
  verifiedAccount,
  error,
}) => {
  const [breadcrumbs, updateBreadcrumbs] = useState<BreadcrumbsVM>([]);

  useEffect(() => {
    const routeId = GalleryBreadcrumbId.REGISTRY;

    updateBreadcrumbs(
      generateBreadcrumbsViewModel(routeId, {
        [GalleryBreadcrumbId.REGISTRY]: publisherId ?? 'registry',
      })
    );
  }, [publisherId]);

  const renderBreadcrumbs = (isLoading: boolean) => {
    if (isLoading) return;

    return <Breadcrumbs vm={breadcrumbs} />;
  };

  return (
    <div>
      {renderBreadcrumbs(isLoading)}
      <Box className={`${bemPrefix}__container`} margin={{ bottom: 'xl' }}>
        <div className={`${bemPrefix}__container__repoInfo`}>
          {isLoading ? (
            <Spinner />
          ) : error ? null : (
            <>
              <Box
                variant="span"
                fontWeight="normal"
                className={`${bemPrefix}__container__repoInfo__publisherName wb-bw`}
              >
                <Box
                  data-testid="registryDisplayName"
                  variant="span"
                  fontSize="heading-xl"
                  padding={{ right: 'xs' }}
                  textAlign="left"
                >
                  {registryOwner?.trim().length ? registryOwner : 'untitled registry'}
                </Box>
                <Box variant="span">{verifiedAccount ? <VerifiedAccountIndicator /> : null}</Box>
              </Box>
              <Box variant="div">
                <Box variant="span" fontWeight="normal" padding={{ right: 'xxs' }}>
                  Registry alias:
                </Box>
                <Box variant="span" fontWeight="normal" data-testid="registryAlias">
                  {publisherId}
                </Box>
              </Box>
            </>
          )}
        </div>
      </Box>
    </div>
  );
};

export default RegistryHeader;
