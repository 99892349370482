import React from 'react';

import { ImageTagDetail } from '@spencer/apis/ecrPublicClient';
import {
  getDerivedMediaType,
  DERIVED_TYPES,
} from '@spencer/components/common/ImageMediaTypeText/utils';
import prettyBytes from 'pretty-bytes';

import { Box } from '@amzn/awsui-components-react/polaris';
import { Popover } from '@amzn/awsui-components-react/polaris';

const SizeCell = (item: ImageTagDetail) => {
  const derivedType = getDerivedMediaType({
    imageManifestMediaType: item?.imageDetail?.imageManifestMediaType,
    artifactMediaType: item?.imageDetail?.artifactMediaType,
  });
  const formattedSize = prettyBytes(item.imageDetail.imageSizeInBytes)?.toUpperCase();

  if (formattedSize) {
    if (derivedType === DERIVED_TYPES.MANIFEST_LIST) {
      return (
        <Popover
          size="small"
          position="bottom"
          content={
            <Box color="text-label">
              This image tag may contain multiple file sizes. Only the maximum image size is shown
              here.
            </Box>
          }
        >
          <Box variant="code">{`${formattedSize} (max.)`}</Box>{' '}
        </Popover>
      );
    }
    return <Box variant="code">{formattedSize}</Box>;
  }

  return '-';
};

export default SizeCell;
