import React, { ComponentType } from 'react';
import { ReactQueryCacheProvider, QueryCache } from 'react-query';

/**
 *
 * @param WrappedComponent Component to be wrapped with ReactQueryCacheProvider.
 * @param queryCache Storage mechanism for all the data, meta information and state of the queries it contains.
 * @returns WrappedComponent with ReactQueryCacheProvider and context to the query cache.
 *
 * @example withQueryCacheProvider(SomeComponent, someQueryCacheForComponentTree)
 */

const withQueryCacheProvider =
  <P extends unknown>(WrappedComponent: ComponentType<P>, queryCache: QueryCache) =>
  ({ ...props }) =>
    (
      <ReactQueryCacheProvider queryCache={queryCache}>
        <WrappedComponent {...(props as any)} />
      </ReactQueryCacheProvider>
    );

export default withQueryCacheProvider;
