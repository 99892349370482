import React, { FC } from 'react';

import { Box } from '@amzn/awsui-components-react/polaris';
import VerifiedAccountIndicator from '@spencer/components/common/VerifiedAccountIndicator';
import { LinkInternal } from '@spencer/components/common/LinkInternal/LinkInternal';

interface ISearchCardAuthorProps {
  author: string;
  publisherId: string;
  verified: boolean;
  isPublisherPage?: boolean;
}

const SearchCardAuthor: FC<ISearchCardAuthorProps> = ({
  author,
  publisherId,
  verified,
  isPublisherPage,
}) => {
  if (!isPublisherPage) {
    return (
      <Box variant="div" padding={{ top: 'xxxs', bottom: 'xxs' }}>
        <Box variant="span" padding={{ right: 'xs' }} data-testid="displayName">
          <Box variant="span" color="text-label">
            by&nbsp;
          </Box>
          <LinkInternal
            displayText={author?.trim()?.length ? author : `untitled registry`}
            route={`/${publisherId}/`}
            state={{ from: 'search' }}
            fontSize="body-m"
          />
        </Box>
        {verified ? <VerifiedAccountIndicator /> : null}
      </Box>
    );
  }
  return null;
};

export default SearchCardAuthor;
