import ecrPublicClient, { GetRepositoryCatalogDataResponse } from '@spencer/apis/ecrPublicClient';
import { AWSError } from 'aws-sdk/lib/error';
import { useQuery } from 'react-query';
import { querySettings } from './common';

export const useListingQuery = (publisherId: string, repoName: string) =>
  useQuery<GetRepositoryCatalogDataResponse, AWSError>(
    ['listing', { publisherId, repoName: repoName }],
    ecrPublicClient.getListing.bind(ecrPublicClient),
    {
      ...querySettings,
    }
  );

export const useListingQueryState = (publisherId: string, repoName: string) =>
  useQuery<GetRepositoryCatalogDataResponse, AWSError>([
    'listing',
    { publisherId, repoName: repoName },
  ]);
