import React, { FC } from 'react';

import {
  Box,
  Select,
  SelectProps,
  NonCancelableCustomEvent,
  Spinner,
} from '@amzn/awsui-components-react/polaris';

import Paginator from './../Paginator';

import './PaginationHeader.scss';

export interface PaginationHeaderProps {
  title: React.ReactNode;
  sortSelectedOption: SelectProps.Option;
  sortOptions: SelectProps.Options;
  sortDisabled: boolean;
  onSortChange: (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => void;
  currentPage: number;
  setPage: (page: number) => void;
  totalPages: number;
  pageStart: number;
  pageEnd: number;
  totalItems: number;
  isLoading: boolean;
  isLoadingMoreItems?: boolean;
}

const bemPrefix = 'PaginationHeader';

const PaginationHeader: FC<PaginationHeaderProps> = ({
  title,
  sortSelectedOption,
  sortOptions,
  sortDisabled,
  onSortChange,
  currentPage,
  setPage,
  totalPages,
  pageStart,
  pageEnd,
  totalItems,
  isLoading,
  isLoadingMoreItems,
}) => {
  return (
    <Box className={`${bemPrefix}`}>
      <div className="clearfix">
        <Box float="left">
          {title}
          {pageStart && pageEnd && !isLoading ? (
            <>
              <Box>{`Showing ${pageStart} - ${pageEnd} results (of ${totalItems})`}</Box>
              {isLoadingMoreItems ? <Spinner size="normal" /> : null}
            </>
          ) : null}
        </Box>
        <Box className={`${bemPrefix}__Controls`}>
          <Select
            data-testid="paginationHeaderSortSelect"
            className={`${bemPrefix}__Controls__Select`}
            selectedOption={sortSelectedOption}
            onChange={onSortChange}
            options={sortOptions}
            disabled={sortDisabled}
            selectedAriaLabel="Selected"
          />
          <Paginator currentPage={currentPage} setPage={setPage} totalPages={totalPages} />
        </Box>
      </div>
    </Box>
  );
};

export default PaginationHeader;
