import {
  Configuration,
  DefaultApi,
  DescribeImageTagsOperationRequest,
  DescribeRepositoryCatalogDataOperationRequest,
  DescribeRepositoryCatalogDataResponse,
  GetRegistryCatalogDataOperationRequest,
  GetRegistryCatalogDataResponse,
  GetRepositoryCatalogDataOperationRequest,
  GetRepositoryCatalogDataResponse,
  ImageTagDetail,
  SearchRepositoryCatalogDataOperationRequest,
  SearchRepositoryCatalogDataResponse,
  RepositoryCatalogSearchResult,
  RepositoryCatalogDataItem,
  ResponseError,
} from '@amzn/spencer-portal-open-api-sdk';
import { SEARCH_SORT } from '@spencer/components/common/SearchContext';
import { FilterGroupIds } from '@spencer/components/common/filters';
import { waitFor } from '@spencer/util/asyncHelpers';

// Implementation thoughts
// Cleanup weird parameters from previous implementation?
// Should we decouple application settings from the client and pass them in?
// Replace bind with arrow function to prevent losing context to operation in sendRequest?
// Should we wrap exceptions thrown by the APIG client to an error type that can be consumed for better error messaging?
// Monitoring?

interface RequestInit {
  /** A BodyInit object or null to set request's body. */
  body?: BodyInit | null;
  /** A string indicating how the request will interact with the browser's cache to set request's cache. */
  cache?: RequestCache;
  /** A string indicating whether credentials will be sent with the request always, never, or only when sent to a same-origin URL. Sets request's credentials. */
  credentials?: RequestCredentials;
  /** A Headers object, an object literal, or an array of two-item arrays to set request's headers. */
  headers?: HeadersInit;
  /** A cryptographic hash of the resource to be fetched by request. Sets request's integrity. */
  integrity?: string;
  /** A boolean to set request's keepalive. */
  keepalive?: boolean;
  /** A string to set request's method. */
  method?: string;
  /** A string to indicate whether the request will use CORS, or will be restricted to same-origin URLs. Sets request's mode. */
  mode?: RequestMode;
  /** A string indicating whether request follows redirects, results in an error upon encountering a redirect, or returns the redirect (in an opaque fashion). Sets request's redirect. */
  redirect?: RequestRedirect;
  /** A string whose value is a same-origin URL, "about:client", or the empty string, to set request's referrer. */
  referrer?: string;
  /** A referrer policy to set request's referrerPolicy. */
  referrerPolicy?: ReferrerPolicy;
  /** An AbortSignal to set request's signal. */
  signal?: AbortSignal | null;
  /** Can only be null. Used to disassociate request from any Window. */
  window?: null;
}

type HTTPRequestMethod =
  | 'GET'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'HEAD'
  | 'CONNECT'
  | 'OPTIONS'
  | 'TRACE'
  | 'PATCH';

interface SettingsResponse {
  spencerPortalProxyApiBaseUrl: string;
  region: string;
  // TODO: remove non longer used members
  spencerListingApiDomainName?: string;
  userPoolId?: string;
  identityPoolId?: string;
}

export interface SearchFilter {
  state: boolean;
  value: string;
}

export class ECRPublicClient {
  private _ecrPublicApi: DefaultApi;
  private _clientInitialized = false;
  private _clientInitializedCallbacks: ((value: DefaultApi | PromiseLike<DefaultApi>) => void)[] =
    [];
  private defaultRequestInit: RequestInit = {};
  private maxRetryAttempts = 3;

  constructor() {
    this.configureClient();
  }

  public async getRegistryCatalogData(
    key: string,
    { publisherId }: { publisherId: string }
  ): Promise<GetRegistryCatalogDataResponse> {
    const client = await this.ecrPublicApi;
    const request: GetRegistryCatalogDataOperationRequest = {
      getRegistryCatalogDataRequest: {
        registryAliasName: publisherId,
      },
    };

    return await this.sendRequest(
      'POST',
      'getRegistryCatalogData',
      client.getRegistryCatalogData.bind(client),
      request
    );
  }

  public async getListing(
    key: string,
    { publisherId, repoName }: { publisherId: string; repoName: string }
  ): Promise<GetRepositoryCatalogDataResponse> {
    const client = await this.ecrPublicApi;
    const request: GetRepositoryCatalogDataOperationRequest = {
      getRepositoryCatalogDataRequest: {
        registryAliasName: publisherId,
        repositoryName: repoName,
      },
    };

    return await this.sendRequest(
      'POST',
      'getRepositoryCatalogData',
      client.getRepositoryCatalogData.bind(client),
      request
    );
  }

  public async getTags(key: string, { publisherId, repoName }): Promise<ImageTagDetail[]> {
    const client = await this.ecrPublicApi;

    let firstLoop = true;
    let nextToken;
    const payload = {
      registryAliasName: publisherId,
      repositoryName: repoName,
    };
    let response: ImageTagDetail[] = [];
    while (firstLoop || nextToken) {
      firstLoop = false;
      const request: DescribeImageTagsOperationRequest = {
        describeImageTagsRequest: {
          ...payload,
          nextToken,
        },
      };

      const tagsResponse = await this.sendRequest(
        'POST',
        'describeImageTags',
        client.describeImageTags.bind(client),
        request
      );

      nextToken = tagsResponse.nextToken;
      response = response.concat(tagsResponse.imageTagDetails as ImageTagDetail[]);
    }

    return response;
  }

  public async getListings(
    key: string,
    props?: {
      simulateError?: boolean;
      searchTerm: string;
      verified: SearchFilter[];
      architectures: SearchFilter[];
      operatingSystems: SearchFilter[];
      popularRegistries: SearchFilter[];
      sortOrder: SEARCH_SORT;
    },
    nextToken?: string
  ): Promise<SearchRepositoryCatalogDataResponse> {
    const {
      searchTerm,
      verified = [],
      architectures = [],
      operatingSystems = [],
      popularRegistries = [],
      sortOrder = SEARCH_SORT.POPULARITY,
    } = props;
    const client = await this.ecrPublicApi;
    const filterSetOptions = (arr: SearchFilter[]) => arr.filter(a => a.state);

    const verifiedParse = verified.find(f => f.value === FilterGroupIds.VERIFIED)?.state ?? false;
    const setArchs = filterSetOptions(architectures);
    const setOses = filterSetOptions(operatingSystems);
    const setRegistryFilters = filterSetOptions(popularRegistries);
    const request: SearchRepositoryCatalogDataOperationRequest = {
      searchRepositoryCatalogDataRequest: {
        ...(verifiedParse ? { registryVerified: true } : {}),
        ...(searchTerm?.length ? { searchTerm } : {}),
        ...(setArchs?.length
          ? {
              architectures: setArchs.map(a => a.value),
            }
          : {}),
        ...(setOses?.length
          ? {
              operatingSystems: setOses.map(a => a.value),
            }
          : {}),
        ...(setRegistryFilters?.length
          ? {
              registryFilter: {
                registryFilterNames: setRegistryFilters.map(a => a.value),
              },
            }
          : {}),
        ...(nextToken ? { nextToken } : {}),
        sortConfiguration: {
          sortKey: sortOrder,
        },
      },
    };

    return await this.sendRequest(
      'POST',
      'searchRepositoryCatalogData',
      client.searchRepositoryCatalogData.bind(client),
      request
    );
  }

  public async getReposInRegistry(
    key: string,
    props?: {
      registryAliasName: string;
    },
    nextToken?: string
  ): Promise<DescribeRepositoryCatalogDataResponse> {
    const client = await this.ecrPublicApi;
    const { registryAliasName } = props;
    const request: DescribeRepositoryCatalogDataOperationRequest = {
      describeRepositoryCatalogDataRequest: {
        registryAliasName,
        ...(nextToken ? { nextToken } : {}),
        maxResults: 100,
      },
    };

    return await this.sendRequest(
      'POST',
      'describeRepositoryCatalogData',
      client.describeRepositoryCatalogData.bind(client),
      request
    );
  }

  private async sendRequest<RequestType, ResponseType>(
    method: HTTPRequestMethod,
    operationName: string,
    operation: (request: RequestType, requestInit?: RequestInit) => Promise<ResponseType>,
    request: RequestType,
    initOverrides?: RequestInit
  ): Promise<ResponseType> {
    const mergedRequestInit = {
      ...this.defaultRequestInit,
      ...initOverrides,
      method: method,
    };

    let requestCount = this.maxRetryAttempts;
    while (requestCount > 0) {
      try {
        return await operation(request, mergedRequestInit);
      } catch (e) {
        let retryableError = true;
        requestCount -= 1;

        if (e instanceof ResponseError) {
          const response = await e.response.json();
          retryableError = response.retryable;
        }

        if (requestCount < 0 || !retryableError) throw e;
      }

      await waitFor(100);
    }
  }

  private get ecrPublicApi(): DefaultApi | Promise<DefaultApi> {
    if (this._clientInitialized) {
      return this._ecrPublicApi;
    } else {
      const initalizationCallback = new Promise<DefaultApi>(res => {
        this._clientInitializedCallbacks.push(res);
      });

      return (async function () {
        return await initalizationCallback;
      })();
    }
  }

  private async configureClient(): Promise<void> {
    try {
      let settingsResponse: SettingsResponse;
      if (process?.env?.NODE_ENV === 'dev') {
        settingsResponse = {
          spencerPortalProxyApiBaseUrl: process?.env?.SPENCER_PORTAL_PROXY_API_BASE_URL,
          region: process?.env?.REGION,
        } as SettingsResponse;
      } else {
        const settings = await fetch(`${window.origin}/settings.v2.json`, {
          method: 'GET',
        });

        settingsResponse = (await settings.json()) as SettingsResponse;
      }
      const configuration = new Configuration({
        basePath: settingsResponse.spencerPortalProxyApiBaseUrl,
      });
      this._ecrPublicApi = new DefaultApi(configuration);
      this._clientInitialized = true;
      this._clientInitializedCallbacks.forEach(callback => callback(this._ecrPublicApi));
    } catch (err) {
      throw new Error(`Unable to fetch application settings due to error:\n${err}`);
    }
  }
}

export default new ECRPublicClient();
export type {
  DescribeImageTagsOperationRequest,
  DescribeRepositoryCatalogDataOperationRequest,
  DescribeRepositoryCatalogDataResponse,
  GetRegistryCatalogDataOperationRequest,
  GetRegistryCatalogDataResponse,
  GetRepositoryCatalogDataOperationRequest,
  GetRepositoryCatalogDataResponse,
  ImageTagDetail,
  SearchRepositoryCatalogDataOperationRequest,
  SearchRepositoryCatalogDataResponse,
  RepositoryCatalogSearchResult,
  RepositoryCatalogDataItem,
};
