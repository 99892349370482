import React, { FC } from 'react';

import { handleLaunchWithAppRunnerButtonClick } from './utils/launchWithAppRunnerHandlers';

import { Button } from '@amzn/awsui-components-react';

interface ILaunchWithAppRunnerButton {
  publisherId: string;
  repoName: string;
  imageTag: string;
  isLoading: boolean;
  disabled: boolean;
}

const LaunchWithAppRunnerButton: FC<ILaunchWithAppRunnerButton> = ({
  publisherId,
  repoName,
  imageTag,
  isLoading,
  disabled,
}) => {
  return (
    <Button
      loading={isLoading}
      disabled={disabled}
      variant="primary"
      onClick={e => handleLaunchWithAppRunnerButtonClick(e)(publisherId, repoName, imageTag)}
      data-testid="launchWithAppRunnerButton"
      data-publisherid={publisherId}
      data-repository-name={repoName}
      data-image-uri={imageTag}
    >
      Launch with App Runner
    </Button>
  );
};

export default LaunchWithAppRunnerButton;
