import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { InfoSectionItemViewModel } from '../../Models/types';

interface InfoSectionModelProps {
  vm: InfoSectionItemViewModel;
}

const scssRoot = 'InfoSectionItem';

export const InfoSectionItem: FC<InfoSectionModelProps> = props => {
  const { vm } = props;
  return (
    <div className={scssRoot}>
      <div className={`${scssRoot}-header`}>{vm.header}</div>
      <div className={`${scssRoot}-copy`}>
        {`${vm.copy} `}
        {vm.linkText && vm.linkRoute ? (
          <Link className={`${scssRoot}-copy -link`} to={vm.linkRoute}>
            {vm.linkText}
          </Link>
        ) : null}
      </div>
    </div>
  );
};
