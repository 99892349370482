import React, { FC } from 'react';

import { AWSError } from 'aws-sdk/lib/error';

import Markdown from 'markdown-to-jsx';

interface IUsageTab {
  listingError?: AWSError;
  usageText?: string;
}

const UsageTab: FC<IUsageTab> = ({ listingError, usageText }) => {
  return listingError?.code === 'RepositoryNotFoundException' ? (
    <span>listingError.message</span>
  ) : (
    <Markdown options={{ disableParsingRawHTML: true }}>
      {usageText?.length ? usageText : "This repository doesn't have any Usage information set."}
    </Markdown>
  );
};

export default UsageTab;
