import React, { FC } from 'react';

import { Box, Popover, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import { getDerivedMediaType } from '@spencer/components/common/ImageMediaTypeText/utils/artifactTypes';

interface IImageMediaTypeText {
  imageManifestMediaType: string;
  artifactMediaType: string;
}

const ImageMediaTypeText: FC<IImageMediaTypeText> = ({
  imageManifestMediaType,
  artifactMediaType,
}) => {
  const derviedMediaType = getDerivedMediaType({
    imageManifestMediaType: imageManifestMediaType,
    artifactMediaType: artifactMediaType,
  });

  return (
    <Popover
      size="large"
      position="top"
      content={
        <SpaceBetween direction="vertical" size="s">
          <div>
            <Box color="text-label">Image manifest media type</Box>
            <Box>{imageManifestMediaType ?? '-'}</Box>
          </div>
          <div>
            <Box color="text-label">Artifact media type</Box>
            <Box>{artifactMediaType ?? '-'}</Box>
          </div>
        </SpaceBetween>
      }
    >
      {derviedMediaType}
    </Popover>
  );
};

export default ImageMediaTypeText;
