import React, { FC } from 'react';

import { Box, Popover, StatusIndicator } from '@amzn/awsui-components-react/polaris';

const VerifiedAccountPopoverContentBox: FC = () => {
  return (
    <Box variant="p" className="wb-bw">
      {`This publisher has been verified by Amazon. `}
    </Box>
  );
};

export const VerifiedAccountIndicator: FC = () => (
  <StatusIndicator>
    <Popover
      dismissButton={true}
      position="top"
      size="medium"
      triggerType="text"
      dismissAriaLabel="Close verified account popover"
      content={<VerifiedAccountPopoverContentBox />}
    >
      Verified Account
    </Popover>
  </StatusIndicator>
);
