import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris';

interface ITablePreference {
  value: number;
  label: string;
}

export const PAGE_SIZE_OPTIONS: ITablePreference[] = [
  { value: 20, label: '20 tags' },
  { value: 50, label: '50 tags' },
  { value: 100, label: '100 tags' },
];

export const DEFAULT_PAGE_SIZE: CollectionPreferencesProps.Preferences = { pageSize: 50 };

export const paginationLabels = {
  nextPageLabel: 'Next page',
  previousPageLabel: 'Previous page',
  pageLabel: pageNumber => `Page ${pageNumber} of all pages`,
};
