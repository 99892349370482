import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';
import { QueryCache } from 'react-query';
import { withQueryCacheProvider } from '../common/HOC';

import { useMediaQuery } from 'beautiful-react-hooks';

import {
  Box,
  Button,
  Grid,
  Icon,
  SelectProps,
  Spinner,
} from '@amzn/awsui-components-react/polaris';

import SearchResultCard from '@spencer/components/common/SearchResultCard';
import Paginator from '@spencer/components/common/Paginator';
import PaginationHeader from '@spencer/components/common/PaginationHeader';
import FilterBar from '@spencer/components/common/FilterBar';
import { isRegistryHidden } from '@spencer/util/hideRegistry';
import { usePrevious } from '@spencer/util/hooks';
import { useGetReposInRegistryQuery } from './hooks';

import RegistryHeader from './RegistryHeader';

import './RegistryPage.scss';

import {
  stateInitializer,
  getConnectedFilterGroups,
  ACTION,
  SORT_OPTIONS,
  IStateInitializerProps,
} from './registryPageState';
import { FEATURES, isFeatureEnabled } from '@spencer/util/featureControl';

import { useRegistryCatalogQuery } from '@spencer/components/common/hooks';
import ErrorPage from '../common/ErrorPage';

const bemPrefix = 'RegistryPage';

interface IRegistryRouteParams {
  registryAliasName: string;
}

const RegistryPageQueryCache = new QueryCache();

const baseGridDefinition = [
  { colspan: { default: 0, xs: 3 } },
  { colspan: { default: 12, xs: 9 } },
];

const sortOptions: SelectProps.Option[] = [
  {
    value: SORT_OPTIONS.POPULARITY,
    label: 'Sort by: Popularity',
  },
  {
    value: SORT_OPTIONS.ALPHA,
    label: 'Sort by: Alphabetical order',
  },
];

const RegistryPage: FC = () => {
  const location = useLocation();
  const previousPathname = usePrevious(location.pathname);
  const { registryAliasName } = useParams<IRegistryRouteParams>();
  const {
    isLoading,
    data,
    isError: isErrorLoadingRepositories,
    error: getReposInRegistryError,
    canFetchMore,
    fetchMore,
  } = useGetReposInRegistryQuery(registryAliasName);
  const { data: registryCatalogDataResponse, error: getRegistryCataglogError } =
    useRegistryCatalogQuery(registryAliasName);

  const RESULTS_PER_PAGE = 20;

  const initialState: IStateInitializerProps = {
    defaultSortOption: sortOptions[0],
    resultsPerPage: RESULTS_PER_PAGE,
  };

  const [state, dispatch] = stateInitializer(initialState);

  useEffect(() => {
    dispatch({
      type: ACTION.SET_IS_LOADING_MULTIPLE_PAGES,
      payload: {
        isLoadingMultiplePages: true,
      },
    });
    window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
  }, []);

  useEffect(() => {
    dispatch({
      type: ACTION.UPDATE_FROM_URL,
    });
  }, [location.pathname]);

  // sync from URL every navigation event
  useEffect(() => {
    if (location.pathname !== previousPathname) {
      dispatch({
        type: ACTION.UPDATE_FROM_URL,
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch({
      type: ACTION.UPDATE_PAGE_DATA,
      payload: {
        data,
      },
    });
    if (canFetchMore) {
      fetchMore();
    } else if (!isLoading) {
      dispatch({
        type: ACTION.SET_IS_LOADING_MULTIPLE_PAGES,
        payload: {
          isLoadingMultiplePages: false,
        },
      });
    }
  }, [data]);

  const isMobileState = useMediaQuery('(min-width: 688px)');
  useEffect(() => {
    dispatch({
      type: ACTION.SET_FILTER_BAR_VISIBILITY,
      payload: { shouldShow: state.showFilterBar && !isMobileState },
    });
  }, [isMobileState]);

  const filterGroups = getConnectedFilterGroups(state, dispatch);
  const setPage = (page: number) => dispatch({ type: ACTION.SET_PAGE, payload: { page } });

  const noResults = state?.repositories?.length === 0;

  const getErrorMessage = () => {
    if (
      getReposInRegistryError?.code === 'RegistryNotFoundException' ||
      isRegistryHidden(registryAliasName)
    ) {
      return `Registry with alias "${registryAliasName}" not found.`;
    } else {
      return 'An error occurred. Please try refreshing.';
    }
  };

  const isRegisterErrorPageEnabled = isFeatureEnabled(FEATURES.publisherErrorPage);

  // TODO: separate view, cleanup rendering, recheck error page feature
  // this lacks readibility
  if (getRegistryCataglogError !== null && isRegisterErrorPageEnabled) {
    return <ErrorPage error={getRegistryCataglogError} />;
  } else {
    return (
      <div className={bemPrefix}>
        <Helmet>
          {registryCatalogDataResponse?.registryCatalogData.displayName ? (
            <>
              {registryCatalogDataResponse?.verified ? (
                <title>{registryCatalogDataResponse?.registryCatalogData.displayName}</title>
              ) : null}
              <meta property="og:url" content={`https://gallery.ecr.aws/${registryAliasName}/`} />
              <meta
                property="og:title"
                content={registryCatalogDataResponse?.registryCatalogData.displayName}
              />
              <link rel="canonical" href={`https://gallery.ecr.aws/${registryAliasName}/`} />
            </>
          ) : null}
        </Helmet>
        <Grid
          disableGutters
          className={`${bemPrefix}__BaseGrid`}
          gridDefinition={baseGridDefinition}
        >
          <FilterBar
            showMobileFilterBar={state.showFilterBar}
            setPage={setPage}
            page={state.page}
            mobileClassOverride={`${bemPrefix}__BaseGrid__MobileFilterBar`}
            clearAllFilters={() => {
              dispatch({
                type: ACTION.CLEAR_ALL_FILTERS,
              });
            }}
            clearAllFiltersAndResetPage={() => {
              dispatch({
                type: ACTION.CLEAR_ALL_FILTERS_AND_RESET_PAGE,
              });
            }}
            filterGroups={filterGroups}
            disabled={
              state.isLoadingMultiplePages ||
              isErrorLoadingRepositories ||
              isRegistryHidden(registryAliasName)
            }
          />
          <div className={`${bemPrefix}__BaseGrid__Registry`}>
            {isRegistryHidden(registryAliasName) ? (
              <RegistryHeader
                isLoading={isLoading}
                publisherId={registryAliasName}
                error={getRegistryCataglogError}
              />
            ) : (
              <RegistryHeader
                isLoading={isLoading}
                registryOwner={registryCatalogDataResponse?.registryCatalogData.displayName}
                publisherId={registryAliasName}
                verifiedAccount={registryCatalogDataResponse?.verified ?? false}
                error={getRegistryCataglogError}
              />
            )}
            <div className={`${bemPrefix}__BaseGrid__Registry__Repositories`}>
              <Button
                className={`${bemPrefix}__BaseGrid__Registry__Repositories__FilterButton`}
                onClick={() => {
                  dispatch({
                    type: ACTION.SET_FILTER_BAR_VISIBILITY,
                    payload: { shouldShow: !state.showFilterBar },
                  });
                }}
              >
                <Icon
                  className={`${bemPrefix}__BaseGrid__Registry__Repositories__FilterButton__icon`}
                  name={state.showFilterBar ? 'treeview-collapse' : 'treeview-expand'}
                />
                {state.showFilterBar ? 'Hide filters' : 'Show filters'}
              </Button>

              <div className={`${bemPrefix}__BaseGrid__Registry__Repositories__List`}>
                <PaginationHeader
                  title={<Box variant="h4">Repositories</Box>}
                  sortSelectedOption={state.selectedSortOption}
                  sortOptions={sortOptions}
                  onSortChange={({ detail }) => {
                    dispatch({
                      type: ACTION.SET_SORT,
                      payload: {
                        sortOption: detail.selectedOption,
                      },
                    });
                  }}
                  currentPage={state.page}
                  setPage={setPage}
                  totalPages={isRegistryHidden(registryAliasName) ? 0 : state.totalPages}
                  pageStart={isRegistryHidden(registryAliasName) ? 0 : state?.pageStart}
                  pageEnd={isRegistryHidden(registryAliasName) ? 0 : state?.pageEnd}
                  totalItems={
                    isRegistryHidden(registryAliasName)
                      ? 0
                      : state?.filteredRepositories?.length ?? 0
                  }
                  isLoading={isLoading}
                  isLoadingMoreItems={state?.isLoadingMultiplePages}
                  sortDisabled={
                    isLoading ||
                    !state?.filteredRepositories?.length ||
                    state?.isLoadingMultiplePages ||
                    isRegistryHidden(registryAliasName)
                  }
                />
                <div
                  className={`${bemPrefix}__BaseGrid__Registry__Repositories__List__Container${
                    isLoading ? '--loading' : ''
                  }`}
                >
                  {isLoading ? (
                    <Spinner />
                  ) : isErrorLoadingRepositories || isRegistryHidden(registryAliasName) ? (
                    <div
                      className={`${bemPrefix}__BaseGrid__Registry__Repositories__List__Container__Error`}
                    >
                      {getErrorMessage()}
                    </div>
                  ) : noResults && !state?.isLoadingMultiplePages ? (
                    <div
                      className={`${bemPrefix}__BaseGrid__Registry__Repositories__List__Container__Error`}
                    >
                      No matching results. Modify your filters and try again.
                    </div>
                  ) : (
                    <>
                      {state.chunkedRepositories?.[state.page - 1]?.map((repository, index) => {
                        return (
                          <SearchResultCard
                            key={index}
                            repositoryTitle={repository.repositoryName}
                            repositoryImageSrc={repository.catalogData.logoUrl}
                            repositoryAuthor={
                              registryCatalogDataResponse?.registryCatalogData?.displayName
                            }
                            repositoryDescription={repository.catalogData.description}
                            repositoryTags={[
                              ...(repository.catalogData?.operatingSystems
                                ? repository.catalogData?.operatingSystems
                                : []),
                              ...(repository.catalogData?.architectures
                                ? repository.catalogData?.architectures
                                : []),
                            ]}
                            publisherId={registryAliasName}
                            verifiedAuthor={registryCatalogDataResponse?.verified}
                            repositoryDownloads={repository.insightData.downloadCount}
                            isPublisherPage={true}
                          />
                        );
                      })}
                    </>
                  )}
                </div>
                <Paginator
                  currentPage={state.page}
                  setPage={setPage}
                  totalPages={isRegistryHidden(registryAliasName) ? 0 : state.totalPages}
                />
              </div>
            </div>
          </div>
        </Grid>
      </div>
    );
  }
};

export default withQueryCacheProvider(RegistryPage, RegistryPageQueryCache);
