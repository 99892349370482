import React, { FC } from 'react';

import { Box } from '@amzn/awsui-components-react/polaris';
import { getFilteredTags } from '@spencer/components/common/filters';

interface ISearchCardTagsProps {
  tags: string[];
}

const SearchCardTags: FC<ISearchCardTagsProps> = ({ tags }) => {
  if (!tags && tags.length > 0) return null;

  const filteredTags = getFilteredTags(tags);

  if (filteredTags.length <= 0) {
    return null;
  } else {
    return (
      <Box padding={{ top: 'xxs', bottom: 'xxs' }}>
        OS/Arch:&nbsp;
        {filteredTags.join(', ')}
      </Box>
    );
  }
};

export default SearchCardTags;
