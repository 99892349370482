import React, { FC } from 'react';
import errorPageIllustrationSVG from '@spencer/assets/svgs/error_page_illustration.svg';
import { Box, Link } from '@amzn/awsui-components-react/polaris';
import './ErrorPage.scss';
import { HistoryState } from '@spencer/App';
import { AWSError } from 'aws-sdk';
import {
  BaseNavigationDetail,
  CancelableEventHandler,
} from '@amzn/awsui-components-react/polaris/internal/events';
import { useHistory } from 'react-router-dom';
import ErrorPageContentHandler from '@spencer/components/common/ErrorPage/ErrorPageContentHandler';
import { ECR_PUBLIC_GALLERY } from '@spencer/util/constants';

export interface IErrorPageProps {
  error: AWSError;
}

const ErrorPage: FC<IErrorPageProps> = ({ error }) => {
  const {
    title,
    errorDesc,
    hasDestLink,
    redirectBack,
    destLinkLabel,
    destLink,
    optionalTip,
    destLinkDesc,
  } = ErrorPageContentHandler(error);
  const history = useHistory<HistoryState>();
  const handleLinkFollow: CancelableEventHandler<BaseNavigationDetail> = event => {
    event.preventDefault();
    history.push('/');
  };

  const bemPrefix = 'errorPage';

  return (
    <div className={`${bemPrefix}`}>
      <div className={`${bemPrefix}__content`}>
        <img aria-label="Error Page Illustration" src={errorPageIllustrationSVG} />
        <Box variant="h1">{title}</Box>
        <div className={`${bemPrefix}__content__header`}>
          <Box variant="p" margin={{ bottom: 'xs' }}>
            {errorDesc}
          </Box>
        </div>

        {hasDestLink && (
          <div className={`${bemPrefix}__content__linkContainer`}>
            {destLinkDesc !== undefined && (
              <Box variant="p" margin={{ bottom: 'xxs' }}>
                {destLinkDesc}
                {redirectBack ? (
                  <Link
                    ariaLabel={`${ECR_PUBLIC_GALLERY} Link`}
                    variant="secondary"
                    external={false}
                    onFollow={handleLinkFollow}
                  >
                    {ECR_PUBLIC_GALLERY}
                  </Link>
                ) : (
                  <Link
                    ariaLabel={`${destLinkLabel} Link`}
                    target="_blank"
                    variant="secondary"
                    href={destLink}
                  >
                    {destLinkLabel}
                  </Link>
                )}
                .
              </Box>
            )}
          </div>
        )}

        {optionalTip !== undefined && (
          <div className={`${bemPrefix}__content__tip`}>
            <Box variant="p" margin={{ bottom: 'xxs' }}>
              <strong>Tip:</strong>&nbsp;
              {optionalTip}
            </Box>
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorPage;
