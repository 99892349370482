import React, { FC } from 'react';

import './TriangleFullWidthDivider.scss';

interface TriangleFullWidthDividerProps {
  direction: 'UP' | 'DOWN';
  height?: string;
  baseColor?: string;
  secondaryColor?: string;
  secondarySize?: string;
}

const scssRoot = 'TriangleFullWidthDivider';

export const TriangleFullWidthDivider: FC<TriangleFullWidthDividerProps> = props => {
  const height = props.height ?? '100px';
  const baseColor = props.baseColor ?? '#eee';
  const secondaryColor = props.secondaryColor ?? '#ccc';
  const secondarySize = props.secondarySize ?? '18';

  return (
    <div className={`${scssRoot}${props.direction === 'DOWN' ? '-down' : ''}`}>
      <div className={`${scssRoot}-separator`}>
        <svg
          width="50%"
          height={height}
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          fill={baseColor}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M 100 100 V 0 L 0 100" />
          <path d={`M 0 100 L 100 ${secondarySize} V 0 Z`} fill={secondaryColor} strokeWidth="0" />
        </svg>
        <svg
          width="50%"
          height={height}
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          fill={baseColor}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M 0 0 V 100 L 100 100" />
          <path d={`M 0 0 V ${secondarySize} L 100 100 Z`} fill={secondaryColor} strokeWidth="0" />
        </svg>
      </div>
    </div>
  );
};
