export const truncatePullCommand = (pullCommand: string) => {
  const MAX_PULL_COMMAND_LENGTH = 48;

  // parse the pull command
  const splitDomainName = pullCommand.split('.aws/');
  const domainName = `${splitDomainName[0]}.aws`;

  const splitTag = splitDomainName[1].split(':');
  const tag = splitTag[splitTag.length - 1];

  const [repositoryAlias, ...rest] = splitTag[0].split('/');
  const splitRepositoryName = rest.join('/');

  // check if there is a namespace
  const repositoryNameAndNamespace = splitRepositoryName.split('/');
  let repositoryName = repositoryNameAndNamespace[0];
  let namespace = '';
  if (repositoryNameAndNamespace.length > 1) {
    namespace = repositoryNameAndNamespace
      .slice(0, repositoryNameAndNamespace.length - 1)
      .join('/');
    repositoryName = repositoryNameAndNamespace[repositoryNameAndNamespace.length - 1];
  }

  const charactersOver = MAX_PULL_COMMAND_LENGTH - pullCommand.length;
  if (charactersOver >= 0) {
    return pullCommand;
  }

  const maxNamespaceLength = charactersOver + namespace.length;
  const maxRepositoryNameLength = maxNamespaceLength + repositoryName.length;
  const maxRepositoryAliasLength = maxRepositoryNameLength + repositoryAlias.length;
  const maxTagLength = maxRepositoryAliasLength + tag.length;

  if (maxNamespaceLength > 0 && maxNamespaceLength < namespace.length) {
    // truncate namespace from the left
    return `${domainName}/${repositoryAlias}/...${[...namespace]
      .slice(namespace.length - maxNamespaceLength, namespace.length)
      .join('')}/${repositoryName}:${tag}`;
  } else if (maxRepositoryNameLength > 0 && maxRepositoryNameLength < repositoryName.length) {
    // truncate repositoryName from the left
    return `${domainName}/${repositoryAlias}/...${[...repositoryName]
      .slice(repositoryName.length - maxRepositoryNameLength, repositoryName.length)
      .join('')}:${tag}`;
  } else if (maxRepositoryAliasLength > 0 && maxRepositoryAliasLength < repositoryAlias.length) {
    // truncate repositoryAlias from the right
    return `${domainName}/${[...repositoryAlias]
      .slice(0, Math.min(repositoryAlias.length, maxRepositoryAliasLength))
      .join('')}...:${tag}`;
  } else if (maxTagLength > 0 && maxTagLength < tag.length) {
    // truncate image tag from the left
    return `${domainName}/...${[...tag].slice(tag.length - maxTagLength, tag.length).join('')}`;
  } else {
    // fallback to the old logic
    return `${domainName}/...:${tag}`;
  }
};
