import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Input } from '@amzn/awsui-components-react';

import { SearchContext } from '@spencer/components/common/SearchContext';

import { TopNavigationFilter, TopNavigationViewModel } from './Models/types';
import { SCROLL_TOP_OFFSET_DEFAULT, SCROLL_LARGE_SEARCH_LIMIT } from './Models/constants';
import { TOP_NAV_VM } from './Models/models';
import { Header } from './Components/Header';
import { FilterRadioGroup } from './Components/FilterRadioGroup';
import { attemptScrollToId } from '@spencer/util/document';
import { useScrollY } from '@spencer/hooks';
import { ECR_PUBLIC_GALLERY } from '@spencer/util/constants';

import './TopNavigation.scss';
const scssRoot = 'TopNavigation';

export const TopNavigation: FC = () => {
  const vm: TopNavigationViewModel = TOP_NAV_VM;
  const location = useLocation();
  const isHomePage = ['/', '/home'].includes(location?.pathname);

  const searchContext = useContext(SearchContext);
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement>(null);

  const [searchTerm, updateSearchTerm] = useState('');
  const [selectedFilter, updateSelectedFilter] = useState(vm.search.filters[0]);

  useEffect(() => {
    updateSearchTerm(searchContext.state.searchTerm);
  }, [searchContext.state.searchTerm]);

  useEffect(() => {
    if (searchContext.state?.clearNavSearch > 0) clearSearch();
  }, [searchContext.state?.clearNavSearch]);

  const scrollY = useScrollY();
  const { resetState } = searchContext;

  const clearSearch = () => {
    updateSearchTerm('');
    resetState();
  };

  const handleInputKeyDown = key => {
    if (key === 'Enter') {
      const filter = isHomePage && !!selectedFilter.value ? selectedFilter.value : undefined;
      searchContext.updateSearchTerm(searchTerm, filter);
    }
  };

  const navigateHome = () => {
    history.push('/');
  };

  const handleLogoClick = () => {
    clearSearch();
    if (!isHomePage) {
      navigateHome();
    }
  };

  return (
    <>
      <div
        className={`${scssRoot}-logo ${!isHomePage ? '-clickable' : ''}`}
        onClick={() => {
          handleLogoClick();
        }}
      >
        <img src={vm.logoPath} alt="Amazon Web Services logo" />
      </div>
      {isHomePage ? (
        <div className={`${scssRoot}-header`}>
          <div className={`${scssRoot}-container`}>
            <Header variant="h1" content={vm.header} />
            <FilterRadioGroup
              filters={vm?.search?.filters ?? []}
              selectedFilter={selectedFilter}
              updateFilter={(filter: TopNavigationFilter) => updateSelectedFilter(filter)}
            />
          </div>
        </div>
      ) : null}
      <div className={`${scssRoot}-search`}>
        <div className={`${scssRoot}-container -search-variant`}>
          <div
            className={`${scssRoot}-search-input-container${
              !isHomePage || scrollY > SCROLL_LARGE_SEARCH_LIMIT ? ' -small' : ''
            }`}
          >
            <div className={`${scssRoot}-search-logo`} onClick={() => inputRef?.current?.focus()}>
              <img src={vm.search.iconPath} alt="Search" />
            </div>
            {/* <input
              ref={inputRef}
              data-testid="ecr-public-gallery-nav-search"
              className={`${scssRoot}-search-input`}
              onChange={e => updateSearchTerm(e?.target?.value)}
              value={searchTerm}
              aria-label={`Search ${ECR_PUBLIC_GALLERY}`}
              placeholder={vm.search.placeholderText}
              onKeyDown={event => handleInputKeyDown(event)}
            /> */}
            <Input
              aria-label={`Search ${ECR_PUBLIC_GALLERY}`}
              className={`${scssRoot}-search-input`}
              data-testid="ecr-public-gallery-nav-search"
              onChange={({ detail }) => {
                updateSearchTerm(detail.value);
              }}
              onKeyDown={({ detail }) => {
                handleInputKeyDown(detail.key);
              }}
              placeholder={vm.search.placeholderText}
              ref={inputRef}
              type="text"
              value={searchTerm}
            />
          </div>
        </div>
      </div>
      {isHomePage ? (
        <div className={`${scssRoot}-end`}>
          <div className={`${scssRoot}-container`}>
            <div className={`${scssRoot}-end-scroll`}>
              {vm?.scroll?.items?.map((item, index) => (
                <div
                  key={item.scrollId}
                  className={`${scssRoot}-end-scroll-item ${index === 0 ? '' : '--left-border'}`}
                  onClick={() => attemptScrollToId(item.scrollId, SCROLL_TOP_OFFSET_DEFAULT)}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
