import React, { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ClickLogosSectionItemViewModel } from '../../Models/types';

import { SearchContext } from '@spencer/components/common/SearchContext';

import { EVENT_TYPES } from '@spencer/customerMonitoringTelemetry/customEventTypes';
import { recordHomeEvent } from '@spencer/customerMonitoringTelemetry/cwrumconfig';

import './ClickLogosSection.scss';

interface ClickLogosInfoSectionProps {
  vm: ClickLogosSectionItemViewModel;
}

const scssRoot = 'ClickLogosSectionItem';

export const ClickLogosSectionItem: FC<ClickLogosInfoSectionProps> = props => {
  const { vm } = props;
  const searchContext = useContext(SearchContext);

  const handleRegistryClick = (clickId: string) => {
    searchContext.clearNavSearch();
    recordHomeEvent(EVENT_TYPES.HOME_CLICKED_POPULAR_REGISTRY, { registry: clickId });
  };

  return (
    <Link
      className={scssRoot}
      data-testid={`ClickLogosSectionItem-${vm.clickId.split('/').join('')}`}
      href={`${window?.location?.origin}/${vm.clickId}`}
      to={vm.clickId}
      onClick={() => handleRegistryClick(vm.clickId)}
    >
      <div className={`${scssRoot}-logo-container`}>
        <img src={vm.logoPath} alt={`logo for ${vm.header}`} />
      </div>
      <div className={`${scssRoot}-header`}>{vm.header}</div>
    </Link>
  );
};
