import React, { FC } from 'react';

import { useImageDetailListQueryState, useListingQueryState } from '../hooks';
import { Tabs } from '@amzn/awsui-components-react/polaris';
import AboutTab from './AboutTab';
import UsageTab from './UsageTab';
import ListingDetailTagsTable from './ListingDetailTagsTable';
import './ListingDetailTabs.scss';

interface IListingDetailTabs {
  publisherId: string;
  repoName: string;
}

const ListingDetailTabs: FC<IListingDetailTabs> = ({ publisherId, repoName }) => {
  const {
    isLoading: isLoadingImageTagDetailList,
    data: imageTagDetailList,
    error: imageTagDetailListError,
  } = useImageDetailListQueryState(publisherId, repoName);
  const { data: listingData, error: listingError } = useListingQueryState(publisherId, repoName);

  return (
    <span className="ListingDetailTabs">
      <Tabs
        tabs={[
          {
            label: 'About',
            id: 'about',
            content: (
              <AboutTab
                publisherId={publisherId}
                repoName={repoName}
                aboutText={listingData?.catalogData?.aboutText}
                listingError={listingError}
              />
            ),
          },
          {
            label: 'Usage',
            id: 'usage',
            content: (
              <UsageTab
                usageText={listingData?.catalogData?.usageText}
                listingError={listingError}
              />
            ),
          },
          {
            label: 'Image tags',
            id: 'tags',
            content: (
              <ListingDetailTagsTable
                isLoading={isLoadingImageTagDetailList}
                imageTagDetailList={imageTagDetailList}
                publisherId={publisherId}
                repoName={repoName}
                error={imageTagDetailListError}
              />
            ),
          },
        ]}
        data-testid="listingDetailTabs"
      />
    </span>
  );
};

export default ListingDetailTabs;
