import React, { useEffect, FC } from 'react';
import { Link } from '@amzn/awsui-components-react/polaris';
import { AWSCShortbread } from '@amzn/shortbread';

declare global {
  interface Window {
    shortbread: {
      customizeCookies: () => void;
      checkForCookieConsent: () => void;
    };
  }
}

import { footerVM, type AppFooterVM } from './Model/appfooter';

const bemPrefix = 'AppFooter';
import './AppFooter.scss';

const AppFooter: FC = () => {
  const vm: AppFooterVM = footerVM;

  useEffect(() => {
    window.shortbread = AWSCShortbread({
      parent: document.body,
      domain: `.${window.location.hostname}`,
    });
    window.shortbread.checkForCookieConsent();
  }, []);

  return (
    <div className={`${bemPrefix}`}>
      <div className={`${bemPrefix}__links`}>
        {vm.links.map(link => (
          <Link
            key={link.text}
            external={!!link.url}
            href={link.url ?? undefined}
            onFollow={link.onFollow ?? undefined}
          >
            {link.text}
          </Link>
        ))}
      </div>
      <div className={`${bemPrefix}__copyright`}>{vm.copywriteText}</div>
    </div>
  );
};

export default AppFooter;
