import { ulid } from 'ulid';

export interface RepositoryPort {
  repositoryName: string;
  port: number;
}
export interface RepositoryPortList {
  repositories: RepositoryPort[];
}

const APPRUNNER_LAUCH_CONFIG = new Map<string, RepositoryPort[]>([
  ['aws-containers', [{ repositoryName: 'hello-app-runner', port: 8000 }]],
  ['nginx', [{ repositoryName: 'nginx', port: 80 }]],
  ['tableau', [{ repositoryName: 'tabpy', port: 9004 }]],
  ['electroroute', [{ repositoryName: 'uvicorn-gunicorn-fastapi', port: 80 }]],
  ['fauna-labs', [{ repositoryName: 'fauna-app-runner-blog', port: 80 }]],
  [
    'lts',
    [
      { repositoryName: 'apache2', port: 80 },
      { repositoryName: 'nginx', port: 80 },
    ],
  ],
  [
    'ubuntu',
    [
      { repositoryName: 'apache2', port: 80 },
      { repositoryName: 'nginx', port: 80 },
    ],
  ],
  ['alvajus-test-alias', [{ repositoryName: 'alvajus-test', port: 8000 }]],
  ['s2g7m0a4', [{ repositoryName: 'ecrpubliccanary', port: 8000 }]],
  ['a3y6b4g0', [{ repositoryName: 'ecrpubliccanary', port: 8000 }]],
  ['a8x4u8c9', [{ repositoryName: 'ecrpubliccanary', port: 8000 }]],
]);

export interface RepositorySpecifier {
  registryAlias: string;
  repositoryName: string;
}

export const enableLaunchWithAppRunner = ({
  registryAlias,
  repositoryName,
}: RepositorySpecifier) => {
  const allowedRepos = APPRUNNER_LAUCH_CONFIG.get(registryAlias);
  if (allowedRepos?.find(r => r?.repositoryName === repositoryName)) {
    return true;
  }
  return false;
};

export const getRepoPort = ({ registryAlias, repositoryName }: RepositorySpecifier) => {
  const allowedRepos = APPRUNNER_LAUCH_CONFIG.get(registryAlias);
  const repo = allowedRepos?.find(r => r?.repositoryName === repositoryName);
  return repo?.port ?? 80;
};

export enum COMPUTE_SERVICES {
  APPRUNNER = 'APPRUNNER',
}

export interface GenerateServiceNameProps {
  repositoryName: string;
  computeService: COMPUTE_SERVICES;
}

export const generateServiceName = ({
  repositoryName,
  computeService,
}: GenerateServiceNameProps) => {
  if (computeService === COMPUTE_SERVICES.APPRUNNER) {
    // ULIDs are 26 characters long, App Runner service names must 40 characters or less
    const truncatedRepoName = repositoryName.substring(0, 13);
    return `${truncatedRepoName}-${ulid()}`;
  } else {
    // TODO: add more rules when we get more compute services
    return repositoryName;
  }
};
