// config from RUM dashboard configuration JS snippet

// ToDo: Receive this information from CDK config
export const RUM_BETA_CONFIG = {
  config: {
    sessionSampleRate: 1,
    guestRoleArn: 'arn:aws:iam::090691362586:role/CustomerAssumedMonitoringRole',
    endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
    telemetries: ['errors', 'performance', 'http'],
    allowCookies: true,
    enableXRay: true,
  },
  applicationId: 'b90aab51-e321-432a-8ede-e70aa8a3fcb3',
  applicationVersion: '1.0.0',
  applicationRegion: 'us-west-2',
  location: 'https://d3uspj7qqt5w72.cloudfront.net',
  apiEndpoint:
    'https://7cle5r6my9.execute-api.us-west-2.amazonaws.com/beta/putCustomerMonitoringEvents',
};

export const RUM_GAMMA_CONFIG = {
  config: {
    sessionSampleRate: 1,
    guestRoleArn: 'arn:aws:iam::802367137718:role/CustomerAssumedMonitoringRole',
    endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
    telemetries: ['errors', 'performance', 'http'],
    allowCookies: true,
    enableXRay: true,
  },
  applicationId: '26f72e74-5865-43fd-9c27-cc5ff272773a',
  applicationVersion: '1.0.0',
  applicationRegion: 'us-east-1',
  location: 'https://d2xbmidb75nhl7.cloudfront.net',
  apiEndpoint:
    'https://r1jj7yel67.execute-api.us-east-1.amazonaws.com/gamma/putCustomerMonitoringEvents',
};

export const RUM_PROD_CONFIG = {
  config: {
    sessionSampleRate: 1,
    guestRoleArn: 'arn:aws:iam::031963684356:role/CustomerAssumedMonitoringRole',
    endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
    telemetries: ['errors', 'performance', 'http'],
    allowCookies: true,
    enableXRay: true,
  },
  applicationId: '8449e561-2239-4935-9c7c-f5a6e0f308d6',
  applicationVersion: '1.0.0',
  applicationRegion: 'us-east-1',
  location: 'https://gallery.ecr.aws',
  apiEndpoint: 'https://api.us-east-1.gallery.ecr.aws/putCustomerMonitoringEvents',
};
