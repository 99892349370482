import React, { FC } from 'react';
import { InfoSectionViewModel } from '../../Models/types';
import { InfoSectionItem } from './InfoSectionItem';
import { HomeHeader } from '../HomeHeader/HomeHeader';

import './InfoSection.scss';

interface InfoSectionProps {
  vm: InfoSectionViewModel;
  id?: string;
}

const scssRoot = 'InfoSection';

export const InfoSection: FC<InfoSectionProps> = props => {
  const { vm, id } = props;
  return (
    <div id={id ?? undefined} className={scssRoot}>
      <div
        data-testid={`InfoSection-${vm.header.split(' ').join('-')}`}
        className={`${scssRoot}-header`}
      >
        <HomeHeader content={vm.header} />
      </div>
      <div className={`${scssRoot}-items`}>
        {vm.items.map(item => (
          <InfoSectionItem key={item.copy} vm={item} />
        ))}
      </div>
    </div>
  );
};
