import { TopNavigationViewModel } from './types';

import awsWhiteLogo from '@spencer/assets/homepage/aws_white_logo.png';
import searchIcon from '@spencer/assets/homepage/search.png';
import { ECR_PUBLIC_GALLERY } from '@spencer/util/constants';

const AMAZON_FILTER = 'popularRegistries=amazon';
const DOCKER_FILTER = 'popularRegistries=docker';
const VERIFIED_FILTER = 'verified=verified';

export const TOP_NAV_VM: TopNavigationViewModel = {
  header: ECR_PUBLIC_GALLERY,
  logoPath: awsWhiteLogo,
  search: {
    placeholderText: 'Search container images',
    iconPath: searchIcon,
    filters: [
      { label: 'All images' },
      { label: 'Docker official images', value: DOCKER_FILTER },
      { label: 'Amazon images', value: AMAZON_FILTER },
      { label: 'Verified publishers', value: VERIFIED_FILTER },
    ],
  },
  scroll: {
    items: [
      {
        label: 'Features and benefits',
        scrollId: '#home--features-and-benefits',
      },
      {
        label: 'Use cases',
        scrollId: '#home--use-cases',
      },
    ],
  },
};
