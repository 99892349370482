import React, { FC, useEffect, useState } from 'react';

import { ImageTagDetail } from '@spencer/apis/ecrPublicClient';
import { formatDistance } from 'date-fns';
import { copyToClipboard } from '@spencer/util/clipboard';

import {
  Box,
  Button,
  ButtonProps,
  Popover,
  SpaceBetween,
  Select,
  SelectProps,
  StatusIndicator,
} from '@amzn/awsui-components-react';
import {
  CancelableEventHandler,
  NonCancelableEventHandler,
} from '@amzn/awsui-components-react/polaris/internal/events';
import LaunchWithAppRunnerButton, {
  enableLaunchWithAppRunner,
} from '@spencer/components/common/LaunchWithAppRunnerButton';
import {
  COPIED_IMAGE_URL_ATTRIBUTES,
  EVENT_TYPES,
} from '@spencer/customerMonitoringTelemetry/customEventTypes';
import { awsRum } from '@spencer/customerMonitoringTelemetry/cwrumconfig';

interface ICopyStatusIndicator {
  selectedImageTag: SelectProps.Option;
}

const CopyStatusIndicator: FC<ICopyStatusIndicator> = ({ selectedImageTag }) => {
  if (selectedImageTag) {
    return <StatusIndicator type="success">{selectedImageTag.label} copied</StatusIndicator>;
  } else {
    return <StatusIndicator type="error">Unable to copy pull command</StatusIndicator>;
  }
};

const getPullCommand = (publisherId: string, repoName: string, imageTag: string) =>
  `public.ecr.aws/${publisherId}/${repoName}:${imageTag}`;

const getSelectedOption = (
  publisherId: string,
  repoName: string,
  tags?: ImageTagDetail[]
): SelectProps.Option | null => {
  if (tags && tags.length > 0) {
    return {
      label: getPullCommand(publisherId, repoName, tags[0].imageTag),
      value: tags[0].imageTag,
    };
  } else {
    return null;
  }
};

const getImageTagPullOptions = (
  publisherId: string,
  repoName: string,
  tags?: ImageTagDetail[]
): SelectProps.Options => {
  if (!tags || tags.length === 0) return [];
  if (tags.length === 1) {
    return [
      {
        label: getPullCommand(publisherId, repoName, tags[0].imageTag),
        value: tags[0].imageTag,
      },
    ];
  }

  return tags.reduce((options, currentTag, currentIndex) => {
    options.push({
      label: getPullCommand(publisherId, repoName, tags[currentIndex].imageTag),
      value: tags[currentIndex].imageTag,
    });

    return options;
  }, []);
};

const getLatestTag = (tags?: ImageTagDetail[]): ImageTagDetail | null => {
  let latestTag = null;

  if (tags?.length) {
    const latestTagFromList = tags.find(tag => tag.imageTag === 'latest');
    const lastPushedTagFromList = tags.sort(
      (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
    )?.[0];
    if (latestTagFromList) {
      latestTag = latestTagFromList;
    } else if (lastPushedTagFromList) {
      latestTag = lastPushedTagFromList;
    }
  }

  return latestTag;
};

interface IListingDetailPullSelect {
  publisherId: string;
  repoName: string;
  isLoading: boolean;
  imageTagDetailList?: ImageTagDetail[];
}

const ListingDetailPullSelect: FC<IListingDetailPullSelect> = ({
  publisherId,
  repoName,
  isLoading,
  imageTagDetailList,
}) => {
  const [selectedOption, setSelectedOption] = useState<SelectProps.Option | null>(
    getSelectedOption(publisherId, repoName, imageTagDetailList)
  );
  const [options, setOptions] = useState<SelectProps.Options>(
    getImageTagPullOptions(publisherId, repoName, imageTagDetailList)
  );
  const [latestTag, setLatestTag] = useState<ImageTagDetail | null>(
    getLatestTag(imageTagDetailList)
  );

  useEffect(() => {
    setOptions(getImageTagPullOptions(publisherId, repoName, imageTagDetailList));
    setSelectedOption(getSelectedOption(publisherId, repoName, imageTagDetailList));
    setLatestTag(getLatestTag(imageTagDetailList));
  }, [imageTagDetailList]);

  const handleSelectChange: NonCancelableEventHandler<SelectProps.ChangeDetail> = event => {
    const { detail } = event;

    setSelectedOption(detail.selectedOption);
  };

  const handleCopyPullToClipboard: CancelableEventHandler<ButtonProps.ClickDetail> = () => {
    if (selectedOption?.label) {
      copyToClipboard(selectedOption.label);
      // Emitting custom CW RUM event
      const eventData: COPIED_IMAGE_URL_ATTRIBUTES = {
        repositoryName: repoName,
        imageTag: selectedOption.value,
        copiedImageUrl: selectedOption.label,
      };
      awsRum.recordEvent(EVENT_TYPES.COPIED_IMAGE_URL, eventData);
    }
  };

  return (
    <Box margin={{ bottom: 'm' }}>
      <SpaceBetween direction="horizontal" size="xxs">
        <Select
          selectedOption={selectedOption}
          options={options}
          virtualScroll={options.length > 500 ? true : false}
          onChange={handleSelectChange}
          selectedAriaLabel=""
          filteringType="auto"
          placeholder="Choose an image"
          empty="No available images"
          loadingText="Loading images"
          statusType={isLoading ? 'loading' : 'finished'}
          data-testid="selectImageURI"
          data-image-uri={selectedOption ? selectedOption.value : null}
        />
        <Popover
          size="large"
          position="bottom"
          triggerType="custom"
          dismissButton={false}
          content={<CopyStatusIndicator selectedImageTag={selectedOption} />}
        >
          <Button
            iconName="copy"
            variant="normal"
            onClick={handleCopyPullToClipboard}
            disabled={options.length === 0 || isLoading ? true : false}
          >
            Copy
          </Button>
        </Popover>
        {enableLaunchWithAppRunner({
          registryAlias: publisherId,
          repositoryName: repoName,
        }) ? (
          <LaunchWithAppRunnerButton
            publisherId={publisherId}
            repoName={repoName}
            imageTag={selectedOption?.value}
            isLoading={isLoading}
            disabled={options.length === 0 || isLoading || selectedOption === null}
          />
        ) : null}
      </SpaceBetween>
      {latestTag && (
        <Box variant="span" fontSize="body-s" color="text-body-secondary" padding={{ top: 'xxs' }}>
          {`Updated ${formatDistance(new Date(latestTag.createdAt), new Date(), {
            addSuffix: true,
          })}`}
        </Box>
      )}
    </Box>
  );
};

export default ListingDetailPullSelect;
