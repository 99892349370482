import React, { FC } from 'react';
import { CallToActionItemViewModel } from '../../Models/types';

import './CallToAction.scss';

interface CallToActionItemProps {
  vm: CallToActionItemViewModel;
}

const scssRoot = 'CallToActionItem';

export const CallToActionItem: FC<CallToActionItemProps> = props => {
  const { vm } = props;

  return (
    <a
      data-testid={`CallToActionItem-${vm.linkText.split(' ').join('-')}`}
      href={vm.link}
      className={scssRoot}
      rel="noreferrer"
      target="_blank"
      onClick={() => vm.event()}
    >
      <div className={`${scssRoot}-logo-container`}>
        <img src={vm.imagePath} alt={`icon illustration for ${vm.linkText}`} />
      </div>
      <div className={`${scssRoot}-link`}>{vm.linkText}</div>
      <div className={`${scssRoot}-copy`}>{vm.copy}</div>
    </a>
  );
};
