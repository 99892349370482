import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Checkbox } from '@amzn/awsui-components-react/polaris';

import { ISearchFilterGroup } from './../SearchContext';

const bemPrefix = 'FilterBar';
import './FilterBar.scss';

const addToUrl = (
  urlSearchParams: URLSearchParams,
  urlParamName: string,
  filterState: boolean,
  filterValue?: string
) => {
  const params = urlSearchParams.get(urlParamName);
  if (params) {
    if (filterState) {
      urlSearchParams.set(urlParamName, `${params},${filterValue}`);
    } else {
      const withoutRemovedValue = params.split(',').filter(p => p !== filterValue);
      if (withoutRemovedValue.length) {
        urlSearchParams.set(urlParamName, withoutRemovedValue.join(','));
      } else {
        urlSearchParams.delete(urlParamName);
      }
    }
  } else {
    if (filterState) {
      urlSearchParams.set(urlParamName, filterValue);
    }
  }
};

type IFilterBarSectionProps = ISearchFilterGroup & {
  page: number;
  disabled?: boolean;
  setPage: (page: number) => void;
};

const FilterGroup: FC<IFilterBarSectionProps> = ({
  title,
  type,
  searchFilters,
  page,
  setPage,
  disabled,
}) => {
  const location = useLocation();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(location.search);

  const componentBemPrefix = `${bemPrefix}__ControlSection`;
  return (
    <div className={componentBemPrefix}>
      <span className={`${componentBemPrefix}__Title`}>{title}</span>
      <div className={`${componentBemPrefix}__Controls`}>
        {searchFilters.map((f, index) => (
          <div className={`${componentBemPrefix}__Controls__Item`} key={index}>
            <Checkbox
              data-testid={f.testid ? `${f.testid}--checkbox` : undefined}
              onChange={({ detail }) => {
                addToUrl(urlSearchParams, type, detail.checked, f.value);
                if (page !== 1) {
                  setPage(1);
                  urlSearchParams.set('page', '1');
                }
                f.updateFilter(detail.checked);
                history.replace({
                  search: urlSearchParams.toString(),
                  state: history?.location?.state,
                });
              }}
              checked={f.filterState}
              disabled={disabled ?? false}
            >
              <div>
                <span data-testid={f.testid ?? undefined}>{f.label}</span>
                {f.description && (
                  <p className={`${componentBemPrefix}__Controls__Description`}>{f.description}</p>
                )}
              </div>
            </Checkbox>
          </div>
        ))}
      </div>
    </div>
  );
};

interface IFilterBarProps {
  filterGroups: ISearchFilterGroup[];
  page: number;
  showMobileFilterBar: boolean;
  mobileClassOverride?: string;
  disabled?: boolean;
  setPage: (page: number) => void;
  clearAllFilters: () => void;
  clearAllFiltersAndResetPage: () => void;
}

const FilterBar: FC<IFilterBarProps> = ({
  page,
  showMobileFilterBar,
  mobileClassOverride,
  setPage,
  clearAllFilters,
  clearAllFiltersAndResetPage,
  filterGroups,
  disabled,
}) => {
  const history = useHistory();
  const location = useLocation();

  const resetAllFilters = () => {
    const urlSearchParams = new URLSearchParams(location.search);
    let resetPage = false;
    for (const filterGroup of filterGroups) {
      urlSearchParams.delete(filterGroup.type);
      for (const filter of filterGroup.searchFilters) {
        // set to true if any filter is set
        if (filter.filterState) {
          resetPage = true;
        }
      }
    }
    history.replace({
      search: urlSearchParams.toString(),
      state: history?.location?.state,
    });
    // only reset the page if filters were actually cleared
    if (resetPage) {
      clearAllFiltersAndResetPage();
    } else {
      clearAllFilters();
    }
  };

  let className = bemPrefix;
  if (showMobileFilterBar) {
    className = `${className} ${bemPrefix}--mobile`;
    if (mobileClassOverride) {
      className = `${className} ${mobileClassOverride}`;
    }
  }

  return (
    <div className={className}>
      <div className={`${bemPrefix}__Header`}>
        <strong>Filters</strong>
        <div className={`${bemPrefix}__Header__Clear`} onClick={resetAllFilters}>
          Clear all
        </div>
      </div>
      <div className="Common__Divider" />
      {filterGroups.map((group, index) => (
        <FilterGroup
          key={index}
          title={group.title}
          searchFilters={group.searchFilters}
          type={group.type}
          page={page}
          setPage={setPage}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export default FilterBar;
