import { Alert as ExternalAlert } from '@amzn/awsui-components-react/polaris';
import React, { FC } from 'react';
import './Alert.scss';
import { IAlertContentProps } from './AlertContentHandler';

export interface IErrorPageProps {
  alertContent: IAlertContentProps;
}

const Alert: FC<IErrorPageProps> = ({ alertContent }) => {
  return (
    <div className="alert">
      <ExternalAlert
        visible={true}
        dismissAriaLabel="Close alert"
        header={alertContent.title ?? ''}
        type={alertContent.alertType}
      >
        {alertContent.content}
      </ExternalAlert>
    </div>
  );
};

export default Alert;
