import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { LocationState } from 'history';

import Box from '@amzn/awsui-components-react/polaris/box';
import Link from '@amzn/awsui-components-react/polaris/link';
import { awsRum } from '@spencer/customerMonitoringTelemetry/cwrumconfig';
import {
  EVENT_TYPES,
  CLICKED_ON_REPOSITORY_ATTRIBUTES,
} from '@spencer/customerMonitoringTelemetry/customEventTypes';

interface LinkInternalProps {
  autoRouteOverride?: (route) => void;
  displayText: string;
  route: string;
  fontSize?: 'body-s' | 'body-m' | 'heading-s' | 'heading-m' | 'heading-l' | 'heading-xl';
  state?: LocationState;
  testid?: string;
  eventType?: EVENT_TYPES;
  repoName?: string;
}

export const LinkInternal: FC<LinkInternalProps> = props => {
  const { autoRouteOverride, displayText, fontSize, route, state, testid, eventType, repoName } =
    props;
  const history = useHistory();

  function handleClick(event) {
    event.preventDefault();

    if (autoRouteOverride) {
      autoRouteOverride(route);
    } else {
      history.push(route, state);
    }
    // Emitting custom CW RUM event
    //ToDo: Move event data creation outside component before adding more routing events
    if (eventType) {
      const eventData: CLICKED_ON_REPOSITORY_ATTRIBUTES = {
        repositoryName: repoName,
      };
      awsRum.recordEvent(EVENT_TYPES.CLICKED_ON_REPOSITORY, eventData);
    }
  }

  return (
    <Box variant="span" fontWeight={fontSize && fontSize.includes('heading') ? 'bold' : undefined}>
      <Link
        fontSize={fontSize ?? 'body-m'}
        data-testid={testid ?? undefined}
        href={route}
        onFollow={handleClick}
      >
        {displayText}
      </Link>
    </Box>
  );
};
