import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useListingQueryState, useRegistryCatalogQueryState } from './hooks';
import { ECR_PUBLIC_GALLERY } from '@spencer/util/constants';

interface IListingDetailPageHelmet {
  publisherId: string;
  repoName: string;
}

const ListingDetailPageHelmet: FC<IListingDetailPageHelmet> = ({ publisherId, repoName }) => {
  const { data: repositoryCatalogData } = useListingQueryState(publisherId, repoName);
  const { data: registryCatalogData } = useRegistryCatalogQueryState(publisherId);

  let title = `${ECR_PUBLIC_GALLERY} - ${repoName}`;
  if (registryCatalogData?.registryCatalogData.displayName && registryCatalogData.verified) {
    title = `${ECR_PUBLIC_GALLERY} - ${registryCatalogData?.registryCatalogData.displayName}/${repoName}`;
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:url" content={`https://gallery.ecr.aws/${publisherId}/${repoName}`} />
      <meta property="og:title" content={title} />
      <link rel="canonical" href={`https://gallery.ecr.aws/${publisherId}/${repoName}`} />
      {repositoryCatalogData?.catalogData?.description ? (
        <meta property="og:description" content={repositoryCatalogData?.catalogData?.description} />
      ) : null}
      {repositoryCatalogData?.catalogData?.description ? (
        <meta name="description" content={repositoryCatalogData?.catalogData?.description} />
      ) : null}
    </Helmet>
  );
};

export default ListingDetailPageHelmet;
