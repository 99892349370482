import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { HistoryState } from '@spencer/App';
import {
  BaseNavigationDetail,
  CancelableEventHandler,
} from '@amzn/awsui-components-react/polaris/internal/events';

import errorPageIllustrationSVG from '@spencer/assets/svgs/error_page_illustration.svg';
import { Box, Link } from '@amzn/awsui-components-react/polaris';

import './ListingDetailNotFound.scss';
import { ECR_PUBLIC_GALLERY } from '@spencer/util/constants';

const ListingDetailNotFound: FC = () => {
  const history = useHistory<HistoryState>();

  const handleLinkFollow: CancelableEventHandler<BaseNavigationDetail> = event => {
    event.preventDefault();

    history.push('/');
  };

  return (
    <div className="errorPage">
      <div className="errorPage__content">
        <img aria-label="Error Page Illustration" src={errorPageIllustrationSVG} />
        <Box variant="h1">Repository not found</Box>
        <Box variant="p" margin={{ bottom: 'xs' }}>
          The requested repository does not exist or is no longer available.
        </Box>
        <Box variant="p" margin={{ bottom: 'xxs' }}>
          {`Visit the ${ECR_PUBLIC_GALLERY} to view the available public repositories.`}
        </Box>
        <Box>
          <Link
            ariaLabel={`${ECR_PUBLIC_GALLERY} Link`}
            variant="secondary"
            external={false}
            onFollow={handleLinkFollow}
          >
            {ECR_PUBLIC_GALLERY}
          </Link>
        </Box>
      </div>
    </div>
  );
};

export default ListingDetailNotFound;
