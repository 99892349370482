import { TopNavigationFilter } from '@spencer/components/common/TopNavigation/Models/types';

export enum EVENT_TYPES {
  SEARCH_WITH_TERM = 'SearchWithTerm',
  COPIED_IMAGE_URL = 'CopiedImageUrl',
  CHANGED_SORT_BY_SETTING = 'ChangedSortBySetting',
  CLICKED_ON_REPOSITORY = 'ClickOnRepository',
  FILTERED_BY_AMAZON_REGISTRY = 'FilteredByAmazonRegistries',
  FILTERED_BY_DOCKER_REGISTRY = 'FilteredByDockerRegistries',
  FILTERED_BY_VERIFIED_REGISTRY = 'FilteredByVerifiedRegistries',
  FILTERED_BY_REPO_CONFIGURATION = 'FilteredByRepositoryConfigurations',
  HOME_CLICKED_POPULAR_REGISTRY = 'HomeClickedPopularRegistry',
  HOME_CLICKED_DOC_LINK = 'HomeClickedDocLink',
  HOME_CLICKED_FILTER_RADIO = 'HomeClickedFilterRadio',
}

export type SEARCH_WITH_TERM_ATTRIBUTES = {
  searchTerm: string;
};

export type COPIED_IMAGE_URL_ATTRIBUTES = {
  repositoryName: string;
  copiedImageUrl: string;
  imageTag: string;
};

export type CLICKED_ON_REPOSITORY_ATTRIBUTES = {
  repositoryName: string;
};

export type CHANGED_SORT_BY_SETTING_ATTRIBUTE = {
  searchTerm: string;
  sorting_method: string;
};

export type FILTER_BY_ATTRIBUTE = {
  filteredBy: string;
};

export type HOME_CLICKED_ATTRIBUTES = {
  registry?: string;
  url?: string;
  filter?: TopNavigationFilter;
};

export type HOME_EVENTS =
  | EVENT_TYPES.HOME_CLICKED_DOC_LINK
  | EVENT_TYPES.HOME_CLICKED_FILTER_RADIO
  | EVENT_TYPES.HOME_CLICKED_POPULAR_REGISTRY;
