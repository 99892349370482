import React, { useState, useEffect, FunctionComponent } from 'react';

interface IFallbackImageProps {
  className?: string;
  imageSrc: string;
  fallbackSrc: string;
}

const FallbackImage: FunctionComponent<IFallbackImageProps> = ({
  className,
  imageSrc,
  fallbackSrc,
}) => {
  const [errored, setErrored] = useState(false);
  const [imgSrc, setImgSrc] = useState(imageSrc);

  const onError = () => {
    if (!errored) {
      setErrored(true);
      setImgSrc(fallbackSrc);
    }
  };

  useEffect(() => {
    setErrored(false);
    setImgSrc(imageSrc);
  }, [imageSrc]);

  return (
    <img
      aria-label={
        imgSrc ? 'default public repository image' : 'customer generated public repository image'
      }
      className={className}
      src={imgSrc ?? fallbackSrc}
      onError={onError}
    />
  );
};

export default FallbackImage;
