import { ERROR_TYPE } from '../ErrorPage/ErrorPageContentHandler';
import { ECR_PUBLIC_GALLERY } from '@spencer/util/constants';

export interface IAlertContentProps {
  title?: string;
  content: string;
  alertType: any;
}

const RepositoryNotFoundExceptionContent: IAlertContentProps = {
  title: `Repository not found`,
  content: `View the ${ECR_PUBLIC_GALLERY} to view the available public repositories.`,
  alertType: `info`,
};

const ServerExceptionContent: IAlertContentProps = {
  content: `An unexpected error occurred. Retry the request.`,
  alertType: `error`,
};

const InvalidParameterExceptionContent: IAlertContentProps = {
  title: `Invalid parameter specified`,
  content: `You specified an invalid parameter or the formatting is invalid. Verify the information and try again.`,
  alertType: `error`,
};

const AlertContentHandler = (errorType: string) => {
  switch (errorType) {
    case ERROR_TYPE.REPOSITORY_NOT_FOUND_EXCEPTION:
      return RepositoryNotFoundExceptionContent;
    case ERROR_TYPE.SERVER_EXCEPTION:
      return ServerExceptionContent;
    case ERROR_TYPE.INVALID_PARAMETER_EXCEPTION:
      return InvalidParameterExceptionContent;
    default:
      return ServerExceptionContent;
  }
};

export default AlertContentHandler;
