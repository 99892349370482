import { ImageTagDetail } from '@spencer/apis/ecrPublicClient';
import { TableProps } from '@amzn/awsui-components-react/polaris';
import { DatePushedCell, ImageURICell, NameCell, SizeCell, TypeCell } from './Cells';
import { getDerivedMediaType } from '@spencer/components/common/ImageMediaTypeText/utils';

const imageDerivedMediaTypeComparator = (a: ImageTagDetail, b: ImageTagDetail) => {
  const mediaTypeStringA = getDerivedMediaType({
    imageManifestMediaType: b?.imageDetail?.imageManifestMediaType,
    artifactMediaType: b?.imageDetail?.artifactMediaType,
  });

  const mediaTypeStringB = getDerivedMediaType({
    imageManifestMediaType: a?.imageDetail?.imageManifestMediaType,
    artifactMediaType: a?.imageDetail?.artifactMediaType,
  });

  return mediaTypeStringA?.localeCompare(mediaTypeStringB) ?? -1;
};

const imageCreatedAtComparator = (a: ImageTagDetail, b: ImageTagDetail) =>
  b.createdAt.getTime() - a.createdAt.getTime();

const imageSizeComparator = (a: ImageTagDetail, b: ImageTagDetail) =>
  b?.imageDetail?.imageSizeInBytes - a?.imageDetail?.imageSizeInBytes ?? -1;

export const getColumnDefinitions = (
  publisherId: string,
  repoName: string
): TableProps.ColumnDefinition<ImageTagDetail>[] => [
  {
    id: 'name',
    header: 'Name',
    cell: NameCell,
    sortingField: 'imageTag',
  },
  {
    id: 'type',
    header: 'Type',
    cell: TypeCell,
    sortingField: 'imageDetail.imageManifestMediaType',
    sortingComparator: imageDerivedMediaTypeComparator,
  },
  {
    id: 'pushedAt',
    header: 'Date pushed',
    cell: DatePushedCell,
    sortingField: 'imagePushedAt',
    sortingComparator: imageCreatedAtComparator,
  },
  {
    id: 'pullCommand',
    header: 'Image URI',
    cell: ImageURICell(publisherId, repoName),
  },
  {
    id: 'size',
    header: 'Size',
    cell: SizeCell,
    sortingField: 'imageSizeInBytes',
    sortingComparator: imageSizeComparator,
  },
];
