import React, { FC } from 'react';

import { Box } from '@amzn/awsui-components-react/polaris';
import { bemPrefix } from './common';

interface ISearchCardDescriptionProps {
  description: string;
}

const SearchCardDescription: FC<ISearchCardDescriptionProps> = ({ description }) => (
  <Box
    variant="p"
    data-testid="searchCardDescription"
    className={`${bemPrefix}__Container__info__description`}
  >
    {description}
  </Box>
);

export default SearchCardDescription;
