import React, { FC, useState } from 'react';
import { QuotesViewModel } from '../../Models/types';
import { QuoteItem } from './QuoteItem';
import { HomeHeader } from '../HomeHeader/HomeHeader';

import './QuotesSection.scss';

interface QuoteSectionProps {
  vm: QuotesViewModel;
}

const scssRoot = 'QuotesSection';

export const QuotesSection: FC<QuoteSectionProps> = props => {
  const { vm } = props;
  const [quoteIndex, setQuoteIndex] = useState(0);

  const visibleQuotes = vm?.quotes.filter(m => m.show).filter((_, i) => i < 3);

  const handleQuoteClick = quoteIndex => {
    setQuoteIndex(quoteIndex);
  };

  return (
    <div className={scssRoot}>
      <HomeHeader content={vm.header} />
      <div className={`${scssRoot}__sub-header`}>
        {visibleQuotes.map((quote, index) => (
          <div
            className={`${scssRoot}__container`}
            key={quote.logoPath}
            onClick={() => handleQuoteClick(index)}
          >
            <div className={`${scssRoot}__container__logo`}>
              <img src={quote.logoPath} alt={`Logo for ${quote.quoteTitle}`} />
            </div>
            <div
              className={`${scssRoot}__container__logo__select ${
                quoteIndex === index ? '-selected' : ''
              }`}
            />
          </div>
        ))}
      </div>
      {visibleQuotes.map((quote, index) => (
        <QuoteItem key={quote.quoteTitle} model={quote} selected={quoteIndex === index} />
      ))}
    </div>
  );
};
