export enum DERIVED_TYPES {
  IMAGE_MANIFEST = 'image manifest',
  MANIFEST_LIST = 'manifest list',
  HELM_CHART = 'helm chart',
  SINGULARITY_SIF = 'singularity sif',
  SOCI = 'soci index',
  SBOM = 'SBoM',
  SIGNATURE = 'signature',
  OTHER = 'other',
}

const typeFromArtifact = (artifactMediaType: string) => {
  if (artifactMediaType?.includes('sbom')) {
    return DERIVED_TYPES.SBOM;
  }
  if (artifactMediaType?.includes('notary') || artifactMediaType?.includes('signature')) {
    return DERIVED_TYPES.SIGNATURE;
  }
  if (artifactMediaType?.includes('soci.index')) {
    return DERIVED_TYPES.SOCI;
  }
  return DERIVED_TYPES.OTHER;
};

const typeFromOciImage = (artifactMediaType: string) => {
  const type = artifactMediaType.split('.')[2] ?? '-';

  switch (type) {
    case 'image':
      return DERIVED_TYPES.IMAGE_MANIFEST;
    case 'helm':
      return DERIVED_TYPES.HELM_CHART;
    case 'sif':
      return DERIVED_TYPES.SINGULARITY_SIF;
    default:
      return typeFromArtifact(artifactMediaType);
  }
};

const resolveType = (imageManifestMediaType, artifactMediaType) => {
  const manifestMediaType = imageManifestMediaType ?? '-';
  const artifactType = artifactMediaType ?? '-';

  switch (manifestMediaType) {
    case 'application/vnd.docker.distribution.manifest.v1+json':
    case 'application/vnd.docker.distribution.manifest.v2+json':
      return DERIVED_TYPES.IMAGE_MANIFEST;
    case 'application/vnd.docker.distribution.manifest.list.v2+json':
    case 'application/vnd.oci.image.index.v1+json':
      return DERIVED_TYPES.MANIFEST_LIST;
    case 'application/vnd.oci.image.manifest.v1+json':
      return typeFromOciImage(artifactType);
    case 'application/vnd.cncf.oras.artifact.manifest.v1+json':
    case 'application/vnd.oci.artifact.manifest.v1+json':
      return typeFromArtifact(artifactType);
    default:
      return DERIVED_TYPES.OTHER;
  }
};

export const getDerivedMediaType = ({
  imageManifestMediaType,
  artifactMediaType,
}: {
  imageManifestMediaType?: string;
  artifactMediaType?: string;
}): DERIVED_TYPES => {
  return resolveType(imageManifestMediaType, artifactMediaType);
};
