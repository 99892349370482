import { getCopywriteMessage, getReportIssueLinkUrl } from '../Utils/appfooter';

type AppFooterLink = {
  url?: string;
  onFollow?: () => void;
  text: string;
};

export type AppFooterVM = {
  links: AppFooterLink[];
  copywriteText: string;
};

export const footerVM: AppFooterVM = {
  copywriteText: getCopywriteMessage(),
  links: [
    { url: 'https://aws.amazon.com/ecr/', text: 'About' },
    { url: 'https://console.aws.amazon.com/ecr/home', text: 'Share & manage images' },
    { url: 'https://aws.amazon.com/service-terms/', text: 'AWS Service Terms' },
    { url: 'https://aws.amazon.com/privacy/', text: 'Privacy' },
    { url: 'https://aws.amazon.com/terms/', text: 'Site Terms' },
    { url: 'https://aws.amazon.com/legal/', text: 'Legal' },
    { url: getReportIssueLinkUrl(), text: 'Report a site issue' },
    {
      onFollow: () => {
        window?.shortbread?.customizeCookies();
      },
      text: 'Manage cookies',
    },
  ],
};
