import React, { FC } from 'react';

import { Box } from '@amzn/awsui-components-react/polaris';
import SearchCardDownloads from './SearchCardDownloads';
import { LinkInternal } from '@spencer/components/common/LinkInternal/LinkInternal';
import { bemPrefix } from './common';
import { EVENT_TYPES } from '@spencer/customerMonitoringTelemetry/customEventTypes';

interface ISearchCardTitleProps {
  title: string;
  publisherId: string;
  downloads?: number;
}

const SearchCardTitle: FC<ISearchCardTitleProps> = ({ title, publisherId, downloads }) => (
  <Box display="block">
    <Box
      variant="h4"
      display="inline-block"
      padding={{ vertical: 'xxxs' }}
      margin={{ right: 'xxs' }}
      className={`${bemPrefix}__Container__info__title__name`}
      data-testid="searchCardRepositoryName"
    >
      <LinkInternal
        displayText={`${publisherId}/${title}`}
        route={`/${publisherId}/${title}`}
        state={{ from: 'search' }}
        fontSize="heading-s"
        eventType={EVENT_TYPES.CLICKED_ON_REPOSITORY}
        repoName={title}
      />
    </Box>
    <Box display="inline-block">
      <SearchCardDownloads downloads={downloads} />
    </Box>
  </Box>
);

export default SearchCardTitle;
