import { useLayoutEffect, useState } from 'react';

export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(window?.innerWidth ?? 0);
  useLayoutEffect(() => {
    function updateWidth() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return screenWidth;
};
