import React, { FC, useState } from 'react';
import { ClickLogosSectionViewModel } from '../../Models/types';
import { ClickLogosSectionItem } from './ClickLogosSectionItem';
import { LinkInternal } from '@spencer/components/common/LinkInternal/LinkInternal';

import { useScreenWidth } from '@spencer/hooks/useScreenWidth';

import './ClickLogosSection.scss';

interface ClickLogosSectionProps {
  vm: ClickLogosSectionViewModel;
  defaultShowNum?: number;
}

const scssRoot = 'ClickLogosSection';
const SMALL_SCREEN_ROW_SIZE = 6;

export const ClickLogosSection: FC<ClickLogosSectionProps> = props => {
  const { vm, defaultShowNum } = props;
  const [showAll, updateShowAll] = useState(false);

  const itemsLength = vm?.items?.length ?? 0;

  const showNumDefault = defaultShowNum ?? 6;
  const showMoreDisplay = showAll ? 'Show less' : 'Show more';

  const smallScreenCutoff = itemsLength - (itemsLength % SMALL_SCREEN_ROW_SIZE);
  const screenWidth = useScreenWidth();

  return (
    <div className={scssRoot}>
      <div className={`${scssRoot}-header`}>{vm.header}</div>
      <div className={`${scssRoot}-items`}>
        {vm.items
          .filter((_, index) => showAll || index < showNumDefault)
          .filter((_, index) => screenWidth >= 912 || index < smallScreenCutoff)
          .map(item => (
            <ClickLogosSectionItem key={item.header} vm={item} />
          ))}
      </div>
      <div className={`${scssRoot}-show`}>
        <LinkInternal
          autoRouteOverride={() => updateShowAll(!showAll)}
          displayText={showMoreDisplay}
          route={''}
        />
      </div>
    </div>
  );
};
