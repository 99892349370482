import React, { FC } from 'react';

import { Box } from '@amzn/awsui-components-react/polaris';
import { LinkInternal } from '@spencer/components/common/LinkInternal/LinkInternal';
import VerifiedAccountIndicator from '@spencer/components/common/VerifiedAccountIndicator';
import { formatDownloads } from '@spencer/util/formatDownloads';

interface IListingDetailInfo {
  publisherId: string;
  repoName: string;
  registryOwner: string;
  verifiedAccount: boolean;
  downloads: number;
}

const ListingDetailInfo: FC<IListingDetailInfo> = ({
  publisherId,
  repoName,
  registryOwner,
  verifiedAccount,
  downloads,
}) => {
  return (
    <Box margin={{ bottom: 'm' }}>
      <Box>
        <Box
          variant="h1"
          display="inline-block"
          padding={{ vertical: 'xxxs' }}
          data-testid="repositoryName"
          className="wb-bw"
        >
          {`${publisherId}/${repoName}`}
        </Box>
        <Box
          key="downloads"
          display="inline-block"
          variant="p"
          fontSize="body-m"
          color="text-body-secondary"
          padding={{ vertical: 'xxxs' }}
          margin={{ left: 'xxs' }}
        >
          ({formatDownloads(downloads)} downloads)
        </Box>
      </Box>
      {registryOwner?.trim() ? (
        <Box>
          <Box
            variant="span"
            display="inline-block"
            fontWeight="normal"
            className="wb-bw"
            padding={{ top: 'xxs' }}
            margin={{ right: 'xs' }}
          >
            by&nbsp;
            <LinkInternal
              displayText={registryOwner?.trim().length ? registryOwner : 'untitled registry'}
              route={`/${publisherId}/`}
            />
          </Box>
          {verifiedAccount ? <VerifiedAccountIndicator /> : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default ListingDetailInfo;
