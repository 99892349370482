import React, { FC } from 'react';

import { Box } from '@amzn/awsui-components-react/polaris';
import { formatDownloads } from '@spencer/util/formatDownloads';

interface ISearchCardDownloadsProps {
  downloads?: number;
}

const SearchCardDownloads: FC<ISearchCardDownloadsProps> = ({ downloads = 0 }) => (
  <Box
    variant="p"
    key="downloads"
    color="text-body-secondary"
    fontSize="body-m"
    display="inline-block"
    padding={{ vertical: 'xxxs' }}
  >
    ({formatDownloads(downloads)} downloads)
  </Box>
);

export default SearchCardDownloads;
