export enum FEATURES {
  publisherErrorPage = 'publisherErrorPage',
  listDetailAlert = 'listDetailAlert',
  repositoryErrorPage = 'repositoryErrorPage',
  uxPreview = 'uxPreview',
}

const FEATURE_OBJECT = 'ecr_public_gallery_features';

const PROD_FEATURES = [];

/**
 * Checks if a feature should be enabled in prod.
 * If the value is in the PROD_FEATURES array, it is enabled in Prod by default.
 * If the value is truthy in localStorage, the feature is enabled.
 * @param featureName name of feature
 *
 * show feature by adding value to localStorage
 * eg paste into browser console:
 *   localStorage.setItem("ecr_public_gallery_features", JSON.stringify({"uxPreview": "true"}));
 *
 */
export const isFeatureEnabled = (featureName: FEATURES): boolean => {
  if (process?.env?.NODE_ENV !== 'dev' && PROD_FEATURES.includes(featureName)) {
    return true;
  } else {
    const featureString = window.localStorage.getItem(FEATURE_OBJECT);
    try {
      const features = JSON.parse(featureString);

      if (features?.actLikeProd) {
        return PROD_FEATURES.includes(featureName);
      } else if (features) {
        return !!features[featureName];
      }
    } catch (e) {
      return false;
    }
  }
};
