import React, { FC } from 'react';

import { AWSError } from 'aws-sdk/lib/error';
import { isRegistryHidden } from '@spencer/util/hideRegistry';

import Markdown from 'markdown-to-jsx';

const getDefaultRepositoryNotFoundMessage = (publisherId: string, repoName: string) =>
  `The repository with name '${repoName}' does not exist in the registry with id '${publisherId}'`;

interface IAboutTab {
  publisherId: string;
  repoName: string;
  aboutText?: string;
  listingError?: AWSError;
}

const AboutTab: FC<IAboutTab> = ({ publisherId, repoName, aboutText, listingError }) => {
  let aboutTabContent = (
    <Markdown options={{ disableParsingRawHTML: true }}>
      {aboutText?.length ? aboutText : "This repository doesn't have any About information set."}
    </Markdown>
  );

  if (listingError?.code === 'RepositoryNotFoundException') {
    aboutTabContent = (
      <span>
        {listingError?.message ?? getDefaultRepositoryNotFoundMessage(publisherId, repoName)}
      </span>
    );
  } else if (isRegistryHidden(publisherId)) {
    aboutTabContent = <span>{getDefaultRepositoryNotFoundMessage(publisherId, repoName)}</span>;
  }

  return aboutTabContent;
};

export default AboutTab;
