import React from 'react';
import { Box, Button, Icon, Popover, StatusIndicator } from '@amzn/awsui-components-react/polaris';

import { copyToClipboard } from '@spencer/util/clipboard';
import { truncatePullCommand } from '@spencer/util/truncatePullCommand';

interface ICopyProps {
  pullCommand: string;
  inButton?: boolean;
  isLoadingTags?: boolean;
  className?: string;
}

const bemPrefix = 'CopyPullCommands';

const CopyPullCommand = ({ pullCommand, inButton, isLoadingTags, className }: ICopyProps) => {
  if (!isLoadingTags && !pullCommand) {
    return null;
  }

  const truncatedPullCommand = pullCommand.length ? truncatePullCommand(pullCommand) : '';

  const copyStringToClipboard = (str: string) => copyToClipboard(str);

  const renderInner = () => (
    <Box variant="span" className={`${className}`}>
      <Popover
        size="medium"
        position="bottom"
        triggerType="custom"
        dismissButton={false}
        content={<StatusIndicator type="success">{pullCommand} copied</StatusIndicator>}
      >
        {inButton ? (
          <Button
            className={`${bemPrefix}__button`}
            onClick={() => copyStringToClipboard(pullCommand)}
            loading={isLoadingTags}
          >
            <Icon name="copy" /> {!isLoadingTags ? truncatedPullCommand : ''}
          </Button>
        ) : (
          <Button
            iconName="copy"
            variant="icon"
            onClick={() => copyStringToClipboard(pullCommand)}
          />
        )}
      </Popover>{' '}
      {!!!inButton && truncatedPullCommand}
    </Box>
  );

  return renderInner();
};

export default CopyPullCommand;
