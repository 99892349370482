import ecrPublicClient, { ImageTagDetail } from '@spencer/apis/ecrPublicClient';
import { AWSError } from 'aws-sdk/lib/error';
import { useQuery } from 'react-query';
import { querySettings } from './common';

export const useImageDetailListQuery = (publisherId: string, repoName: string) =>
  useQuery<ImageTagDetail[], AWSError>(
    ['tags', { publisherId, repoName: repoName }],
    ecrPublicClient.getTags.bind(ecrPublicClient),
    {
      ...querySettings,
    }
  );

export const useImageDetailListQueryState = (publisherId: string, repoName: string) =>
  useQuery<ImageTagDetail[], AWSError>(['tags', { publisherId, repoName: repoName }]);
